import PaypalPayment from "./PaypalPayment";

function Plans(user){
  return (
    <div className="hidden md:flex flex-col w-full items-center bg-darkBlue py-20 gap-10">
      <div className="flex justify-center font-title font-medium text-white text-4xl border-b-2 border-gray-600 pb-6 w-3/4">
          OUR PLANS
      </div>
      <div className="flex py-10 w-3/4 justify-center gap-10">
        {/* <p className="absolute z-10 translate-y-full -rotate-12 text-red-500 font-title text-8xl font-bold border-dashed border-8 border-red-500 p-5 rounded-lg"> FREE BETA VERSION</p> */}
        <div className="flex flex-col items-center w-1/4 gap-2 border border-gray-600 rounded-xl pb-1 hover:-translate-y-1.5 duration-500 hover:cursor-pointer">
          <div className=" flex flex-col bg-darkBlue2 w-full rounded-t-xl p-3">
            <p className="font-body text-center text-menuText text-lg font-medium">1 DAY</p>
            <p className="font-body text-center text-white text-3xl font-medium" style={{ display: 'none' }}>$9</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Full access to all functionalities
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Dive into the world of blackjack and explore new strategies!  
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Perfect for beginners who are just getting familiar with the game
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Priority attention via social networks and email
              </p>
            </div>
            {/* Comentar este bloque para la integración de paypal: ----------------------------------------------------- */}
            <div className="m-5">
              <button className="flex justify-center items-center rounded-full bg-red-500 text-white font-body font-bold px-9 py-2 hover:cursor-pointer hover:bg-red-600 duration-500 ">Beta!</button>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            {/* DEScomentar este bloque para la integración de paypal: (Botón plan Perfect ------------------------------ */}
            {/* {(user.user!=undefined && user.user!=null) ?
              (<PaypalPayment planId={0} user={user}/>):null
            } */}
            {/* ---------------------------------------------------------------------------------------------------------- */}
          </div>
        </div>
        <div className="flex flex-col items-center w-1/4 gap-2 border border-gray-600 rounded-xl pb-1 hover:-translate-y-1.5 duration-500 hover:cursor-pointer">
          <div className=" flex flex-col bg-darkBlue2 w-full rounded-t-xl p-3">
            <p className="font-body text-center text-menuText text-lg font-medium">7 DAYS</p>
            <p className="font-body text-center text-white text-3xl font-medium" style={{ display: 'none' }}>$21</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Full access to all functionalities
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Enough time to master the calculator and improve your skills
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                For those advanced players looking to polish their skills and deepen their blackjack knowledge
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Priority attention via social networks and email
              </p>
            </div>
            {/* Comentar este bloque para la integración de paypal: ----------------------------------------------------- */}
            <div className="m-5">
              <button className="flex justify-center items-center rounded-full bg-red-500 text-white font-body font-bold px-9 py-2 hover:cursor-pointer hover:bg-red-600 duration-500 ">Beta!</button>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            {/* DEScomentar este bloque para la integración de paypal: (Botón plan Hi-Lo) ----------------------------- */}
            {/* {(user.user!=undefined && user.user!=null) ?
              (<PaypalPayment planId={1} user={user}/>):null
            } */}
            {/* ---------------------------------------------------------------------------------------------------------- */}
          </div> 
        </div>
        <div className="flex flex-col items-center w-1/4 gap-2 border border-gray-600 rounded-xl pb-1 hover:-translate-y-1.5 duration-500 hover:cursor-pointer">
          <div className=" flex flex-col bg-darkBlue2 w-full rounded-t-xl p-3">
            <p className="font-body text-center text-menuText text-lg font-medium">30 DAYS</p>
            <p className="font-body text-center text-white text-3xl font-medium" style={{ display: 'none' }}>$60</p>
          </div>
          <div className="flex flex-col p-3 w-full items-center">
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Full access to all functionalities
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Reach the maximum limit of knowledge and skills in the game
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Perfect for experienced players looking to get the most out of the calculator
              </p>
            </div> 
            <div className="flex border-b border-gray-600 w-3/4 justify-center p-3">
              <p className="font-body text-center text-gray-400 ">
                Priority attention via social networks and email
              </p>
            </div>
            {/* Comentar este bloque para la integración de paypal: ----------------------------------------------------- */}
            <div className="m-5">
              <button className="flex justify-center items-center rounded-full bg-red-500 text-white font-body font-bold px-9 py-2 hover:cursor-pointer hover:bg-red-600 duration-500 ">Beta!</button>
            </div>
            {/* --------------------------------------------------------------------------------------------------------- */}
            {/* DEScomentar este bloque para la integración de paypal: (Botón plan Full) -------------------------------- */}
            {/* {(user.user!=undefined && user.user!=null) ?
              (<PaypalPayment planId={2} user={user}/>):null
            } */}
            {/* ---------------------------------------------------------------------------------------------------------- */}
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Plans;