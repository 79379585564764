import getProbability from "../getProbability";

const GetBestBet = async (setDisabledButton, cartas, s17, decks, double, enhc, p0, pa, 
    risk, setApuesta, setEdge, setCargandoBet, setEV, setStd, countMethod, decisionMethod, 
    hHands, returnTarget, percentile, desv_cond, hl_count, depth, alpha, k_value,c_value,ModeCalculator,
    nHands) => {
    setCargandoBet(true)
    setDisabledButton(true)
    if (p0 != "" && pa != ""){
      try {
        const cartasFetch = Object.values(cartas);
        const newDecks = "" + (decks);
        const newDouble = "" + (double);
        const newP0 = "" + (p0);
        const newPa = "" + (pa);
        const newRisk = "" + (risk);
  
        let newEnhc;
        let newS17;
        if (enhc){
          newEnhc = "0";
        } else {
          newEnhc = "1";
        }
        if (s17){
          newS17 = "0";
        } else {
          newS17 = "1";
        }
        // Crear objeto de solicitud
        let requestData = {
          "array": cartasFetch,
          "risk": newRisk,
          "p0": newP0,
          "pa": newPa,
          "s17": newS17,
          "decks": newDecks,
          "double": newDouble,
          "enhc": newEnhc,
          "hl_count": hl_count,
          "depth": depth,
          "hands": hHands,
          "returnTarget": returnTarget,
          "percentile": percentile,
          "desvcond": desv_cond,
          "alphaValue": alpha,
          "k_value": k_value,
          "c_value": c_value,
          "CM": countMethod,
          "DM": decisionMethod,
          "nHands": nHands

        };

        // Si ModeCalculator es diferente de 1, agregar el comando
        console.log("ModeCalculator",ModeCalculator);
        if (ModeCalculator !== "1") {
          requestData["comando"] = "apuesta";
        }else {
          requestData["comando"] = "hiloc";
        }


        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/apuesta`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(requestData)
        });
        const json = await response.json();
        console.log(json)
        const apuestaWithRisk = parseFloat(json.apuesta_con_riesgo).toFixed(2);
        const estimatedResponse = parseFloat(json.esp);
        const stdResponse = parseFloat(json.std);
        const ApuestaSinRiesgo = parseFloat(json.apuesta_sin_riesgo).toFixed(2);
  
        setApuesta(apuestaWithRisk);
        setEV(estimatedResponse);
        setStd(stdResponse);
        /*let cleaned_array = json.map(str => str.replace(/[()]/g, '')).join('').split(',');
        const lastElement =  json[json.length-1]
        const lastElement2 =  json[json.length-2]
        const array = lastElement2.split(/\)/)
        const lastElement22 = array[array.length - 1]
        // Convert strings to floats
        let float_array = [];
        for (let i = 0; i < cleaned_array.length; i++) {
          float_array.push(parseFloat(cleaned_array[i]));
        }
        // Create tuple and add second and third elements of original array
        let result = [float_array.slice(0, 9), parseFloat(lastElement22), parseFloat(lastElement)];
        console.log(result);
        setApuesta(result[1])
        setEdge(getProbability((result[2]).toFixed(2)))
        */
      } catch (e) {
        console.log(e)
      }
    } else {
      alert("You should include Initial Bankroll (I.B.)");
    }
    setDisabledButton(false);
    setCargandoBet(false);
  }

export default GetBestBet;