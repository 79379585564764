
function resetCount(setRounds) {
    for (var step = 1; step < 10; step++) {
      document.getElementById(`value_c_${step}`).innerHTML = 32
    }
    document.getElementById(`value_c_${0}`).innerHTML = 128
    document.getElementById('new_cards').innerHTML = ''
    setRounds(0);
}

export default resetCount;