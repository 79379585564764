
function resetBestDecs() {
  //reseteo del COLOR DE FONDO de las acciones
  document.getElementById('a1').className = document.getElementById('a1').className.slice(0, 81) + ' bg-gray-300 dark:bg-darkBlue2'
  document.getElementById('a2').className = document.getElementById('a2').className.slice(0, 81) + ' bg-gray-300 dark:bg-darkBlue2'
  document.getElementById('a3').className = document.getElementById('a3').className.slice(0, 81) + ' bg-gray-300 dark:bg-darkBlue2'
  document.getElementById('a4').className = document.getElementById('a4').className.slice(0, 81) + ' bg-gray-300 dark:bg-darkBlue2'
  
  //reseteo del color del TEXTO de las acciones
  document.getElementById('t1').className = document.getElementById('t1').className.slice(0, 17) + ' text-black dark:text-gray-400'
  document.getElementById('t2').className = document.getElementById('t2').className.slice(0, 17) + ' text-black dark:text-gray-400'
  document.getElementById('t3').className = document.getElementById('t3').className.slice(0, 17) + ' text-black dark:text-gray-400'
  document.getElementById('t4').className = document.getElementById('t4').className.slice(0, 17) + ' text-black dark:text-gray-400'

  

  try{
    document.getElementById('e1').innerHTML = 0;
    document.getElementById('e2').innerHTML = 0;
    document.getElementById('e3').innerHTML = 0;
    document.getElementById('e4').innerHTML = 0;
  } catch {}
}

export default resetBestDecs;