import {HiOutlineCog} from "react-icons/hi";
import c1 from "../../content/AS.svg"
import c2 from "../../content/2.svg"
import c3 from "../../content/3.svg"
import c4 from "../../content/4.svg"
import c5 from "../../content/5.svg"
import c6 from "../../content/6.svg"
import c7 from "../../content/7.svg"
import c8 from "../../content/8.svg"
import c9 from "../../content/9.svg"
import c10 from "../../content/10.svg"
import {cssLastCards, cssCards} from "../../helpers/Mobile/cssClass";

function zoneCards({ cola, openModal, ResetCountMobile, disabledButton, cartas, countMethod, ModeCalculator, hl_count, setHL_Count}) {
    return(
    <div className="flex border border-gray-600 shadow-xl bg-white dark:bg-newBlue">
      {ModeCalculator === "0"?
          <div className="flex flex-col gap-2 pr-1.5 pt-1.5 pb-1.5 
          w-full h-[25%] rounded-md">
      
              <div className='flex justify-between'> 
                  <div className="flex justify-start gap-2 pl-4 border border-gray-600 text-gray-900 rounded-full 
                  w-[95%] h-full bg-gray-300 dark:bg-darkBlue2">
                      <div className="flex items-center pl-1 pt-1 pb-1" >
                          <label className="font-body text-sm text-black dark:text-gray-400"> Latest cards dealt: </label>
                      </div>
                      <div className="flex flex-row items-center w-full">
                    
                          {cola.map((elemento, index) => (
                              <div key={index} id="new_cards2Mobile" className="flex gap-1">
                                  {elemento  ? (
                                      elemento[0] === "1"?
                                      <p className={cssLastCards(elemento[1])}>
                                          As
                                      </p>:
                                        <p className={cssLastCards(elemento[1])}>
                                          {elemento[0]}
                                        </p>
                                  ) : null}
                              </div>
                          ))}
                      </div>  
                  </div>    
                  <div className="md:hidden flex justify-end w-[10%]">
                     <button onClick={() => {openModal()}}>
                          <HiOutlineCog className="text-4xl text-black dark:text-4xl dark:text-white"/>
                      </button>
                  </div>
              </div>
                <div className="flex flex-row items-center justify-around gap-2">
                <div className="flex flex-col justify-center w-[30%] gap-1">
                    <button onClick={()=>{ResetCountMobile()}} disabled={disabledButton} className="flex shadow-xl 
                      justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm 
                      hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 py-1"> 
                      Reset Count 
                    </button>
                    {/* <label className="font-body text-sm text-black dark:text-gray-400 text-justify pl-2"> Count Method </label>
                    {(countMethod) === "pc"?
                    <label className="font-body text-sm text-red-400 dark:text-red-400 text-center pl-2"> PC </label>:
                    <label className="font-body text-sm text-red-400 dark:text-red-400 text-center pl-2"> HLTC </label>} */}
                  </div>  
                  <div className="flex flex-col w-full w-[70%]">
                    <div className="flex border border-gray-600 rounded-t bg-gray-300 dark:bg-darkBlue2">
                      <label  className={cssCards}> {cartas["1"]} </label>
                      <label  className={cssCards}> {cartas['2']} </label>
                      <label  className={cssCards}> {cartas['3']} </label>
                      <label  className={cssCards}> {cartas['4']} </label>
                      <label  className={cssCards}> {cartas['5']} </label>
                      <label  className={cssCards}> {cartas['6']} </label>
                      <label  className={cssCards}> {cartas['7']} </label>
                      <label  className={cssCards}> {cartas['8']} </label>
                      <label  className={cssCards}> {cartas['9']} </label>
                      <label  className={cssCards}> {cartas['10']} </label>
                    </div>
                    <div className="flex justify-between bg-white border-x border-b border-gray-600 w-full">
                      <img src={c1} alt="carta1" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c2} alt="carta2" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c3} alt="carta3" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c4} alt="carta4" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c5} alt="carta5" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c6} alt="carta6" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c7} alt="carta7" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c8} alt="carta8" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c9} alt="carta9" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                      <img src={c10} alt="carta10" className="h-[80%] w-[10%] border-l border-gray-600"></img>
                    </div>
                  </div>
                </div>
                
              </div>:

              <div className="flex flex-col gap-2 pr-1.5 pt-1.5 pb-1.5 w-full h-[25%] rounded-md">
              <div className={"flex flex-col items-center justify-center gap-2 p-1.5 rounded-md shadow-xl bg-white dark:bg-newBlue"}>
              <div className="flex justify-around gap-5">
                <h2 className="font-bold font-title text-black text-center text-3xl dark:text-gray-400">Hi-Lo True Count:</h2>
                <select id="hl_count"  className="w-16 rounded-md p-1 text-black text-lg bg-gray-300 dark:bg-gray-600 
                dark:text-white place-self-center"  
                value={hl_count} onChange={(e) => {setHL_Count(e.target.value)}}>
                  {(() => {
                    const options = [];
                    for (let i = 0; i <= 100; i++) {
                      const value = (i * 0.1).toFixed(1);
                      options.push(<option key={value} value={value}>{value}</option>);
                    }
                    return options;
                  })()}
                </select>
                <div className="md:hidden flex justify-end w-[10%]">
                     <button onClick={() => {openModal()}}>
                          <HiOutlineCog className="text-4xl text-black dark:text-4xl dark:text-white"/>
                      </button>
                  </div>
              </div>
              <div className="flex justify-center items-center gap-5">
                <label className="font-body font-bold text-black dark:text-gray-400">Count Method</label>
                <label className="font-body font-bold text-lg text-red-500 uppercase "> HLTC </label>
              </div>
            </div>

                </div>
              
}


        </div>
        
        
    )
}

export default zoneCards;