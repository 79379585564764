import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaSignOutAlt, FaCrown } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';




function Profile() {

	const location = useLocation();
  	const queryParams = new URLSearchParams(location.search);
  	const subscriptionPlan = queryParams.get('subscriptionPlan');
  	const expirationDate = queryParams.get('expirationDate');

	console.log( "subscriptionPlan: ", subscriptionPlan,"; expirationDate: ", expirationDate);

	const {
		user,
		logout,
		} = useAuth0();
		const userRoles = user && user['https://parametros_auth0.com/roles']
		? user['https://parametros_auth0.com/roles'].map((role) => role)
		: [];

	let planName;

	if (subscriptionPlan == -1) {
		planName = 'FREE';
	} else if (subscriptionPlan == 0) {
		planName = 'PerfectCount';
	} else if (subscriptionPlan == 1) {
		planName = 'Hi-Lo Count';
	} else if (subscriptionPlan == 2) {
		planName = 'FULL';
	} else {
		planName = 'Unknown'; // Fallback for unrecognized subscriptionPlan values
	}

	var expirationDate0;
	var expirationDate1;

	if (expirationDate?.includes(',')){
		const datesArray = expirationDate?.split(',');
		expirationDate0 = (datesArray[0] && new Date(datesArray[0]) instanceof Date) ? datesArray[0] : 'No purchases Made';
		expirationDate1 = (datesArray[1] && new Date(datesArray[1]) instanceof Date) ? datesArray[1] : 'No purchases Made';
	} else {
		expirationDate0 = 'No purchases Made';
		expirationDate1 = 'No purchases Made';
	}


	return (
			<div className='flex flex-col'>
				<div className='bg-footer py-3 px-6 '>
					<a href='/' className='group flex items-center cursor-pointer w-fit '>
						<BiLeftArrowAlt className='text-menuText font-body font-bold text-4xl group-hover:text-red-500' />
						<p className='text-menuText font-menu font-bold text-lg group-hover:text-red-500'>HOME</p>
					</a>
				</div>
				<div className='flex flex-col bg-darkBlue md:py-10 items-center h-screen px-3'>

					{userRoles?.includes('Maestro') && <h1 className='text-green-500 text-6xl'>Que Pasa MASTER</h1>}
					<div className='relative flex translate-y-[50px]'>
						<img src={user?.picture} alt='profile' className='rounded-full w-auto'></img>
						{subscriptionPlan!=-1 && (
							<FaCrown className="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 text-yellow-500"
										style={{ top: '-15%', left: '49%', zIndex: 1, pointerEvents: 'none',fontSize: '3rem' }} />
						)}
					</div>
					<div className='flex flex-col items-center bg-darkBlue2 w-full md:w-1/3 rounded-xl py-16 px-2 md:px-20'>
						<div className='flex flex-col items-center border-b border-gray-600 w-full pb-4'>
							<p className='font-title font-medium text-white text-xl'>{user?.name}</p>
							<p className='font-title font-medium text-white text-xl'>{user?.email}</p>
							<br></br>
							<h2 className='font-title font-medium text-white text-xl'>User Roles</h2>
							{userRoles.map((role) => <p className='font-title font-medium text-white text-xl'>{role}</p>)}

						</div>
						<div className='flex flex-col w-full pt-12'>
							<div className='flex justify-between py-1'>
								<p className='font-body text-menuText'>Current plan:</p>
								<p className='font-title font-medium text-white'>{planName}</p>
							</div>
							<div className='flex flex-col justify-between py-1'>
								{/* <p className='font-body text-menuText text-white'>Expiration dates:</p> */}
								<div className='flex justify-between py-1'>
									<p className='font-body text-menuText'>Perfect Count until:</p>
									<p className='font-title font-medium text-white'>{expirationDate0}</p>
								</div>
								<div className='flex justify-between py-1'>
									<p className='font-body text-menuText'>Hi-Lo Count until:</p>
									<p className='font-title font-medium text-white'>{expirationDate1}</p>
								</div>

								{/* <p className='font-title font-medium text-white mb-2'>Perfect Count: {expirationDate0}</p>
								<p className='font-title font-medium text-white mb-2'>Hi-Lo Count: {expirationDate1}</p> */}
							</div>
							<div className='flex justify-center pt-32'>
								<button onClick={() => logout({ returnTo: window.location.origin })} className='group flex items-center gap-3 rounded-full bg-red-500 text-white font-menu font-bold px-4 py-2 hover:cursor-pointer hover:bg-red-600 duration-500'>
									<p>Sign out</p>
									<FaSignOutAlt/>
								</button>
								
							</div>
						</div>
					</div>
				</div>
			</div>
	);
};
export default Profile;