
function PhoneAlert(){
  return (
    <div className="md:hidden fixed bottom-2 bg-red-500 mx-2 p-2 rounded-xl border border-red-800 text-gray-300 animate-wiggle-small">
      <p className="font-body font-bold text-center">Calculator and "Player Edge" section only available for 
      desktop version at the moment. We are working on a mobile version.</p>
    </div>
  )

}

export default PhoneAlert;