import { RxChevronDown, RxChevronRight } from 'react-icons/rx';
import { useState } from 'react';

export default function Rules({ showPost, show }) {
    const [expanded, setExpanded] = useState(null);

    const step2 = [
        {
            _id: 0,
            titulo: "RULES",
            content: [
                "● Soft 17: If ON, Dealer stands on soft 17.",
                "● ENHC: If ON, Dealer does not peek for blackjack.",
                "● Double: If “ALL”, player can double any two-card hand. If “9,10,11”, player can only double on hands with that score.",
                "● Depth: If “1/2”, the game is restarted each time half of the deck is reached. If “3/4”, the game is restarted each time ¾ of the deck is reached.",
                "In the table below, we invite you to define the rules of the game you wish to explore, and to establish a strategy for the player. The strategy is divided into three fundamental aspects: the count method, the decision method, and the bet method."
            ]
        },
        {
            _id: 1,
            titulo: "COUNTING METHOD",
            content: [
                "● Perfect Count (PC): If activated, the player knows the exact composition of the deck at any given moment. This allows him to make the best specific bet for that deck, according to the selected betting strategy.",
                "● Hi-Lo True Count (HLTC): If activated, the player knows the Hi-Lo \"True Count (TC)\" at each moment. With this, the player's notion of the deck composition is inaccurate, and makes the best bet based on the TC, according to the selected betting strategy. You can choose 2 levels of accuracy for the true count: 0 (a TC of 3.25 is rounded to 3) or 1 decimal (a TC of 3.25 is rounded to 3.3)."
            ]
        },
        {
            _id: 2,
            titulo: "DECISION METHOD",
            content: [
                "● Basic Strategy (BS): If activated, the player uses the basic strategy, according to the established set of rules.",
                "● Perfect Decision (PD): If activated, the player makes the best possible decision, considering the exact composition of the deck at each moment maximizing the EV."
            ]
        },
        {
            _id: 3,
            titulo: "STRATEGY GENERATOR",
            content: [
                "The Strategy Generator establishes an optimality criterion for the betting strategy. Two parameters are selected in this section:",
                "● Number of Rounds \"H\": The number of rounds for which the strategy is optimized.",
                "● Player's risk profile \"R\": This parameter is associated with the reward function used for training the neural networks. It captures the risk profile of different players."
            ]
        },
    ];

    return (
        <div className="flex bg-darkBlue2 pb-20 w-full">
            <div className="top-28 flex flex-col w-full gap-2 md:gap-10">
                {step2.map((elemento, index) => (
                    <div
                        className="flex flex-col w-full items-center justify-center py-3 md:py-5 px-5 md:px-10 rounded-lg bg-newBlue"
                        id={elemento._id}
                        key={elemento._id}
                        onClick={() => setExpanded(expanded === index ? null : index)}
                    >
                        <div className="flex w-full justify-start gap-3 md:justify-center items-center">
                            {expanded === index ? (
                                <RxChevronDown className="md:hidden text-3xl text-white" />
                            ) : (
                                <RxChevronRight className="md:hidden text-3xl text-white" />
                            )}
                            <h2 className="font-title font-medium text-center text-white text-2xl md:text-3xl">
                                {elemento.titulo}
                            </h2>
                        </div>
                        <div
                            className={
                                expanded === index
                                    ? "flex flex-col gap-4 py-6 items-center justify-center"
                                    : "hidden md:flex flex-col gap-4 py-6 items-center justify-center"
                            }
                        >
                            {elemento.content.map((item, itemIndex) => (
                                <p
                                    key={`${index}-${itemIndex}`}
                                    className="font-body text-gray-400 text-xl text-justify"
                                >
                                    {item}
                                </p>
                            ))}
                        </div>
                    </div>
                ))}

            <div className="flex flex-col w-full">
                <p className="text-justify font-body text-menuText md:text-xl mt-10 px-6 mb-4">
                    Once you have established a combination of game rules with a strategy for the player, you can adjust the "number of rounds" slider. This way you will be able to see the expected Bankroll Distribution for the player according to the number of rounds “N” played.
                </p>
                <p className="text-justify font-body text-menuText md:text-xl mt-10 px-6">
                    Depending on the scenario you have set, the expected Bankroll Distribution will be displayed for the strategy you have selected, under the rules you have set, and after a total of N hands played. The graph shows the expected Bankroll density and cumulative distribution curves. The table on the right offers the same information as the graph but may be more convenient for visualizing certain data. Finally, below the graph, you will find an interactive simulator where you can visualize the evolution of the player's bankroll over N hands played.
                </p>
            </div>

            </div>
        </div>
    );
}
