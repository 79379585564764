import { useEffect, useState } from "react";
import {HiX} from "react-icons/hi";
import Modal from 'react-modal';
import { IoClose } from 'react-icons/io5';

function SeetingsMobile({subscriptionPlan, BI, setBI, modalIsOpen, setModalIsOpen, darkMode, setDarkMode, setModalIsOpen2, 
    surrender, setSurrender, BJpays, setBJpays, split, setSplit, DAS, setDAS, splitAces, setSplitAces,
    showPercentaje, setShowPercentaje, s17, setS17, enhc, setEnhc, double, setDouble, decks, setDecks, risk, setRisk, 
    countMethod, setCountMethod, decisionMethod, setDecisionMethod, 
    hands, setHands, returnTarget, setreturnTarget, percentile, setPercentile, desv_cond, setDesvCond, changeStrategy, ModeCalculator, 
    setModeCalculator, depth, setDepth, setP0, setPa, setInputValue}){
    
    function Tooltip(id){
        document.getElementById(id).classList.toggle("invisible");   
    }

    const [disabled, setDisabled] = useState(true);
    // const [BI, setBI] = useState("");
    const step2 = ["Soft 17: “If ON, Dealer stands on soft 17”", 
    "ENHC: “If ON, Dealer does not peek for blackjack”",
    "Show percentage: “If ON, the EV of the decisions and of the next round are displayed”",
    ]
    
    useEffect(() => {
        if (countMethod === "hlc"){
            setDisabled(false);
        } else {
            setDisabled(true);
            setModeCalculator("0");
        }
    }, [countMethod])
    const closeModal = () => {
        setModalIsOpen(false);
    };

    useEffect(() => {
        setPa(BI);
        setP0(BI);
        setInputValue(BI);
    }, [BI])
    
    const openModal2 = () => {
        setModalIsOpen2(true);
        closeModal();
    };

    const limitBankroll = (event) => {
        if (event.target.value !== ""){
            let numero2;
            if (event.target.value.length > 0 && event.target.value[0] === "0"){
                numero2 = event.target.value.slice(1, event.target.value.length);
            } else {
                numero2 = event.target.value;
            }
            const numero = parseFloat(numero2)
            if (numero > 1000000) {
                setBI("1000000");
            } else if (numero < 0) {
                setBI("0");
            } else {
                setBI(numero2);
            }
        } else {
            setBI("0");
        }
      }



    return(
    <div>
        <Modal isOpen={modalIsOpen} onRequestClose={() => {closeModal()}} style={
            { overlay: {margin:0}, content: {padding: 0, inset: 20,  backgroundColor: darkMode? "#252f5a": 'white'}}}>
            <div className={darkMode ? "dark my-4 mx-4" : "my-4 mx-4"}>
    
            <div className="md:hidden flex items-center justify-end gap-2">
                <button onClick={() => {openModal2()}} className="flex shadow-xl justify-center items-center 
                border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer 
                bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700"> Tutorial </button>
                <button onClick={() => {closeModal()}}>
                    <HiX className="text-3xl dark:text-white text-black"/>
                </button>
            </div>
      
            <div className="flex flex-col justify-center items-center">
                <h1 className="text-2xl font-body text-sm text-black dark:text-gray-400 my-2 uppercase text-center"> 
                    Settings 
                </h1>
            </div>

            {/* <div className="flex- flex-col  border-2 rounded-lg border-black dark:border-gray-400 my-2 p-2 w-full">
                <label className="font-body text-sm text-black dark:text-white p-1 w-[50%]">
                     Calculator MODE 
                </label>
                <select className="rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white p-1" 
                    onChange={(e) => {setModeCalculator(e.target.value)}} value={ModeCalculator} disabled={disabled}>
                    <option value="0">  Full Card Counting  </option>
					<option value="1">  Count as input  </option>
                </select>
            </div> */}
            
            <div className="grid grid-cols-2 gap-2">
                <div className="flex flex-col items-center justify-center border-r-2 border-black p-2">
                    {/* --------------------------------- Columna izquierda -----------------------------------*/}
                    {/* <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> Show Percent </p> 
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" checked={showPercentaje} 
                        onChange={() => setShowPercentaje(!showPercentaje)}/>
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 
                        peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 
                        peer-checked:after:translate-x-full peer-checked:after:border-white 
                        after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white 
                        after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 
                        after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                        </div>
                        </label>
                    </div> */}

                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> Depth </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <select className="w-11 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(e) => setDepth(e.target.value)} value={depth}>
                            <option value="0.5">1/2</option>
                            <option value="0.75">3/4</option>
                        </select>
                        </label>
                    </div>
                    
                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2 "> Dark Mode </p>
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" checked={darkMode} onChange={() => setDarkMode(!darkMode)}/>
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </div>
                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> Soft 17 </p>
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" checked={s17} onChange={() => setS17(!s17)}/>
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </div>

                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> BJ Pays </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <select className="w-11 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(e) => setBJpays(e.target.value)} value={BJpays}>
                            <option value="3to2">3 to 2</option>
                        </select>
                        </label>
                    </div>

                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> Surrender </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <select className="w-11 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(e) => setSurrender(e.target.value)} value={surrender}>
                            <option value="notAllowed">Not Allowed</option>
                        </select>
                        </label>
                    </div>

                </div>
                {/* --------------------------------- Columna derecha -----------------------------------*/}
                <div className="flex flex-col items-center justify-center">
                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> ENHC </p>
                        <label className="relative inline-flex items-center cursor-pointer">
                        <input type="checkbox" value="" className="sr-only peer" checked={enhc} onChange={() => setEnhc(!enhc)}/>
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </div>

                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> Double </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <select className="w-11 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(e) => setDouble(e.target.value)} value={double}>
                            <option value="0">ALL</option>
                            <option value="1">"9,10,11"</option>
                        </select>
                        </label>
                    </div>

                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> Decks </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <select className="w-11 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(e) => setDecks(e.target.value)} value={decks}>
                            <option value="6">6</option>
                            <option value="8">8</option>
                            <option value="10">10</option>
                            <option value="12">12</option>
                        </select>
                        </label>
                    </div>

                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> D.A.S </p>
                        <label className="relative inline-flex items-center cursor-pointer opacity-50">
                        <input type="checkbox" value="" className="sr-only peer" checked={DAS}/>
                        <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                        </label>
                    </div>
                    
                    <div className="flex items-center justify-between w-full">
                        <p className="font-body text-sm text-black dark:text-gray-400 my-2"> Split </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <select className="w-11 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(e) => setSplit(e.target.value)} value={split}>
                            <option value={1}>Max 1 time</option>
                        </select>
                        </label>
                    </div>
                    
                </div>
            </div>
            {/*<div className="bg-white dark:bg-newBlue gap-6 my-2">
                {step2.map((step, index) => (
                <div className="my-2" key={`${index}A`}>
                    <p className="font-body text-sm text-black dark:text-gray-400" key={`${index}A`}>{index + 1}. {step}</p>
                </div>
                ))}
            </div>*/}

            <div className="flex- flex-col border-2 rounded-lg border-black dark:border-gray-400 mt-5 justify-center items-center">

                <div className="flex flex-col justify-center items-center">
                    <p className="text-1xl font-body text-sm text-black dark:text-gray-400 my-1 text-center"> 
                        Define the counting mehod 
                    </p>
                </div>


                <div className="flex justify-center items-center pt-1 pr-2 pl-2 pb-2">
                    <select className="rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white 
                    w-[60%]" 
                        onChange={(e) => {
                            setCountMethod(e.target.value);
                            if (e.target.value === "pc") {
                            setModeCalculator("fcc");
                            }
                            if (e.target.value === "hlc") {
                            setModeCalculator("ic");
                            }
                        }}
                        value={countMethod}>
                        <option value="pc">
                            {(subscriptionPlan===0||subscriptionPlan===2)? (
                                "Perfect Count (PC)"
                            ) : (
                                <>
                                <p className="text-green-500">&#128274;</p>
                                <p>Perfect Count (PC)</p>
                                </>
                            )}
                        </option>
                        <option value="hlc">
                            {(subscriptionPlan===1||subscriptionPlan===2)? (
                                "Hi-Lo True Count (HLC)"
                            ) : (
                                <>
                                <p className="text-green-500">&#128274;</p>
                                <p>Hi-Lo True Count (HLC)</p>
                                </>
                            )}
                        </option>
                    </select>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <p className="text-1xl font-body text-sm text-black dark:text-gray-400 my-1 text-center"> 
                        Define the decision method 
                    </p>
                </div>

                <div className="flex justify-center items-center pt-1 pr-2 pl-2 pb-3">
                    <select className="rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white 
                        w-[60%]" 
                            onChange={(e) => setDecisionMethod(e.target.value)} value={decisionMethod}>
                            <option value="pd">  Perfect Decision  </option>
                            <option value="bs">  Basic Strategy  </option>
                    </select>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <p className="text-1xl font-body text-sm text-black dark:text-gray-400 my-1 text-center"> 
                        Define your Initial Bankroll (IB):
                    </p>
                </div>

                <div className="flex justify-center items-center pt-1 pr-2 pl-2 pb-3">
                    <span className="font-body text-sm text-black dark:text-gray-400"> $ </span>
                    <label className="inline-flex relative items-center cursor-pointer">
                    <input type="number" className="rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white 
                    w-full" onChange={limitBankroll} value={BI} />
                    </label>
                </div>
            </div>

            <div className="flex- flex-col  border-2 rounded-lg border-black dark:border-gray-400 mt-2">
            <div className="flex flex-row justify-around items-center border-b-2 border-black dark:border-gray-400 
            w-full">
                   <div id='tooltip_helper' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[80%] rounded px-2 pb-1 absolute transform translate-x-[10%] -translate-y-[50%] z-20">
                    <IoClose onClick={()=>Tooltip("tooltip_helper")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                    <p className='font-body font-semibold text-gray-800 text-xs text-justify'>The optimization of the betting policy is an ambiguous problem, as there is no single optimality
                                                                                            criterion. If the expected return of a betting session is maximized, undesirable strategies are
                                                                                            obtained, which, despite having a high expected return, have a probability close to 1 of going
                                                                                            bankrupt (search for “St. Petersburg Paradox” for more information)
                    </p>
                    <p className='font-body font-semibold text-gray-800 text-xs text-justify pt-3'>
                    For this reason, the optimality criterion of a betting policy is defined through a valuation or
                    preference function. This function models the satisfaction a player perceives based on the gains
                    obtained and, in principle, varies for different players. The player's risk profile parameter "R" is
                    associated with a specific valuation function, which was used for training the neural networks that
                    model the betting policy (for more information, see the RESEARCH section).
                    </p>
                  </div>
                  <label onClick={()=>Tooltip("tooltip_helper")} className="font-title text-gray-400 underline hover:cursor-pointer">Help</label>
                <h1 className="text-2xl font-body text-sm text-black dark:text-gray-400 my-2 uppercase text-center"> 
                    Strategy Generator 
                </h1>
            </div>

            <div className="flex flex-col p-4">
            <div className="flex flex-row justify-between items-center gap-2">
                <p className="text-1xl font-body text-sm text-black dark:text-gray-400 my-2 text-center"> 
                Define the number of Rounds "H": </p>
                <select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" 
                onChange={(e) => setHands(e.target.value)} value={hands}>
                    <option value="50"> 50 </option>
					<option value="100"> 100 </option>
					<option value="250"> 250 </option>
                </select>
            </div>

            <div className="flex flex-row justify-between items-center gap-2">
                <p className="text-1xl font-body text-sm text-black dark:text-gray-400 my-2 text-center"> 
                Define the player's risk profile "R": </p>
                <select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" 
                onChange={(e) => setreturnTarget(e.target.value)} value={returnTarget}>
                    <option value="1"> 1 </option>
					<option value="2"> 2 </option>
                    <option value="3"> 3 </option>
                    <option value="4"> 4 </option>
                </select>
            </div>

            {/* <div className="flex flex-row justify-between items-center gap-2">
                <p className="text-1xl font-body text-sm text-black dark:text-gray-400 my-2 text-center"> 
                Define the dispersion level "D"
                </p>
                <select className="w-18 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" 
                onChange={(e) => setDesvCond(e.target.value)} value={desv_cond}>
                    <option value="0">  Level 1 </option>
					<option value="1.25">  Level 2 </option>
                </select>
            </div> */}
            <div className="flex justify-center items-center">
                <button onTouchStart={() => {changeStrategy()}} className="flex shadow-xl 
                    justify-center items-center border border-gray-600 rounded-lg font-body font-bold text-sm 
                    py-1.5 px-2 hover:cursor-pointer bg-gradient-to-br from-red-500 to-red-500 text-gray-700"> 
                    Apply Strategy
                </button>
            </div>
            </div>
            </div>

            </div>
        </Modal>
    </div>
    )
}

export default SeetingsMobile;