import Header from "../Header";
import Footer from "../Footer";
import  plot  from "../GraphChart";
import { useEffect, useState } from 'react';
import newWindow from "../../helpers/newWindow";
import { isMobile } from 'react-device-detect';
import { IoClose } from 'react-icons/io5';
import { BiDownArrowAlt } from 'react-icons/bi';
import { useAuth0 } from "@auth0/auth0-react";
import useFetchUser from "../../hooks/fetchUser";

import SimulationsMobile from '../Mobile/SimulationsMobile';
import { BsDot } from 'react-icons/bs';

import { count } from "mathjs";
import Chart from 'chart.js/auto';
import annotationPlugin from'chartjs-plugin-annotation';

import calculateSabot from "../../helpers/calculateSabot";


Chart.register(annotationPlugin)

var chart;
var chart1;

function PlayerEdge() {

    const [s17, setS17] = useState(true)
    const [enhc, setEnhc] = useState(false)
    const [double, setDouble] = useState(0)
    const [depth, setDepth] = useState(0.5)
	const [init_bankroll, setInit_bankroll] = useState(100)
	const [countingMethod, setCountingMethod] = useState('pc');
	const [decisionMethod, setDecisionMethod] = useState('pd');
	const [bettingMethod, setBettingMethod] = useState('custom');
	const [hands, setHands] = useState(100);
	const [ror, setRor] = useState(0.25);
	const [returnTarget, setReturnTarget] = useState(1);
	const [percentile, setPercentile] = useState(0.5);
	const [histogramas, setHistogramas] = useState([])
	const [graph, setGraph] = useState("a")
	const [f_percentiles, setFPercentiles] = useState([]);
	const [desv_cond, setDesvCond] = useState(0); 

	const [decks, setDecks] = useState(8);
	const [surrender, setSurrender] = useState('notAllowed'); //options: notAllowed / any / anyExceptAce
	const [BJpays, setBJpays] = useState('3to2');  //options: 3to2 
	const [split, setSplit] = useState(1); 
	const [DAS, setDAS] = useState(false); 
	const [splitAces, setSplitAces] = useState(false);

	const [manos, setManos] = useState(100);

	const [strategyHands, setStrategyHands] = useState(100);

	const {subscriptionPlan, expirationDate, cachedUser} = useFetchUser();
	console.log(expirationDate)
	useEffect(()=>{
		if (!isMobile) {
			getGraficos("hist")
			getGraficos("sim")	
		}  
  }, [])

	useEffect(()=>{
		if (!isMobile) {
			plotHistogramas(histogramas)
		}  
  }, [manos, graph])


	function Tooltip(id){
    document.getElementById(id).classList.toggle("invisible");   
  }

	function limitBankroll(){
    const initial_bankroll = document.getElementById('initial_bankroll');
    const numero = parseInt(initial_bankroll.value);
    if (numero > 1000000) {
      initial_bankroll.value = 1000000;  
    }
    if (numero < 1) {
      initial_bankroll.value = 1;  
    }
		setInit_bankroll(parseInt(initial_bankroll.value))
  }

	function getGraficos(tipo){
		setHands(strategyHands);
		setManos(strategyHands)
		MoveHands(strategyHands, strategyHands);
    //console.log(enhc, s17, double, depth, init_bankroll, countingMethod, decisionMethod, bettingMethod, hands, ror, percentile)
		var new_enhc = 1
		var new_s17 = 0
		if(!enhc){
			new_enhc = 0
		}
		if(s17){
			new_s17 = 1
		}
		//console.log(new_enhc, new_s17, double, depth, init_bankroll, countingMethod, decisionMethod, bettingMethod, hands, ror, percentile)
		if(tipo === "hist"){
			fetch(`${process.env.REACT_APP_BACKEND_URL}/grafico`,{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					"enhc": new_enhc,
					"s17": new_s17,
					"double": double,
					"depth": depth,
					"init_bankroll": init_bankroll,
					"countingMethod": countingMethod,
					"decisionMethod": decisionMethod,
					"bettingMethod": bettingMethod,
					"hands": strategyHands,
					"ror": ror,
					"percentile": percentile,
					"desvcond": desv_cond,
					"returnTarget": returnTarget,
				})
			}).then(res => res.json()).then(res => {setHistogramas(res.histogramas); setFPercentiles(res.factores_percentiles); plotHistogramas(res.histogramas)})	
		}
		else if(tipo === "sim"){
			fetch(`${process.env.REACT_APP_BACKEND_URL}/simulations`,{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					"enhc": new_enhc,
					"s17": new_s17,
					"double": double,
					"depth": depth,
					"init_bankroll": init_bankroll,
					"countingMethod": countingMethod,
					"decisionMethod": decisionMethod,
					"bettingMethod": bettingMethod,
					"hands": strategyHands,
					"ror": ror,
					"percentile": percentile,
					"n_manos_sim": manos,
					"desvcond": desv_cond,
					"returnTarget": returnTarget,
				})
			}).then(res => res.json()).then(res => plotSimulacion(res.simulacion))
		}
  }

	function plotHistogramas(info){
		
		var y_label = graph === "a" ? "F(B)" : "f(B)"
		var y_data = graph === "a" ? info[manos/10]?.cumulative : info[manos/10]?.histogram
		// Verificar si y_data está vacío o no definido
		var isDataEmpty = !y_data || y_data.length === 0;

		// Si y_data está vacío o es el gráfico acumulativo, el máximo es 1.
		// De lo contrario, calculamos el máximo basado en y_data.
		var maxYValue = isDataEmpty || graph === "a" ? 1 : Math.max(...y_data) * 1.1;
		if(chart){chart.destroy();}
		chart = new Chart("histograms", {
			type: 'bar',
			data: {
				labels: info[manos/10]?.bin_edges.map(edge => edge.toFixed(2)), 
				datasets: [{
					data: y_data,
					backgroundColor: 'rgba(75, 192, 192, 0.6)', 
					borderColor: 'rgba(75, 192, 192, 1)',
					borderWidth: 1
				}]
			},
			options: {
				plugins: {
					legend: {
						display: false,
					 } 
				}, 
				scales: {
					x: {
						type: 'category', 
						title: {
							display: true,
							text: 'Bankroll "B"',
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						}
					},
					y: {
						max: maxYValue,
						title: {
							display: true,
							text: y_label,
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						}
					}
				}
			}
		});
	}
	function plotSimulacion(data){
		
		const maxValue = Math.max(...data);
		if(chart1){chart1.destroy();}
		chart1 = new Chart("simulacion", {
			type: 'line',
			data: {
				labels: data.map((_, index) => index), 
				datasets: [
				{
					data: data,
					backgroundColor: 'rgba(75, 192, 192, 0.6)', 
					borderColor: '#0000ff',
					borderWidth: 2,
					pointRadius: 0,
				},
				]
			},
			options: {
				
				plugins: {
					legend: {
						display: false,
					},
					annotation: {
						annotations: [
						{
							type: 'line',
							mode: 'horizontal',
							scaleID: 'y',
							value: init_bankroll,
							borderColor: 'green',
							borderWidth: 2,
							label: {
									content: 'Initial Bankroll',
									display: true,
									position: 'end',
									backgroundColor: 'rgba(0, 128, 0, 0)',
									color: 'green',
									yAdjust: -10
									
							}
					}]
				}	 	 	 
				}, 
				scales: {
					x: {
						type: 'linear', 
						max: Math.max(parseInt(manos), hands),
						title: {
							display: true,
							text: 'Number of rounds',
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						},
						grid: {
							display: false, // Oculta las líneas verticales de la grilla
						},
					},
					y: {
						beginAtZero: true,
						title: {
							display: true,
							text: "Bankroll",
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						}
					}
				}
			}
		});
	}

	function MoveHands(value, max_value){
		setManos(value)
		const label = document.getElementById("hands");
		var percent = 0
		console.log(value)
		if(value === "1000"){
			percent = ((value-22)/ max_value) * 100;
		} 
		else if(value === "100"){
			percent = ((value-6)/ max_value) * 100;
		} 
		else {
			percent = ((value-10)/ max_value) * 100;
		}
		label.style.left = `calc(${percent}%)`;
	}
if (!isMobile){

  return (
    <div className="bg-darkBlue">
      <Header subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} newWindow={newWindow} />
			<div className="flex flex-col gap-6 pt-[5%] w-full">
			<div className="flex flex-col items-center justify-center py-8 w-full h-fit gap-10">
				<div className="flex justify-center">
					<p className="font-title font-medium text-white text-2xl md:text-4xl">SIMULATIONS</p>
				</div>
				<div className="flex flex-col items-center justify-center w-3/4 gap-5">
					<p className="text-justify font-body text-menuText">
					In this section, you will have access to the results of the simulations carried out within the framework of this research. Each simulation has been designed with a single player facing the dealer, using a 
					total of 8 decks. In those simulations where the player does not bet on all hands, we have included
					an imaginary player who plays a hand every time our test player chooses not to bet. We have
					considered all possible combinations of 4 game rules: ENHC, Soft17, Double and Depth:
					</p>
					<div className="flex flex-col items-start justify-center w-full ">
						<label className="font-body text-white text-xl">RULES</label>
						<ul className="list-disc ml-14 text-justify font-body text-menuText">
							<li><span className="underline">Soft 17:</span> If ON, Dealer stands on soft 17.</li>
							<li><span className="underline">ENHC:</span> If ON, Dealer does not peek for blackjack.</li>
							<li><span className="underline">Double:</span> If “ALL”, the player can double any two-card hand. If “9,10,11”, the player can only double on hands with that score.</li>
							<li><span className="underline">Depth:</span> If “1/2”, the game is restarted each time half of the deck is reached. If “3/4”, the game is restarted each time ¾ of the deck is reached.</li>
							
						</ul>
					</div>
					<p className="text-justify font-body text-menuText">
					In the table below, we invite you to define the rules of the game you wish to explore, and to establish 					a strategy for the player. the strategy is defined by 3 aspects: the counting method, the decision
					method, and the optimality criterion (Strategy Generator).</p>
					<div className="flex flex-col items-start justify-center w-full ">
						<label className="font-body text-white text-xl">COUNTING METHOD</label>
						<ul className="list-disc ml-14 text-justify font-body text-menuText">
							<li><span className="underline">Perfect Count (PC):</span> If activated, the player knows the exact composition of the deck at any
							given moment. This allows him to make the best specific bet for that deck, according to the
							optimality criterion (Strategy Generator).</li>
							<li><span className="underline">Hi-Lo True Count (HLTC):</span> If activated, the player knows the “Hi-Lo True Count (HLTC)" at each 
							moment. With this, the player's notion of the deck composition is inaccurate, and makes the
							best bet based on the TC, according to the optimality criterion (Strategy Generator).</li>	
						</ul>
					</div>
					<div className="flex flex-col items-start justify-center w-full ">
						<label className="font-body text-white text-xl">DECISION METHOD</label>
						<ul className="list-disc ml-14 text-justify font-body text-menuText">
							<li><span className="underline">Basic Strategy (BS):</span> If activated, the player uses the basic strategy, according to the established set of rules.</li>
							<li><span className="underline">Perfect Decision (PD):</span> If activated, the player makes the best possible decision, considering
															the exact composition of the deck at each moment, maximizing the expected return of each
															round.
															</li>	
						</ul>
					</div>
					<div className="flex flex-col items-start justify-center w-full"> 
						<label className="font-body text-white text-xl">
							STRATEGY GENERATOR
						</label>
						<p className="text-justify font-body text-menuText mt-2">
							The Strategy Generator establishes an optimality criterion for the betting strategy. Two parameters are selected in this section:
						</p>
						<ul className="list-disc ml-14 text-justify font-body text-menuText"> 
							<li className="py-2">
							<span className="underline">Number of Rounds "H":</span> The number of rounds for which the strategy is optimized.
							</li>
							<li className="py-2">
							<span className="underline">Player's risk profile "R":</span> This parameter is associated with the reward function used for training the neural networks. It captures the risk profile of different players.
							</li>
						</ul>
						<p className="text-justify font-body text-menuText mt-2">
							Once you have established a combination of game rules with a strategy for the player, you can adjust the "number of rounds" slider. This way you will be able to see the expected Bankroll Distribution for the player according to the number of rounds “N” played.
						</p>
						<p className="text-justify font-body text-menuText mt-2">
							Depending on the scenario you have set, the expected Bankroll Distribution will be displayed for the strategy you have selected, under the rules you have set, and after a total of N rounds played. The graph shows the expected Bankroll density and cumulative distribution curves. The table on the right offers the same information as the graph but may be more convenient for visualizing the data.
						</p>
						<p className="text-justify font-body text-menuText mt-2">
							Finally, below the graph, you will find an interactive simulator where you can visualize the evolution of the player's bankroll over H hands played. This simulator also depends on the configuration of the game rules and the defined strategy.
						</p>
					</div>
				</div>
				<div  className="flex items-start justify-center w-full gap-10">
					<div id="params" className="flex justify-around border border-white rounded-lg text-center w-3/5 h-[300px]">
						<div className="flex flex-col border-l border-white w-[60%]">
							<div className="border-b border-white p-2 h-[25%] flex items-center justify-center">
								<label className="text-justify font-body text-menuText text-lg">RULES</label>
							</div>
						
							<div className="flex flex-row w-full h-[75%]">
								{/* --------------------------------------- COLUMNA IZQUIERDA -------------------------------------------*/}
								<div className="flex flex-col justify-around items-start h-full p-2 w-[50%]" id="col1">
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">ENHC</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<input type="checkbox" checked={enhc} className="sr-only peer" id="enhc" onChange={(c)=>setEnhc(c.target.checked)}/>
											<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
										</label>
									</div>
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">Soft17</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<input type="checkbox" checked={s17} className="sr-only peer" id="s17" onChange={(c)=>setS17(c.target.checked)}/>
											<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
										</label>
									</div>
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">Double</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(c)=> {setDouble(c.target.value)}} value={double}>
												<option value="0">ALL</option>
												<option value="1">"9,10,11"</option>
											</select>
										</label>

									</div>
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">Depth</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(c)=> {setDepth(c.target.value)}} value={depth}>
												<option value={"0.5"}>1/2</option>
												<option value="0.75">3/4</option>
											</select>
										</label>
									</div>
									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText"> Decks </p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setDecks(c.target.value); calculateSabot(c.target.value)}} value={decks}>
											<option value="6">6</option>
											<option value="8">8</option>
											<option value="10">10</option>
											<option value="12">12</option>
										</select>
										</label>
									</div> 
								</div>
								{/* --------------------------------------- COLUMNA DERECHA -------------------------------------------*/}
								<div className="flex flex-col justify-around items-start h-full p-2 w-[50%]" id="col2">
									<div className=" flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">BJ pays</p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setBJpays(c.target.value)}} value={BJpays}>
											<option value="3to2">3 to 2</option>
										</select>
										</label>
									</div>

									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">Surrender</p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setSurrender(c.target.value)}} value={surrender}>
											<option value="notAllowed">not allowed</option>
										</select>
										</label>
									</div>

									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">Split </p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setSplit(c.target.value)}} value={split}>
											<option value={1}>max 1 time</option>
										</select>
										</label>
									</div>

									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">D.A.S</p>
										<label className="inline-flex relative items-center cursor-pointer opacity-50">
										{/*No se permite: onChange={(c)=>setDAS(c.target.checked)} */}
										<input type="checkbox" checked={DAS} className="sr-only peer" id="das" /> 
										<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
										</label>
									</div>

								</div>
							</div>
						</div>

						<div className="flex flex-col border-l border-white w-[40%]">
							<div className="border-b border-white p-2 h-[25%] flex flex-col items-center justify-center">
								<label className="text-justify font-body text-menuText text-lg">COUNT &</label>
								<label className="text-justify font-body text-menuText text-lg"> DECISION METHOD</label>
							</div>
							<div className="flex flex-col justify-around h-full p-2">
								<div className="flex flex-col items-start justify-center">
									<label className="text-justify font-body text-menuText">Define the counting method:</label>
									<select onChange={(e)=>{setCountingMethod(e.target.value)}} className="w-44 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center">
										<option value="pc">Perfect Count (PC)</option>
										<option value="hlc">HI-LO True Count (HLTC)</option>
									</select>
								</div>
								<div className="flex flex-col items-start justify-center">
									<label className="text-justify font-body text-menuText">Define the decision method:</label>
									<select onChange={(e)=>{setDecisionMethod(e.target.value)}} className="w-44 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center">
										<option value="pd">Perfect Decision (PD)</option>
										<option value="bs">Basic Strategy (BS)</option>
									</select>
								</div>
								<div className="flex flex-col items-start justify-center">
									<label className="text-justify font-body text-menuText">Initial Bankroll:</label>
									<input type="number" onChange={()=>{limitBankroll()}} id="initial_bankroll" value={init_bankroll} className="w-44 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center"/>
								</div>
							</div>
						</div>
						
						{/* <div className="flex flex-col border-l border-white w-[30%]">
							<div className="border-b border-white p-2">
								<label className="text-justify font-body text-menuText text-lg">BET STRATEGY</label>
							</div>
							<div className="flex flex-col justify-start gap-5 h-full p-2">
								<div className="flex flex-col justify-start w-full gap-3">
									<label className="text-justify font-body text-menuText">Count/Rules Independent</label>
									<div className="flex items-center justify-between px-2">
										<label className="text-justify font-body text-menuText "> - Flat Bet</label>
										<input type="radio" name="betting_method" value="fb" checked={bettingMethod === 'fb'} onChange={(e) => setBettingMethod(e.target.value)} disabled className="w-6 h-6 bg-red-500"/>
									</div>
									<div className="flex items-center justify-between px-2">
										<label className="text-justify font-body text-menuText"> - Flat Percentage</label>
										<input type="radio" name="betting_method" value="fpb" checked={bettingMethod === 'fpb'} onChange={(e) => setBettingMethod(e.target.value)} disabled className="w-6 h-6 bg-red-500"/>
									</div>
								</div>	
								<div className="flex flex-col justify-start w-full gap-3">
									<label className="text-justify font-body text-menuText text-lg">Count/Rules Dependent</label>
									<div className="flex items-center justify-between px-2">
										<label className="text-justify font-body text-menuText"> - Custom Strategy</label>
										<input type="radio" name="betting_method" value="custom" checked={bettingMethod === 'custom'} onChange={(e) => setBettingMethod(e.target.value)} className="w-6 h-6 bg-red-500"/>
									</div>
								</div>
							</div>
						</div> */}
					</div>
					<div id="custom_strategy" className="flex justify-around border border-white rounded-lg text-center w-1/5 h-[300px]">
						<div className="flex flex-col w-full">
							<div className="flex justify-around items-center border-b border-white p-2 h-[25%]">
								<label className="font-body text-menuText text-lg">STRATEGY GENERATOR</label>
								<div id='tooltip_helper' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[300px] rounded px-2 pb-1 absolute transform translate-y-[60%]">
									<IoClose onClick={()=>Tooltip("tooltip_helper")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
									<p className='font-body font-semibold text-gray-800 text-xs text-justify'>The optimization of the betting policy is an ambiguous problem, as there is no single optimality
									 criterion. If the expected return of a betting session is maximized, undesirable strategies are
									obtained, which, despite having a high expected return, have a probability close to 1 of going
									bankrupt (search for “St. Petersburg Paradox” for more information).
									</p>
									<p className='font-body font-semibold text-gray-800 text-xs text-justify'>
									For this reason, the optimality criterion of a betting policy is defined through a valuation or
									preference function. This function models the satisfaction a player perceives based on the gains
									obtained and, in principle, varies for different players. The player's risk profile parameter "R" is
									associated with a specific valuation function, which was used for training the neural networks that
									model the betting policy (for more information, see the RESEARCH section).
									</p>
								</div>
								<label onClick={()=>Tooltip("tooltip_helper")} className="font-body text-menuText text-sm underline cursor-pointer">HELP</label>
							</div>
							<div className="flex flex-col justify-around items-start h-full p-2">
								<div className="flex flex-col w-full items-start justify-between">
									<label className="text-justify font-body text-menuText">Define the number of rounds "H":</label>
									<select className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center" onChange={(e)=>setStrategyHands(e.target.value)} value={strategyHands}>
										<option value="50">50</option>
										<option value="100">100</option>
										<option value="250">250</option>
									</select>
								</div>
								<div className="flex flex-col w-full items-start justify-between">
									<label className="text-justify font-body text-menuText">Define the player's risk profile "R":</label>
									<select className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center" onChange={(c)=> {setReturnTarget(c.target.value)}} value={returnTarget}>
										<option value="1">1</option>
										<option value="2">2</option>
										<option value="3">3</option>
										<option value="4">4</option>
									</select>
								</div>
								{/* <div className="flex flex-col w-full items-start justify-between">
									<label className="text-justify font-body text-menuText">Define the dispersion level "D":</label>
									<select className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center" onChange={(c)=> {setDesvCond(c.target.value)}} value={desv_cond}>
										<option value="0">Level 1</option>
										<option value="1.25">Level 2</option>
									</select>
  
								</div>  */}
							</div>
						</div>
					</div>
				</div>
				<div className="flex w-3/4 justify-center items-center gap-7">
					<button onClick={()=>getGraficos("hist")} className="bg-red-500 rounded-lg py-2 px-3 font-title text-white font-medium ">APPLY PARAMETERS</button>
					<label className="font-title font-medium text-menuText text-xl">Number of rounds:</label>
					<div className="flex flex-col w-1/2">
						<div className="flex w-full relative items-end">
							<label className="absolute font-body text-menuText right-0" id="hands">N={manos}</label>
						</div>
						<input
							type="range"
							id="n_manos"
							min="0"
							max={hands} // Asegúrate de que max esté vinculado a hands
							step="10"
							className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-red-500"
							value={manos}
							onChange={(e) => MoveHands(e.target.value, hands)}
						/>
						<div className="flex w-full justify-between">
							<label className="text-justify font-body text-menuText">0</label>
							<label className="font-body text-menuText">{hands}</label>
						</div>
					</div>
					
				</div>
				<div className="flex items-center justify-center w-5/6 gap-10 h-[575px]">
					<div className="flex flex-col justify-between bg-darkBlue3 p-3 rounded-xl border border-gray-600 w-1/2 h-full">
						<div className="flex justify-center gap-2">
							<div className="flex justify-center space-x-0.5 rounded-xl bg-gray-200 p-1 w-1/2 ">
								<div className="w-1/2">
									<input type="radio" id="acumulada" name="dist" className="peer hidden" checked={graph === "a" ? true : false} onChange={()=>setGraph("a")}/>
									<label htmlFor='acumulada' className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-red-500 peer-checked:font-bold peer-checked:text-white font-title font-medium">Cumulative</label>
								</div>
								<div id="showDensidad" className="w-1/2">
									<input type="radio" id="densidad" name="dist" className="peer hidden" checked={graph === "d" ? true : false} onChange={()=>setGraph("d")}/>
									<label htmlFor='densidad' className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-red-500 peer-checked:font-bold peer-checked:text-white font-title font-medium">Density</label>
								</div>  
							</div>
						</div>
						<div className="flex items-center justify-center w-full h-[80%] p-1">
							<canvas id="histograms" height="400" width="650"></canvas>
						</div>    
					</div>
					
					<div className="flex flex-col justify-center items-center border border-gray-700 rounded-lg w-1/2 h-full">
						<div className="flex w-full justify-between items-center h-24">
							<label className="flex items-center justify-center text-center font-body text-menuText w-[13%] h-full border-r border-gray-700 p-1">Factor</label>
							<label className="flex items-center justify-center text-md font-body text-menuText h-full w-[27%] p-1">B = Factor <BsDot/> IB</label>
							<label className="flex items-center justify-center text-center font-body text-menuText h-full w-[30%] p-1 border-l border-gray-700">Probability of ending up with a bankroll equal or LESS than "B"</label>
							<label className="flex items-center justify-center text-center font-body text-menuText h-full w-[30%] p-1 border-l border-gray-700">Probability of ending up with a bankroll equal or GREATER than "B"</label>
						</div>
						{(() => {
								const options = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.25, 2.5, 2.75, 3, 3.5, 4, 4.5, 5];
								const cells = []
								
								for (var i = 0; i < options.length; i++) {
									
									cells.push(
										<div className="flex w-full justify-between items-center text-center">
											<label className="font-body text-menuText w-[13%] border-t border-r border-gray-700">{options[i]}</label>
											<label className="font-body text-menuText w-[27%] border-t border-r border-gray-700">{!isNaN((options[i]*init_bankroll).toFixed(0)) ? (options[i]*init_bankroll).toFixed(0) : 0}</label>
											<label className="font-body text-menuText w-[30%] border-t border-r border-gray-700">{f_percentiles[manos/10] !== undefined ? `${(f_percentiles[manos/10][i][0]*100).toFixed(1)}%` : "0%"}</label>
											<label className="font-body text-menuText w-[30%] border-t border-gray-700">{f_percentiles[manos/10] !== undefined ? `${(f_percentiles[manos/10][i][1]*100).toFixed(1)}%` : "0%"}</label>
										</div>
									)
								}
								console.log(cells.length)
								return cells;
							})()}	
					</div>
				</div>
				<div className="flex flex-col items-center justify-between p-5 w-fit bg-gray-300 h-[500px] rounded-lg">
					<div className="flex justify-center w-full relative">
						<h3 className="font-title font-medium text-black text-2xl md:text-4xl">Simulator</h3>
						<button onClick={()=>getGraficos("sim")} className="absolute bg-red-500 rounded-lg py-2 px-3 font-title text-white font-medium right-0">SIMULATE</button>
					</div>
					<canvas id="simulacion" height="400" width="900"></canvas>			
				</div>
			</div>
			
			{/*
			<div className="flex justify-center">
				<p className="text-sm font-body text-menuText">*The charts and statistics displayed for risks greater than 1 are not technically precise and only offer a rough estimate of reality</p>
			</div>*/}
			{/*<div className="flex flex-col bg-darkBlue2 px-[20%] py-20">
				<p className="text-justify font-body text-menuText text-xl">
					This section details the results obtained from our research. You may wonder what is the 
					expected performance for a player using our strategy. In this section, we present the 
					results of 3 simulations that we have implemented to answer this question. 
				</p>
				<p className="text-justify mt-5 font-body text-menuText text-xl">
					Our simulations are based on standard blackjack rules, including an 8-deck deck, a cut 
					in the middle (stricter than in physical casinos), dealer stands with a soft 17, blackjack 
					that pays 3 to 2 and the possibility to double down with any hand (We are currently 
					conducting further simulations involving various combinations of rules to extend our analysis). 
					Each simulation has a specific objective:
				</p>
				<div className="flex flex-col justify-center items-center pt-20 gap-3">
					<p className="font-title font-medium text-white text-xl">Simulation 1, "1 bet"</p>
					<p className="text-justify font-body text-menuText text-xl">
						A player is simulated sitting alone at the table against the dealer. There are no other 
						players at the table and, in case it is not optimal to bet, cards are drawn from the deck 
						in the same way as in an average hand. The goal is to find the maximum theoretical return 
						from the strategy by betting on a single position.
					</p>
					<div className="flex flex-col w-full items-center gap-5">
						<div className="flex items-start w-full">
							<p className="font-title font-medium text-white text-lg underline">Results:</p>
						</div>
						
						<div className="grid grid-cols-4 border border-gray-600 rounded-lg">
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-gray-600 bg-newBlue rounded-tl-lg">Hands Wagered</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue">Median</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue">Mean</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue rounded-tr-lg">Standard Deviation</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">1</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00001674</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00003349</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">0.00578823</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">100</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00167553</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00335478</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">0.05812277</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">10000</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.18224174</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.39782394</p>
							<p className="font-body text-white text-md text-center p-2 border-l  border-gray-600">0.88179577</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col justify-center items-center pt-20 gap-3">
					<p className="font-title font-medium text-white text-xl">Simulation 2, "3 bets"</p>
					<p className="text-justify mt-5 font-body text-menuText text-xl">
						A player is simulated using 3 positions against the dealer. There are no other players at the 
						table, the same amount is bet on each of the positions and, if it is not optimal to bet, cards 
						are drawn from the deck in the same way as in Simulation 1. The goal is to find out the 
						performance of the strategy by diversifying the bets in parallel.
					</p>
					<div className="flex flex-col w-full items-center gap-5">
						<div className="flex items-start w-full">
							<p className="font-title font-medium text-white text-lg underline">Results:</p>
						</div>
						
						<div className="grid grid-cols-4 border border-gray-600 rounded-lg">
						<p className="font-title font-medium text-white text-md text-center p-2 border-b border-gray-600 bg-newBlue rounded-tl-lg">Hands Wagered</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue">Median</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue">Mean</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue rounded-tr-lg">Standard Deviation</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">1</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00007760</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00015240</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">0.01223285</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">100</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00779011</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.01535592</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">0.12464920</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">10000</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">2.17274746</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">4.59018842</p>
							<p className="font-body text-white text-md text-center p-2 border-l  border-gray-600">8.5421429</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col justify-center items-center pt-20 gap-3">
					<p className="font-title font-medium text-white text-xl">Simulation 3, "1 bet, 2+ players"</p>
					<p className="text-justify mt-5 font-body text-menuText text-xl">
						One player is simulated using the first seat at the table, with 2 additional players sitting 
						at the other seats, pretending to draw cards randomly from the deck. The goal is to find out 
						how the variability induced by the additional players affects the performance of the strategy.
					</p>
					<div className="flex flex-col w-full items-center gap-5">
						<div className="flex items-start w-full">
							<p className="font-title font-medium text-white text-lg underline">Results:</p>
						</div>
						
						<div className="grid grid-cols-4 border border-gray-600 rounded-lg">
						<p className="font-title font-medium text-white text-md text-center p-2 border-b border-gray-600 bg-newBlue rounded-tl-lg">Hands Wagered</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue">Median</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue">Mean</p>
							<p className="font-title font-medium text-white text-md text-center p-2 border-b border-l border-gray-600 bg-newBlue rounded-tr-lg">Standard Deviation</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">1</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00001179</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00002745</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">0.00559736</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">100</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00117960</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.00274909</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">0.05616943</p>

							<p className="font-body text-white text-md text-center p-2 border-gray-600">10000</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.12512144</p>
							<p className="font-body text-white text-md text-center p-2 border-l border-gray-600">1.31591448</p>
							<p className="font-body text-white text-md text-center p-2 border-l  border-gray-600">0.79817225</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col justify-center items-center pt-16">
					<p className="font-title font-medium text-white text-3xl underline">General considerations</p>
					<p className="text-justify mt-5 font-body text-menuText text-xl">
						The results shown in the table correspond to the factor by which the initial equity is multiplied 
						for each number of hands. A median X means that half of the times it was multiplied by more than 
						the indicated factor. A mean X is the average factor by which the indicated factor was multiplied. 
					</p>
					<p className="text-justify mt-5 font-body text-menuText text-xl">
						As a general result, approximately <span className="text-justify mt-5 font-body text-white text-xl"> ~20% of the hands were wagered</span> , and it was concluded that <span className="text-justify mt-5 font-body text-white text-xl">the growth 
						of the initial equity is exponential with respect to the number of hands wagered,</span> having noticeably 
						better returns in the long term. It can be deduced that the hypotheses put forward are correct: <span className="text-justify mt-5 font-body text-white text-xl">by incorporating 
						more players to the game, the strategy deteriorates</span> due to the increase in uncertainty, while <span className="text-justify mt-5 font-body text-white text-xl">multiple bets work 
						as a return enhancer.</span> Therefore, in theory, <span className="text-justify mt-5 font-body text-white text-xl">the most effective strategy would be to place as many side bets as 
						possible in the game, trying to minimize the presence of additional participants.</span>
					</p>
				</div>
				<div className="flex flex-col justify-center items-center pt-16">
					<p className="font-title font-medium text-white text-3xl underline">"Risk" in the strategy</p>
					<p className="text-justify mt-5 font-body text-menuText text-xl">
						The three simulations were carried out using the optimal strategy (risk=1). The risk levels in the strategy 
						involve increasing the optimal bet amount, as if one had started with the initial amount multiplied by a 
						certain factor, depending on the selected risk level. The higher the risk, the higher the factor that 
						weights the initial amount. 
					</p>
					<p className="text-justify mt-5 font-body text-menuText text-xl">
						This mechanism makes it possible to keep the median approximately constant, but amplifies both positive and 
						negative streaks. It should be noted that the results shown in the graphs above are estimates based on the 
						simulations performed and are not rigorously accurate. We are currently running more simulations to analyze 
						the actual returns associated with different levels of risk.
					</p>
				</div>
			</div>*/}			
			</div>   
			<Footer/>
		</div>
		)
  } else {
	return(
		<div className="flex flex-col w-screen">
			<SimulationsMobile subscriptionPlan={subscriptionPlan} expirationDate={expirationDate}/>
		</div>
	)

  }

// Para testear movil: 
//   return(
// 	<div className="flex flex-col w-screen">
// 		<SimulationsMobile/>
// 	</div>
// 	)
}

export default PlayerEdge;
