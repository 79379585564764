import Modal from 'react-modal';
import TutorialMobile from "./ModalTutorial";
import SeetingsMobile from "./ModalSettings";
import zoneCards from './zoneCards';
import zoneMedium from './zoneMedium';
import ZoneKeyboards from './zoneKeyboards';
import HiLoCalculator from './HiLoCalculator';

Modal.setAppElement('#root');

function renderBlackjackApp ({subscriptionPlan, BI, setBI, roundsPlayed, setRoundsPlayed, surrender, setSurrender, BJpays, setBJpays, split210, setSplit210, DAS, setDAS, splitAces, setSplitAces, darkMode, setDarkMode, showMenu, setShowMenu, modalIsOpen, setModalIsOpen, 
    modalIsOpen2, setModalIsOpen2, showPercentaje, setShowPercentaje, s17, setS17, enhc, setEnhc, double, 
    setDouble, decks, setDecks, risk, setRisk, cola, colaPlayer, colaDealer, StatePlayer, 
    StateDealer, pa, p0, sumaPlayer, sumaDealer, disabledButton, prob, ResetCountMobile, apuesta, 
    handleTouchStart, setStatePlayer, setStateDealer, deleteCard, cartas, ClearHandsMobile, 
    GetBestBet, changeBackRoll, handleInputChange, inputValue, manejoFocus, BestDecision, 
    ChangeStatePlayer, ChangeStateDealer, setApuesta, setEdge, setDisabledButton, setInputValue, setP0, setPa,
    cargandoDecision, cargandoBet, setCargandoBet, countMethod, setCountMethod, decisionMethod, setDecisionMethod, 
    hands, setHands, returnTarget, setreturnTarget, percentile, setPercentile, desv_cond, setDesvCond, setColaPlayer, setColaDealer, 
    cartasPlayerTotal, setCartasPlayerTotal, setCartasDealerTotal, setCartasTotal, cartasDealerTotal, cartasTotal, setCola, 
    changeStrategy, EV, setEV, std, setStd, ModeCalculator, setModeCalculator, hl_count, setHL_Count,
    depth, setDepth,alpha, k_value,c_value
  }){

    const openModal = () => {
      setModalIsOpen(true);
    };
        
    const TutorialMobileOut = TutorialMobile({ modalIsOpen2, setModalIsOpen2, darkMode, setModalIsOpen });
    const ModalSettingsOut = SeetingsMobile({subscriptionPlan, BI, setBI, modalIsOpen, setModalIsOpen, darkMode, setDarkMode, setModalIsOpen2, 
      surrender, setSurrender, BJpays, setBJpays, split210, setSplit210, DAS, setDAS, splitAces, setSplitAces,
      showPercentaje, setShowPercentaje, 
      s17, setS17, enhc, setEnhc, double, setDouble, decks, setDecks, risk, setRisk, countMethod, setCountMethod, 
      decisionMethod, setDecisionMethod,
      hands, setHands, returnTarget, setreturnTarget, percentile, setPercentile, desv_cond, setDesvCond, changeStrategy, ModeCalculator, 
      setModeCalculator, depth, setDepth, setP0, setPa, setInputValue});
    const ZoneCardsOut = zoneCards({ cola, openModal, ResetCountMobile, disabledButton, cartas, countMethod, 
      ModeCalculator, hl_count, setHL_Count });
    const ZoneMediumOut = zoneMedium({ subscriptionPlan, BI, setBI, roundsPlayed, setRoundsPlayed, GetBestBet, setDisabledButton, cartas, s17, decks, double, enhc, p0, pa, 
      risk, setApuesta, setEdge, setCargandoBet, disabledButton, cargandoBet,
      changeBackRoll, inputValue, setInputValue, setP0, setPa, manejoFocus,
      colaPlayer, colaDealer, sumaPlayer, sumaDealer, BestDecision, cargandoDecision, prob, showPercentaje, apuesta,
      handleInputChange, EV, setEV, std, setStd, hands, returnTarget, percentile, ModeCalculator, countMethod, 
      decisionMethod, hl_count, depth, alpha, k_value, c_value, desv_cond,
    });
    const ZoneKeyboardOut = ZoneKeyboards({  darkMode, handleTouchStart, disabledButton, StatePlayer, StateDealer, 
      ClearHandsMobile, setStateDealer, setStatePlayer, setDisabledButton, ChangeStatePlayer, ChangeStateDealer, 
      deleteCard, cola, setCola, setColaPlayer, setColaDealer, cartasPlayerTotal, 
      setCartasPlayerTotal, setCartasDealerTotal, setCartasTotal, cartasDealerTotal, cartasTotal, ModeCalculator})
    const HiLoCalculatorOut = HiLoCalculator({modalIsOpen, subscriptionPlan, openModal, decisionMethod, setDecisionMethod, BI, setBI, returnTarget, setreturnTarget,hands});
    return(
        <div className={darkMode ? "dark" : null}>
          <div>
            {ModalSettingsOut}
          </div>
          <div>
            {TutorialMobileOut}
          </div>
          <div className={darkMode ? "flex flex-col h-screen w-screen" : "flex flex-col  h-screen w-screen"}>
          <div className="flex flex-col p-1 gap-2 rounded-br-md rounded-l-md bg-white dark:bg-darkBlue h-screen w-screen">
            {countMethod === "pc"?(<>{ZoneCardsOut} {ZoneMediumOut} {ZoneKeyboardOut}</>):
            countMethod === "hlc"?(<>{HiLoCalculatorOut}</>): null}
          </div>
          </div>
        </div>
  )
};
export default renderBlackjackApp;