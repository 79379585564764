import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import newWindow from "../helpers/newWindow";

function CelMenu({ showMenu, subscriptionPlan, expirationDate }) {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  return(
    showMenu && <div className="md:hidden grid grid-rows-3 text-center items-center bg-footer mt-14 pb-3 gap-2 fixed w-full z-20">
      <Link to={"/"} className="font-menu text-white text-xl">
        Home
      </Link>
      {/*<Link to={"/algorithm"} className="font-menu text-white text-xl">
        Algorithm
      </Link>*/}
      {isAuthenticated?      
      <p onClick={() => newWindow()} className="font-menu text-white text-xl"> Calculator </p>
        :
      <p onClick={() => {loginWithRedirect()}} className="font-menu text-white text-xl"> Calculator </p>
      }
      <Link to={"/simulations"} className="font-menu text-white text-xl">
        Simulations
      </Link>
      <Link to={"/aboutus"} className="font-menu text-white text-xl">
        About Us
      </Link>
      <Link to={"/blog"} className="font-menu text-white text-xl">
        Blog
      </Link>
      <Link to={"/research"} className="font-menu text-white text-xl">
        Research
      </Link>
      {isAuthenticated?      
      <Link to={`/profile?subscriptionPlan=${subscriptionPlan}&expirationDate=${expirationDate}`} className="font-menu text-white text-xl">
        Profile
      </Link> : null
      }
    </div>
  )
}
export default CelMenu;