import Header from "../Header";
import Footer from "../Footer";
import Plans from "../Plans";
import { useState, useEffect } from "react";
import newWindow from "../../helpers/newWindow";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchUser from "../../hooks/fetchUser";

function Subscriptions() {
  const {subscriptionPlan, expirationDate, cachedUser} = useFetchUser();
  return (
    <div className=" flex flex-col justify-between h-screen">
      {/* <Header newWindow={newWindow} /> */}
      <Header subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} newWindow={newWindow} />
      <div className="bg-darkBlue py-16">
        <Plans user={cachedUser}/>
      </div>
			<Footer/>
    </div>
  )
}

export default Subscriptions;