import Chart from 'chart.js/auto';

var math = require('mathjs')
var LogNormal = require( '@stdlib/stats-base-dists-lognormal' ).LogNormal;
var chart;
const mus0 = 0.00001674124157786;
const sigmas0 = 0.00578799239754929;
const mus1 = 0.00007759924789;
const sigmas1 = 0.01223052406320;
const mus2 = 0.000011789097503;
const sigmas2 = 0.005597157753689;

function plot(n_manos, densidad) {

	let tipo_de_sim = 0
	let sigma = 0
	let mu = 0
	let datos;
	let riesgo = 1;
	
	if (tipo_de_sim === 0) {
		sigma = math.sqrt(n_manos) * sigmas0;
		mu = n_manos * mus0;
	}
	else if (tipo_de_sim === 1) {
		sigma = math.sqrt(n_manos) * sigmas1;
		mu = n_manos * mus1;
	}
	else if (tipo_de_sim === 2) {
		sigma = math.sqrt(n_manos) * sigmas2;
		mu = n_manos * mus2;
	}

	//nivel de riesgo para la funcion
    let risk = {
		1: 1,
		2: 4,
		3: 7,
        4: 10,
        5: 15,
	}

    //creacion de la funcion

    let lognorm_dist = new LogNormal(mu, sigma);
    let x = math.range(0, 60, 0.05).toArray();
	const pdf = x.map((val) => {
		if(lognorm_dist.pdf((val + risk[riesgo] - 1)/risk[riesgo]).toFixed(3) > 0.001){
			return lognorm_dist.pdf((val + risk[riesgo] - 1)/risk[riesgo]);
		}
	});
	const cdf = x.map((val) => {
		if(lognorm_dist.cdf(((val + risk[riesgo] - 1)/risk[riesgo]).toFixed(2)) < 0.99){
			return lognorm_dist.cdf((val + risk[riesgo] - 1)/risk[riesgo]);
		}
	})
	
	if (densidad) {
		datos = pdf.filter(x => x !== undefined);
	}
	else {
		datos = cdf.filter(x => x !== undefined);
	}
	x = x.slice(0, datos.length)
	x = x.map((val) => val.toFixed(2))

	if(chart){
        chart.destroy();
    }

	chart = new Chart("myChart", {
		type: "line",
		data: {
			labels: x,
			datasets: [{
				backgroundColor: "rgba(239,68,68,255)",
				borderColor: "rgba(239,68,68,255)",
				data: datos
			}]
		},
		options: {
			plugins: {
				legend: {
					display: false,
       	} 
			}, 
			scales: {
        y: {
          ticks: {
            color: 'white'
          },
          grid: {
            color: 'gray'
          }
        },
        x: {
          ticks: {
            color: 'white'
          },
          grid: {
            color: 'gray'
          }
        }
      } 
    }
	});
    
}

export default plot;