import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import newWindow from "../../helpers/newWindow";
import CelMenu from "../CelMenu";
import Footer from "../Footer";
import Header from "../Header";
import { useNavigate } from 'react-router-dom';
import { RxChevronDown, RxChevronRight  } from 'react-icons/rx';
import { useAuth0 } from "@auth0/auth0-react";
import checkExpirationDate from "../../helpers/checkExpirationDate";
import useFetchUser from '../../hooks/fetchUser';

const clientId = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_ID;
const client_secret = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_SECRET;
const domain = process.env.REACT_APP_MANAGEMENT_BJ_DOMAIN;

function AllPosts() {
  //const [subscriptionPlan, setSubscriptionPlan] = useState(-1); //options: -1 (no plan)/ 0 (Perfect)/ 1 (HiLo)/ 2 (Full)
	//const [expirationDate, setExipirationDate] = useState(null); 
	//const [cachedUser, setCachedUser] = useState(null);

  const [showMenu, setShowMenu] = useState(false);
  const [posts, setPosts] = useState([])
  const [clicked, setClicked] = useState(0)
  const [show, setShow] = useState()
  const navigate = useNavigate();

  const {subscriptionPlan, expirationDate, cachedUser} = useFetchUser();

  const {
    loginWithRedirect,
    isAuthenticated,
    user
    } = useAuth0();
  
  const getRole = () => {
    const userRoles = user['https://parametros_auth0.com/roles'].map((role) => role);
    return userRoles
  };  

  const scrollToPost = (postId) => {
    setClicked(postId);
    const element = document.getElementById(postId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: "start" });
    }

  };

  const CopyLink = (postId) => {
    // Se genera el link al post 
    var link = `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/blog#${postId}`
    navigator.clipboard.writeText(link)

    // Se avisa que se copió el link y luego desaparece el mensaje
    const copied = document.getElementById(`copied_${postId}`);
    copied.innerHTML = 'Copied!'
    setTimeout(function() {
      copied.innerHTML = ''
    }, 2000);
  }

  const CopyEmail = () => {
    // Se genera el link al post 
    var email = "contact@bjtheorem.com"
    navigator.clipboard.writeText(email) 
    const copied = document.getElementById("copied_email");
    copied.innerHTML = 'Email copied to clipboard!'
    setTimeout(function() {
      copied.innerHTML = ''
    }, 2000);
  }

  const DeletePost = (postId) => {
    
    try{
      //reload page
      fetch(`${process.env.REACT_APP_BACKEND_URL}/eliminar_publicacion/${postId}`,{
        method: "DELETE",
      }).then((data) => data.json()).then((data) => {window.location.reload()})
      
    } catch {
      alert("An error occurred while deleting the post")
    }
  };

  function getYouTubeVideoId(link) {
    // Patrón de expresión regular para obtener el ID del video de YouTube
    const youtubePattern = /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([\w-]{11})/;
  
    // Ejecutar la expresión regular para extraer el ID del video
    const match = link.match(youtubePattern);
  
    // Si se encontró un ID, devolverlo; de lo contrario, devolver null
    return match ? match[1] : null;
  }

  function showPost(postId) {
    if(show === postId){
      setShow(null)
    } else {
      setShow(postId)
    }
  }
  /*
  useEffect(() => {
    if (user!=undefined && user!=null) {
      setCachedUser(user); 
    }
  }, [user]);

  useEffect(() => {
    const fetchData = (user) => {
      const managementApiOptions = {
        method: 'POST',
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: clientId,
          client_secret: client_secret,
          audience: `https://${domain}/api/v2/`
        })
      };
  
      // console.log("managementApiOptions: ", managementApiOptions);
    
      fetch(`https://${domain}/oauth/token`, managementApiOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const managementApiToken = data.access_token;
          // console.log("managementApiToken: ", managementApiToken);
  
          if (user) {
            console.log("Querying current user metadata");
            const queryMetadataOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${managementApiToken}`,
                    'Content-Type': 'application/json',
                },
            };
  
            fetch(`https://${domain}/api/v2/users/${user.sub}`, queryMetadataOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                }).then(userData => {
                  console.log('User meta_data:', userData.user_metadata);
                  const planNumber = checkExpirationDate(userData.user_metadata);
                  console.log("planNumber: ", planNumber)
                  setSubscriptionPlan(planNumber);
                  setExipirationDate(userData.user_metadata.expiration_date);
                  // console.log("expirationDate: ", expirationDate)
                })
                .catch(error => {
                  console.error('There was a problem with the API call:', error);
                });
          } else {
            console.log("No user found");
          }
        });
    };
    fetchData(cachedUser);
  }, [cachedUser]);
  */
  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/obtener_publicaciones`,{
          method: "GET",
    }).then((data) => data.json()).then((data) => setPosts(data))
    

    // Verificar si hay un hash en la URL
    setTimeout(function(){
    const hash = window.location.hash;
    if (hash) {
  
      // Extraer el ID del hash
      const id = hash.slice(1); // Eliminamos el primer carácter "#" del hash

      // Intentar encontrar el elemento con el ID correspondiente
      const element = document.getElementById(id);
      if (element) {
        // Realizar el desplazamiento al elemento si se encuentra
        element.scrollIntoView({ behavior: 'smooth', block: "center" });
      }
    }
    }, 1000) 
    
  }, [user])
 // query se ejecuta solamente al conocer el user


  return(

    <div className="bg-darkBlue h-fit">
      <Header subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu}/>
      <CelMenu showMenu={showMenu} subscriptionPlan={subscriptionPlan} expirationDate={expirationDate}/>
      <div className={showMenu ? "flex flex-col py-32 items-center justify-center gap-10 py-52 md:py-28 md:px-36 px-5" : "flex flex-col pt-24 items-center justify-center gap-10 py-20 md:py-28 md:px-16 px-5"}>
        <div className="w-full flex flex-col items-center justify-center gap-5 md:px-60">
          <div className='relative w-full flex items-center justify-center'>
            <h1 className="font-title font-medium text-center text-white text-2xl md:text-4xl">BLOG & FAQs</h1>
            <label className='absolute text-white font-body right-0 ' id='copied_email'></label>
          </div>
          <p className="text-justify font-body text-menuText md:text-xl">
          Some of the topics and concepts we discuss, as well as how to use our specialized calculator effectively, may not be intuitive or easy to understand. In this section, our goal is to address any questions, curiosities, or doubts you may have regarding our research, our results, and the proper use of the calculator.
          </p>
          <p className="text-justify font-body text-menuText md:text-xl">
            In our FAQ post, you will find a compilation of the most common questions from our users, 
            answered in a clear and explanatory manner. If your question is not addressed in this post, 
            we invite you to send us your query via email at <span className='underline hover:cursor-pointer hover:text-red-500' onClick={CopyEmail}>contact@bjtheorem.com</span>, or through our social media channels on <a href='https://instagram.com/bj.theorem?igshid=YmMyMTA2M2Y=' className='hover:text-red-500'>INSTAGRAM</a> and <a href='https://www.facebook.com/profile.php?id=100091901277064' className='hover:text-red-500'>FACEBOOK</a>.
          </p>
          <p className="text-justify font-body text-menuText md:text-xl">
            We will answer as soon as possible and may add it to the FAQ if it proves useful for the community. 
            This section also serves as a platform for exploring topics related to the central concepts or ramifications of our research. We will publish short articles accompanied by images, aiming to illustrate and discuss these topics in a simple, yet informative manner.
          </p>
        </div>
        <div className="hidden md:flex justify-end w-full">
          {(isAuthenticated && getRole().includes('Admin') )?
            <Link to="/blog/newPost" className="bg-red-500 rounded-full px-3 py-1 font-title font-medium text-white text-lg md:text-xl">+ Create new post</Link>: null
          }
        </div>
        <div className="flex items-start justify-start gap-10 w-full">
          <div className="hidden md:flex flex-col sticky top-28 justify-center w-1/6 bg-darkBlue2 rounded-lg p-1 border border-gray-800 gap-1">
            {posts.map((elemento, index) => ( 
              <a key={index} onClick={() => scrollToPost(elemento._id)} className={clicked === elemento._id ? "font-title text-white text-2xl md:text2xl bg-newBlue hover:bg-newBlue rounded-lg p-1 hover:cursor-pointer" : "font-title text-white text-2xl md:text2xl hover:bg-newBlue rounded-lg p-1 hover:cursor-pointer"}> - {elemento.titulo}</a>
            ))}
          </div>
          <div className="top-28 flex flex-col w-full md:w-5/6 gap-2 md:gap-10">
            {posts.map((elemento, index) => (
              <div className="flex flex-col w-full items-center justify-center py-3 md:py-5 px-5 md:px-10 rounded-lg bg-newBlue" id={elemento._id} key={elemento._id} onClick={()=>showPost(elemento._id)}>
                <div className="hidden md:flex justify-between items-center w-full pb-3">
                  <div className="flex gap-1 items-center">
                    {(isAuthenticated && getRole().includes(`Admin`))? <Link to={`/blog/editPost/${elemento._id}`}><MdEdit className="text-3xl text-white hover:cursor-pointer hover:text-gray-500"/></Link>:null}
                    {(isAuthenticated && getRole().includes(`Admin`))? <MdDelete onClick={() => DeletePost(elemento._id)} className="text-3xl text-red-500 hover:cursor-pointer hover:text-red-700"/>:null}
                    <button onClick={() => CopyLink(elemento._id)} className='bg-green-500 rounded-lg px-3 border border-gray-700 text-white font-title font-medium hover:bg-green-700'>Share</button>
                    <label id={`copied_${elemento._id}`} className='text-white text-sm'></label>
                  </div>
                  <label className="font-body text-gray-400">{elemento.fecha}</label>
                </div>
                <div className='flex w-full justify-start gap-3 md:justify-center items-center'>
                  {show === elemento._id ?<RxChevronDown className='md:hidden text-3xl text-white'/>
                  :<RxChevronRight className='md:hidden text-3xl text-white'/>}
                  <h2 className="font-title font-medium text-center text-white text-2xl md:text-3xl">{elemento.titulo}</h2>
                </div>
                <div className={show === elemento._id ?'flex flex-col gap-10 items-center justify-center' :'hidden md:flex flex-col gap-10 items-center justify-center'}>
                  {elemento.contenido.map((item, idx) => (
                    item.type === "text" ? (
                      <p 
                        key={idx} 
                        className="font-body text-gray-400 text-xl text-justify pt-8">{item.content}</p>
                    ) : item.type === "image" ? (
                      <img
                        // item.content es la imagen codificada en base64, 
                        key={idx}
                        // src={item.content}
                        src={`data:image/png;base64,${item.content}`}
                        alt="Post image"
                        className='w-full md:w-[560px]'
                      />
                    ) : item.type === "video" ? (
                      <iframe
                        className='w-[300px] h-[180px] md:w-[560px] md:h-[315px]'
                        src={`https://www.youtube.com/embed/${getYouTubeVideoId(item.content)}`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    ) : null
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default AllPosts;