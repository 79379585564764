import Header from "../Header";
import Footer from "../Footer";
import newWindow from "../../helpers/newWindow";
import CelMenu from "../CelMenu";
import { useEffect, useState } from "react";
import PhoneAlert from "../phoneAlert";
import { useAuth0 } from "@auth0/auth0-react";
import useFetchUser from "../../hooks/fetchUser";

function AboutUs() {
  const [showMenu, setShowMenu] = useState(false);
  const { subscriptionPlan, expirationDate, cachedUser } = useFetchUser();

  return (
    <div className="flex flex-col w-full min-h-screen">
      <Header subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu} />
      <CelMenu showMenu={showMenu} subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} />
      <div className={`flex justify-center px-5 md:px-[20%] w-full ${showMenu ? 'pt-64' : 'pt-20'} md:pt-24 bg-darkBlue pb-20 flex-grow`}>
        <div className="flex flex-col pt-5 w-full">
          <div className="flex justify-center">
            <p className="font-title font-medium text-white text-2xl md:text-4xl">ABOUT US</p>
          </div>
          <p className="text-justify font-body text-menuText md:text-xl mt-10">
            Our team consists of three partners: Alejandro, Javier, and Lucas, each with different skills and a specific academic background, particularly in mathematics and computer science. In addition, for years we have shared a common hobby: 21 Blackjack, and like any lover of this game, we have always been attracted by all the rumors, books, and movies that exist in popular culture about "beating the dealer".
          </p>
          <p className="text-justify font-body text-menuText md:text-xl mt-5">
            Then one day, researching about counting systems and after reading some related articles, we realized that all of them were based on approximate methods, and that in fact none of them managed to "beat the dealer" satisfactorily. So, a little out of curiosity and a little for fun, we wondered if it was possible to do something better, starting first with simple codes.
          </p>
          <p className="text-justify font-body text-menuText md:text-xl mt-5">
            Without realizing it, months went by, and our codes became more complex, always improving our models but still with room for improvement. Until one day, after almost 2 years of hard work and many challenges, we achieved it: WE BEAT THE DEALER!
          </p>
          <p className="text-justify font-body text-menuText md:text-xl mt-5">
            Our strategy is mathematically unbeatable, and we want to share it with you, blackjack lovers. We are sure you will love it!
          </p>
          
          {/* Contenedores para las imágenes de los socios */}
          <div className="flex flex-col md:flex-row justify-around items-center mt-10">
            <div className="flex flex-col items-center mb-10 md:mb-0">
              <div className="w-52 h-52 mb-4 rounded-full overflow-hidden">
                <img src="fotos_socios/tano.png" alt="Alejandro" className="object-contain w-full h-full"/>
              </div>
              <h3 className="text-white text-xl">Alejandro</h3>
            </div>
            <div className="flex flex-col items-center mb-10 md:mb-0">
              <div className="w-52 h-52 mb-4 rounded-full overflow-hidden">
                <img src="fotos_socios/petak.png" alt="Javier" className="object-contain w-full h-full"/>
              </div>
              <h3 className="text-white text-xl">Javier</h3>
            </div>
            <div className="flex flex-col items-center">
              <div className="w-52 h-52 mb-4 rounded-full overflow-hidden">
                <img src="fotos_socios/lukas.png" alt="Lucas" className="object-contain w-full h-full"/>
              </div>
              <h3 className="text-white text-xl">Lucas</h3>
            </div>
          </div>
          {/* Fin de los contenedores para las imágenes de los socios */}
          
        </div>
      </div>
      <Footer />
      <style jsx>{`
        .clip-circle {
          clip-path: circle(50%);
          object-fit: contain;
        }
      `}</style>
    </div>
  )
}

export default AboutUs;
