
function newWindow(){
  const windowFeatures = "width=425,height=531";
  window.open(`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/calculadora`, "calculatorPopUp", windowFeatures);
}

// function newWindow(subscriptionPlan){
//   const windowFeatures = "width=425,height=531";
//   window.open(`${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/calculadora?subscriptionPlan=${subscriptionPlan}`, "calculatorPopUp", windowFeatures);
// }

export default newWindow;