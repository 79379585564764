export const enqueue = (elemento, setCola, setCartasTotal) => {
  setCola(prevCola => {
    if (prevCola.length >= 9) {
      prevCola.shift();
    }
    return [...prevCola, elemento];
  });
  setCartasTotal(prevCola => {
    return [...prevCola, elemento];
  });
};

export const enqueuePlayer = (elemento, setColaPlayer, setCartasPlayerTotal) => {
  setColaPlayer(prevCola => {
    if (prevCola.length >= 3) {
      prevCola.shift();
    }
    return [...prevCola, elemento];
  });
  setCartasPlayerTotal(prevCola => {
    return [...prevCola, elemento];
  });
};

export const enqueueDealer = (elemento, setColaDealer, setCartasDealerTotal) => {
  setColaDealer(prevCola => {
    if (prevCola.length >= 3) {
      prevCola.shift();
    }
    return [...prevCola, elemento];
  });
  setCartasDealerTotal(prevCola => {
    return [...prevCola, elemento];
  });
};