import {HiOutlineCog} from "react-icons/hi";
import HiloAntTable2 from "../HiloAntTable2";
import { useState } from "react";

function HiLoCalculator({modalIsOpen, subscriptionPlan, openModal, decisionMethod, setDecisionMethod, BI, setBI, returnTarget, setreturnTarget, hands}){

    function MoveSlideBarHands(e, id){
        if(id === "hands"){
            setSlideBarHands(e.target.value);
        } else {
            // setHands(e.target.value)
        }
        const label = document.getElementById(id);
        const value = e.target.value;
    }

    const hanldeSliderDrop = (e) => {
        console.log("slider dropped on value: ", e.target.value)
        setDroppedSlideBarHands(e.target.value);
    }


    const [betAsP, setBetAsP] = useState(false); 
    const [heatMap, setHeatMap] = useState(false); 
    const [slideBarHands, setSlideBarHands] = useState(0); // para usar en el display de la barra Hi-Lo
    const [droppedSlideBarHands, setDroppedSlideBarHands] = useState(0); // para usar en la consulta de la tabla de Hi-Lo

    return (
        <>
        <div className='flex justify-between'> 
            <div className="flex justify-start gap-2 pl-4 border border-gray-600 text-gray-900 rounded-full 
            w-[95%] h-full bg-gray-300 dark:bg-darkBlue2">
                
            </div>    
            <div className="md:hidden flex justify-end w-[10%]">
                <button onClick={() => {openModal()}}>
                    <HiOutlineCog className="text-4xl text-black dark:text-4xl dark:text-white"/>
                </button>
            </div>
        </div>

        <div className="flex flex-col gap-2 border border-gray-600 p-1.5 rounded-md shadow-xl bg-white dark:bg-newBlue">
            <div className="flex flex-col w-full gap-2 dark:bg-newBlue" id="settings">
                <div>
                <span className="font-bold text-black dark:text-gray-400">Number of Rounds H:</span> <span className="font-bold text-red-500 dark:text-white">{hands}</span>
                </div>
                <div>
                <span className="font-bold text-black dark:text-gray-400">Target Return R:</span> <span className="font-bold text-red-500 dark:text-white">{returnTarget}</span>
                </div>
                <div>
                <span className="font-bold text-black dark:text-gray-400">Count Method:</span> <span className="font-bold text-red-500 dark:text-white">Hi-Lo True Count</span>
                </div>
                <div>
                <span className="font-bold text-black dark:text-gray-400">Decision Method:</span> <span className="font-bold text-red-500 dark:text-white">{decisionMethod === 'bs'? 'Basic Strategy (BS)': 'Perfect Decision (PD)'}</span>
                </div>
                <div>
                <span className="font-bold text-black dark:text-gray-400">Initial Bankroll:</span> <span className="font-bold text-red-500 dark:text-white">{'$' + BI}</span>
                </div>
            </div>

            <div className="flex flex-col w-2/3 justify-evenly" id="mapSettings"> 
                <div className="flex flex-col w-1/2" id="toggles">
                    <div className=" flex px-2 py-1 items-center justify-between">
                        <p className="font-body text-black dark:text-gray-400"> Bet as Percentage: </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <input type="checkbox" checked={betAsP} className="sr-only peer" id="betAsP" onChange={(c)=>setBetAsP(c.target.checked)}/>
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                        </label>
                    </div>

                    <div className="flex px-2 py-1 items-center justify-between">
                        <p className="font-body text-black dark:text-gray-400"> Heatmap: </p>
                        <label className="inline-flex relative items-center cursor-pointer">
                        <input type="checkbox" checked={heatMap} className="sr-only peer" id="heatMap" onChange={(c)=>setHeatMap(c.target.checked)}/>
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                        </label>
                    </div>
                </div>
            </div>

            <div className="flex flex-row">
                <div className="flex flex-col w-full pr-4" id="SlideBar">
                <div>
                    <span className="font-bold text-black dark:text-white">ROUNDS PLAYED</span> 
                </div>


                <div className="flex flex-col w-full" id="bar">
                    {/* <div className="flex w-full relative items-end">
                    <label className="absolute font-body text-menuText left-1/2" id="hands">N={slideBarHands}</label>
                    </div> */}
                    <input type="range" id="n_manos" min="0" max={hands} step="1" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-red-500" value={slideBarHands} onChange={(e)=>MoveSlideBarHands(e, "hands")} onMouseUp={hanldeSliderDrop} onTouchEnd={hanldeSliderDrop}/>
                    <div className="flex w-full justify-between">
                    <label className="text-justify font-body text-menuText">0</label>
                    <label className="font-body text-red-500">H = {hands}</label>
                    <label id="droppedSlideBarHands" className="hidden">{droppedSlideBarHands}</label>
                    </div>
                    <label className="font-body text-menuText text-center" id="hands">N={slideBarHands}</label>
                </div>
                </div>

                {/* <div>
                <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-2 rounded" onClick={handleMapUpdate}>
                    <img src={refreshIcon} alt="Refresh Icon" className="h-6 w-6" />
                </button>
                </div> */}

            </div>       
            {modalIsOpen? null:(
            <div className="flex items-center justify-center">
                <HiloAntTable2 subscriptionPlan={subscriptionPlan} heatMap={heatMap} betAsP={betAsP} initial_bankroll={BI} ror={returnTarget} droppedSlideBarHands={droppedSlideBarHands} hands={hands} decisionMethod={decisionMethod} />
            </div>)}
        </div>
        </>
    );
}

export default HiLoCalculator;
