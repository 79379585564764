import { useEffect, useState } from 'react';
import newWindow from "../../helpers/newWindow";
import CelMenu from "../CelMenu";
import Footer from "../Footer";
import Header from "../Header";
import { TiDelete } from 'react-icons/ti';
import { redirect, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";



function NewPost() {
  const [showMenu, setShowMenu] = useState(false);
  const [title, setTitle] = useState();
  const [postElements, setPostElements] = useState([]);
  const navigate = useNavigate();


  const {
    loginWithRedirect,
    isAuthenticated,
    user
    } = useAuth0();
  
  const getRole = () => {
    const userRoles = user['https://parametros_auth0.com/roles'].map((role) => role);
    return userRoles
  };  


  const addText = () => {
    setPostElements([...postElements, { type: 'text', content: '' }]);
  };

  const addImage = () => {
    setPostElements([...postElements, { type: 'image', content: '' }]);
  };

  const addVideo = () => {
    setPostElements([...postElements, { type: 'video', content: '' }]);
  };

  const handleImageUpload = (e, index) => {
    const file = e.target.files[0];
    const img = new Image();
    const reader = new FileReader();
  
    reader.onload = (e) => {
      img.src = e.target.result;
    };
  
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
  
      // Compress the image by reducing its quality to 0.6
      const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.6);
      


      console.log("Data URL: ", compressedDataUrl); // Debugging line

      // Update the element content with the compressed image
      updateElementContent(index, compressedDataUrl);
    };
  
    reader.onerror = (error) => {
      console.error("File Reading Error: ", error);
    };
  
    reader.readAsDataURL(file);
  };
  


  const updateElementContent = (index, newContent) => {
    const updatedElements = [...postElements];
    updatedElements[index].content = newContent;
    setPostElements(updatedElements);
  };

  const deleteElement = (index) => {
    const updatedElements = [...postElements];
    updatedElements.splice(index, 1);
    setPostElements(updatedElements);
  };

  const savePost = () => {
    try{
      const day = new Date();
      const newPost = {
        "titulo": title,
        "fecha": `${day.getDate()}/${day.getMonth() + 1}/${day.getFullYear()}`,
        "contenido": postElements
      };

      fetch(`${process.env.REACT_APP_BACKEND_URL}/subir_publicacion`,{
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify(newPost)
      }).then((data) => data.json()).then((data) => navigate('/blog'))
    } catch {
      alert("An error occurred")
    }
    
  };

  if (isAuthenticated && getRole().includes('Admin')) {

    return (
      <div className="bg-darkBlue h-fit">
        <Header newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu}/>
        <CelMenu showMenu={showMenu}/>
        <div className={showMenu ? "flex flex-col py-32 items-center justify-center gap-10 py-52 md:py-28 md:px-36 px-5" : "flex flex-col pt-24 items-center justify-start gap-10 py-20 md:py-28 md:px-16 px-5 min-h-screen"}>
          <h1 className="font-title font-medium text-center text-white text-2xl md:text-4xl">New Post</h1> 
          <div className='flex flex-col w-3/4 items-start gap-5 pl-40'>
            
            <div className='flex flex-col w-5/6'>
              <label className='font-title text-white text-2xl '>Title:</label>
              <input type="text" onChange={(e) => setTitle(e.target.value)} className='w-full h-10 rounded-lg bg-gray-400 pl-2 font-title'/>
            </div>
            <div className='flex flex-col w-full gap-2'>
            {postElements.map((element, index) => (
              <div key={index} className='flex w-full gap-2 justify-end items-center'>
                {element.type === 'text' ? (
                  <textarea
                    value={element.content}
                    onChange={(e) => updateElementContent(index, e.target.value)}
                    className='w-full h-40 rounded-lg bg-gray-400 pl-2 font-title p-1'
                  />
                ) : element.type === 'image' ? (
                  <input
                    type="file"
                    accept="image/*"
                    // onChange={(e) => {
                    //   // Aquí puedes manejar la carga de imágenes y almacenar la URL en el estado
                    //   const imageURL = URL.createObjectURL(e.target.files[0]);
                    //   updateElementContent(index, imageURL);
                    // }}
                    onChange={(e) => handleImageUpload(e, index)}
                    className="block w-1/2 text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-400 focus:outline-none"
                  />
                )  : element.type === 'video' ? (
                  <input
                    type="text"
                    placeholder='Enter Youtube video link'
                    onChange={(e) => updateElementContent(index, e.target.value)}
                    className="block w-1/2 text-lg placeholder-gray-600 pl-1 border border-gray-300 rounded-lg cursor-pointer bg-gray-400 focus:outline-none"
                  />
                
                ) : null}
                <button  onClick={() => deleteElement(index)}><TiDelete className='text-4xl text-red-500 hover:text-red-800 text-center'/></button>
              </div>
            ))}
            </div>
            <div className='flex place-self-center gap-1'>
              <button className='text-white font-title rounded-full bg-newBlue px-3 py-1 text-center hover:bg-darkBlue2' onClick={addText}>Add Text</button>
              <button className='text-white font-title rounded-full bg-newBlue px-3 py-1 text-center hover:bg-darkBlue2' onClick={addImage}>Add Image</button>
              <button className='text-white font-title rounded-full bg-newBlue px-3 py-1 text-center hover:bg-darkBlue2' onClick={addVideo}>Add Video</button>
            </div>
            <div className='flex place-self-end gap-1'>
              <button onClick={savePost} className='text-white font-title rounded-full bg-green-700 hover:bg-green-500 px-3 py-1 text-center hover:bg-darkBlue2'>Upload Post</button>
            </div>  
          </div>
          
        </div>
        <Footer/>
      </div>
    ); 
  } else {
    return(<h1>Not admin compa</h1>)
  }

}

export default NewPost;