import getData from "./getData";
import changeProb from "./changeProb"

const getBestBet = ( setInitBankroll ) => {
	
    const pa = parseInt(document.getElementById('money').value.split('.').join(''))
	const calculatorMode = document.getElementById('mode').innerHTML
    const p0 = parseInt(document.getElementById('p0').innerHTML)
	
	const datos = getData(calculatorMode);
	
	// si hay bankroll actual, se hace la consulta
	if( pa ){
		if(p0 == 0) {
			setInitBankroll(pa)
			datos["p0"] = pa
		} else {
			datos["p0"] = p0
		}
		if(calculatorMode === "fcc"){
			datos["comando"] = "apuesta"
			fetch(`${process.env.REACT_APP_BACKEND_URL}/apuesta`,{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(datos)
			}).then(res => res.json()).then(res => changeProb(res));

		} else {
			
			datos["comando"] = "hilo_apuesta"
			fetch(`${process.env.REACT_APP_BACKEND_URL}/apuesta`,{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify(datos)
			}).then(res => res.json()).then(res => changeProb(res));
		}
	} else {
		document.getElementById("tooltip_bankroll").classList.toggle("invisible");	
	}
	
}

export default getBestBet;