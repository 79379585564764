import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import checkExpirationDate from "../helpers/checkExpirationDate";

const clientId = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_ID;
const client_secret = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_SECRET;
const domain = process.env.REACT_APP_MANAGEMENT_BJ_DOMAIN;

const useFetchUser = () => {

  const [subscriptionPlan, setSubscriptionPlan] = useState(-1); // options: -1 (no plan) / 0 (Perfect) / 1 (HiLo) / 2 (Full)
  const [expirationDate, setExpirationDate] = useState(null);
  const [cachedUser, setCachedUser] = useState(null);

  const { user } = useAuth0();

  useEffect(() => {
    if (user !== undefined && user !== null) {
      setCachedUser(user);
    }
  }, [user]);

  // Comentar este bloque para la integración de paypal: -----------------------------------------------------
  useEffect(() => {
    if (cachedUser) {
      setSubscriptionPlan(2); 
    }
  }, [cachedUser]);
  // --------------------------------------------------------------------------------------------------------------


  // DEScomentar este bloque para la integración de paypal: -----------------------------------------------------

  // useEffect(() => {
  //   const fetchData = (user) => {
  //     const managementApiOptions = {
  //       method: 'POST',
  //       headers: { 'content-type': 'application/x-www-form-urlencoded' },
  //       body: new URLSearchParams({
  //         grant_type: 'client_credentials',
  //         client_id: clientId,
  //         client_secret: client_secret,
  //         audience: `https://${domain}/api/v2/`
  //       })
  //     };

  //     fetch(`https://${domain}/oauth/token`, managementApiOptions)
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error('Network response was not ok');
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         const managementApiToken = data.access_token;

  //         if (user) {
  //           console.log("Querying current user metadata");
  //           const queryMetadataOptions = {
  //             method: 'GET',
  //             headers: {
  //               Authorization: `Bearer ${managementApiToken}`,
  //               'Content-Type': 'application/json',
  //             },
  //           };

  //           fetch(`https://${domain}/api/v2/users/${user.sub}`, queryMetadataOptions)
  //             .then(response => {
  //               if (!response.ok) {
  //                 throw new Error('Network response was not ok');
  //               }
  //               return response.json();
  //             })
  //             .then(userData => {
  //               console.log('User meta_data:', userData.user_metadata);
  //               const planNumber = checkExpirationDate(userData.user_metadata);
  //               console.log("planNumber: ", planNumber);
  //               setSubscriptionPlan(planNumber);
  //               setExpirationDate(userData.user_metadata.expiration_date);
  //             })
  //             .catch(error => {
  //               console.error('There was a problem with the API call:', error);
  //             });
  //         } else {
  //           console.log("No user found");
  //         }
  //       })
  //       .catch(error => {
  //         console.error('There was a problem with the API call:', error);
  //       });
  //   };

  //   if (cachedUser) {
  //     fetchData(cachedUser);
  //   }
  // }, [cachedUser]);

  // --------------------------------------------------------------------------------------------------------------

  return { subscriptionPlan, expirationDate, cachedUser };
};

export default useFetchUser;