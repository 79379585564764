import getData from "./getData";

const getBestBetHiLo = (initial_bankroll, setDataHiLo) => {
    const extractDataHiLo = (res) => {
        try {
            const matriz = res.matriz;
            const matrizPorcentaje = res.matriz_porcentaje;
            if (matriz && matrizPorcentaje) {
                setDataHiLo({ matriz, matrizPorcentaje });
            } else {
                console.error('Las claves "matriz" o "matriz_porcentaje" no se encontraron en la respuesta:', res);
            }
        } catch (error) {
            console.error('Error al procesar la respuesta del servidor:', error);
        }
    };

    const datos = getData('ic');

    // si hay bankroll actual, se hace la consulta
    datos["p0"] = initial_bankroll;
    datos["comando"] = "hilo_apuesta";
    fetch(`${process.env.REACT_APP_BACKEND_URL}/apuesta`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(datos)
    })
    .then(res => res.json())
    .then(res => extractDataHiLo(res))
    .catch(error => console.error('Error en la solicitud fetch:', error));
};

export default getBestBetHiLo;