import { SiConvertio } from "react-icons/si";
import { useEffect, useState } from "react";
import { BsDot } from 'react-icons/bs';


export default function FirstGraph({ tipoGraficoDensidad, setTipoGraficoDensidad, ChangeResource, numMano, 
  changeInput, showGraphs, BI , f_percentiles, manos}) {

  return (
    <div className="flex bg-darkBlue2">
      {showGraphs ? (
        <div className="flex justify-center pt-8 w-full h-full gap-10">
          <div className="flex flex-col justify-around bg-darkBlue3 p-2 rounded-xl border border-gray-600 w-full">
            <div className="flex justify-between gap-2">
              <div className="flex justify-center space-x-0.5 rounded-xl bg-gray-200 p-1 w-3/5 h-full gap-2">
                <div className="flex w-1/2 h-full">
                  <input type="radio" id="acumulada" name="dist" className="peer hidden" onChange={() => { setTipoGraficoDensidad(0) }} checked={!tipoGraficoDensidad} />
                  <label htmlFor='acumulada' className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-red-500 peer-checked:font-bold peer-checked:text-white font-title font-medium">Cumulative</label>
                </div>
                <div id="showDensidad" className="flex w-1/2 h-full">
                  <input type="radio" id="densidad" name="dist" className="peer hidden" onChange={() => { setTipoGraficoDensidad(1) }} checked={tipoGraficoDensidad} />
                  <label htmlFor='densidad' className="block cursor-pointer select-none rounded-xl p-2 text-center peer-checked:bg-red-500 peer-checked:font-bold peer-checked:text-white font-title font-medium">Density</label>
                </div>
              </div>
              <div>
                <button onTouchStart={() => { ChangeResource() }} className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm py-1.5 px-3 hover:cursor-pointer bg-gradient-to-br from-green-600 to-green-600 text-gray-700">
                  <div className="flex flex-row gap-3">
                    <SiConvertio className="flex text-4xl"></SiConvertio>
                    <p className="font-body text-justify "> Table Format </p>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex w-full h-full p-5">
              <canvas height="150" width="200" id="histograms"></canvas>
            </div>
            {/*<div className="flex justify-center items-center gap-7">
              <label className="font-title font-medium text-menuText"> Number of hands: </label>
              <input type="range" id="n_manos" min="100" max="10000" step="20" className="w-1/2 h-2 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-red-500" onChange={(e) => changeInput(e)} />
              <label id="n_manos_v" className="font-title font-medium text-menuText"> {numMano} </label>
            </div>*/}
          </div>
        </div>
      ) : (
        <div className="flex flex-col w-full h-full justify-center items-center px-2">

        <button onTouchStart={() => { ChangeResource() }} className="flex shadow-xl justify-center items-center 
        border border-gray-600 rounded-full font-body font-bold text-sm py-1.5 px-3 my-2 hover:cursor-pointer 
        bg-gradient-to-br from-green-600 to-green-600 text-gray-700 w-[60%]">
          <div className="flex flex-row gap-3">
            <SiConvertio className="flex text-4xl"></SiConvertio>
            <p className="font-body text-justify h-full"> Graph Format </p>
          </div>
        </button>

        <div className="flex flex-col justify-center items-center border border-gray-700 rounded-lg w-full h-[80%] pt-2">
						<div className="flex w-full justify-between items-center">
              <div className="flex h-full w-[20%] border-r border-gray-700 p-1">
                <label className="flex items-center justify-center text-center font-body text-menuText">Factor</label>
              </div>
              <div className="flex flex-col w-[20%] h-full">
                <label className="flex items-center justify-center text-center font-body text-menuText p-1">B =</label>
                <label className="flex items-center justify-center text-center font-body text-menuText">Factor <BsDot/> IB</label>
              </div>

              <div className="flex h-full w-[30%] border-l border-gray-700"> 
                <label className="flex items-center justify-center text-center font-body text-menuText  p-1">Probability of ending up with a bankroll equal or LESS than "B"</label>
              </div>
              <div className="flex h-full w-[30%] border-l border-gray-700">
                <label className="flex items-center justify-center text-center font-body text-menuText  p-1">Probability of ending up with a bankroll equal or GREATER than "B"</label>
              </div>
							
						</div>
						{(() => {
								const options = [0, 0.2, 0.4, 0.6, 0.8, 1, 1.2, 1.4, 1.6, 1.8, 2, 2.25, 2.5, 2.75, 3, 3.5, 4, 4.5, 5];
								const cells = []
								
								for (var i = 0; i < options.length; i++) {
									
									cells.push(
										<div className="flex w-full justify-between items-center text-center">
											<label className="font-body text-menuText w-[20%] border-t border-r border-gray-700">{options[i]}</label>
											<label className="font-body text-menuText w-[20%] border-t border-r border-gray-700">{(BI !== "0" && BI !== "" && BI !== 0)?(options[i]*parseInt(BI).toFixed(0)): "0"}</label>
											<label className="font-body text-menuText w-[30%] border-t border-r border-gray-700">{f_percentiles[manos/10] !== undefined ? `${(f_percentiles[manos/10][i][0]*100).toFixed(1)}%` : "0%"}</label>
											<label className="font-body text-menuText w-[30%] border-t border-gray-700">{f_percentiles[manos/10] !== undefined ? `${(f_percentiles[manos/10][i][1]*100).toFixed(1)}%` : "0%*100"}</label>
										</div>
									)
								}
								
								return cells;
							})()}	
					</div>
        </div>
      )}
    </div>
  );
}

