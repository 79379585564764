import React from 'react';
import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import './custom-table.css';
import getBestBetHiLo from "../helpers/getBestBetHiLo";

function HiloAntTable2({ subscriptionPlan, heatMap, betAsP, initial_bankroll, ror, droppedSlideBarHands, hands, decisionMethod }) {

    // Function to calculate the color based on the cell value and min/max values
    const calculateColor = (value, minValue, maxValue) => {
        const normalizedValue = (value - minValue) / (maxValue - minValue);
        const hue = (normalizedValue * 120).toString(10); // 0 - 120 degrees for color gradient
        return `hsl(${hue}, 100%, 50%)`; // Using HSL color space for smooth gradient
    };

    // formating function
    function formatNumber(value) {
        if (value >= 1000000) {
            return (value / 1000000).toFixed(2).replace(/\.0$/, '').replace(/\.00$/, '') + 'M';
        } else if (value >= 1000) {
            return (value / 1000).toFixed(2).replace(/\.0$/, '').replace(/\.00$/, '') + 'k';
        } else {
            return value.toFixed(1).replace(/\.0$/, '');
        }
    }

    function parseFormattedNumber(value) {
        const numStr = value.toString();
        const lastChar = numStr.charAt(numStr.length - 1);
        if (lastChar === 'M') {
            return parseFloat(numStr.slice(0, -1)) * 1000000;
        } else if (lastChar === 'k') {
            return parseFloat(numStr.slice(0, -1)) * 1000;
        } else {
            return parseFloat(numStr);
        }
    }

    const [columns, setColumns] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const [matrixData, setMatrixData] = useState({ matriz: [], matrizPorcentaje: [] });
    const [globalMinValue, setGlobalMinValue] = useState(0);
    const [globalMaxValue, setGlobalMaxValue] = useState(0);

    useEffect(() => {
        getBestBetHiLo(initial_bankroll, setMatrixData);
    }, [droppedSlideBarHands, initial_bankroll, ror]);

    useEffect(() => {
        if (!matrixData.matriz || matrixData.matriz.length === 0) {
            return; // Salir temprano si matrixData no está inicializado
        }

        const dataToUse = betAsP ? matrixData.matrizPorcentaje : matrixData.matriz;

        // Calcular valores mínimos y máximos de la matrizPorcentaje (para colores)
        let min = matrixData.matrizPorcentaje[0][0];
        let max = matrixData.matrizPorcentaje[0][0];
        for (let row of matrixData.matrizPorcentaje) {
            for (let value of row) {
                if (value < min) min = value;
                if (value > max) max = value;
            }
        }

        setGlobalMinValue(min);
        setGlobalMaxValue(max);

        setMinValue(min);
        setMaxValue(max);
        console.log("minValue: ", min, " maxValue: ", max);

        // Generate columns and dataSource based on matrix data
        const newColumns = [];
        const numberOfColumns = dataToUse[0].length;
        const bankrollIncrements = initial_bankroll * 0.1;
        const newDataSource = [];
        const numberOfRows = dataToUse.length;
        for (let index = 0; index < numberOfColumns + 1; index++) {
            newColumns.push({
                key: `col_${index}`,
                title: index === 0 ? '' : `$${formatNumber((index - 1) * bankrollIncrements)}`,
                dataIndex: `col_${index}`,
                fixed: index === 0 ? 'left' : index === numberOfColumns ? 'right' : false,
                render(text, record, rowIndex, columnIndex) {

                    var cellValue = parseFloat(text);
                    if (!isNaN(cellValue)) {
                        cellValue = parseFormattedNumber(text);
                        const valueForColor = matrixData.matrizPorcentaje[rowIndex][index - 1];
                        return {
                            props: {
                                style: {
                                    backgroundColor: heatMap ? calculateColor(valueForColor, minValue, maxValue) : 'white',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                },
                            },
                            children: <div style={{ width: 50 }}> {betAsP ? `${text}%` : text} </div>
                        }
                    } else {
                        return {
                            props: {
                                style: {
                                    backgroundColor: 'gray',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: 'white',
                                    fontWeight: 'bold'
                                },
                            },
                            children: <div style={{ width: 50, textAlign: 'center' }}> {parseFloat(text.match(/\d+(\.\d+)?/)).toFixed(1)} </div>
                        }
                    }
                },
            });
        }

        for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
            const rowData = {};
            for (let colIndex = 0; colIndex < numberOfColumns + 1; colIndex++) {
                if (colIndex === 0) {
                    rowData[`col_${colIndex}`] = `HiLo Label - ${((rowIndex) * 0.2).toFixed(1)}`;
                } else {
                    // Create a copy of the value from dataToUse[rowIndex][colIndex - 1]
                    const valueToFormat = dataToUse[rowIndex][colIndex - 1];

                    // Apply the formatNumber function to the copied value
                    const formattedValue = formatNumber(parseFloat(valueToFormat));

                    // Assign the formatted value to rowData
                    rowData[`col_${colIndex}`] = betAsP ? `${formattedValue}` : formattedValue;
                }
            }
            newDataSource.push({
                key: `row_${rowIndex}`,
                ...rowData,
            });
        }

        setColumns(newColumns);
        setDataSource(newDataSource);

    }, [initial_bankroll, betAsP, heatMap, matrixData]);

    return (
        <>
            {subscriptionPlan == 1 || subscriptionPlan == 2 ? null : (
                <p className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/3 -rotate-12 text-red-500 font-title text-8xl font-bold border-dashed border-8 border-red-500 p-5 rounded-lg"> Subscribe</p>
            )}
            <div className={subscriptionPlan == 1 || subscriptionPlan == 2 ? 'flex flex-row w-[90%] max-w-[1000px]' : 'flex flex-row w-[90%] blur-md'}>
                <div className='flex flex-col w-[4%]'>
                    <div className='h-[6%]'></div>
                    <div className='flex items-center justify-center h-[94%] w-full bg-red-500 rounded-md'>
                        <span className='text-white -rotate-90 whitespace-nowrap'> Hi-Lo Count </span>
                    </div>
                </div>
                <div className='flex flex-col w-[96%]'>
                    <div className='flex items-center justify-center bg-red-500 rounded-md'>
                        <p className='text-white'> Current Bankroll</p>
                    </div>

                    <Table
                        className="custom-table"
                        columns={columns}
                        dataSource={dataSource}
                        style={{ width: '100%' }}
                        scroll={{ x: true, y: isMobile ? 290 : 330 }}
                        tableLayout="fixed" // Set tableLayout to "fixed" for custom styles
                        bordered={true} // Remove borders to reduce padding further
                        size="large" // Optionally set the size to "small" for compact cells
                        rowClassName={() => "custom-table"}
                        pagination={false}
                    />
                </div>
            </div>
        </>
    );
}

export default HiloAntTable2;
