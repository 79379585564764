  // Eliminar Carta
  const deleteCard = (setDisabledButton, cola, setCola, setColaPlayer, setColaDealer, cartasPlayerTotal, 
    setCartasPlayerTotal, setCartasDealerTotal, setCartasTotal, cartasDealerTotal, cartasTotal) => {
    setDisabledButton(true)
    if (cola[cola.length-1] !== undefined){
      if (cola[cola.length-1][1] === 'Green'){
        
        setColaPlayer(prevCola => {
          if (prevCola.length >= 0) {
            const newCola = prevCola.slice(0, prevCola.length - 1);
            if (prevCola.length >= 3){
              const NewNum =  cartasPlayerTotal[cartasPlayerTotal.length - 4]
              const NewCola2 = [NewNum, ...newCola]
              return NewCola2;
            } 
            return newCola;
         } 
          return prevCola;
        });
        setCartasPlayerTotal(prevCola => {
          if (prevCola.length >= 0) {
            const newCola = prevCola.slice(0, prevCola.length - 1);
            return newCola;
          } 
         return prevCola;
       });

     } else  if (cola[cola.length-1][1] === 'Red'){
       setColaDealer(prevCola => {
         if (prevCola.length >= 0) {
           const newCola = prevCola.slice(0, prevCola.length - 1);
           if (prevCola.length >= 3){
             const NewNum =  cartasDealerTotal[cartasDealerTotal.length - 4]
             const NewCola2 = [NewNum, ...newCola]
             return NewCola2;
           } 
           return newCola;
         } 
         return prevCola;
       });
       setCartasDealerTotal(prevCola => {
         if (prevCola.length >= 0) {
           const newCola = prevCola.slice(0, prevCola.length - 1);
           return newCola;
         } 
         return prevCola;
       });
     }
     setCola(prevCola => {
       if (prevCola.length >= 0) {
         const newCola = prevCola.slice(0, prevCola.length - 1);
         if (prevCola.length >= 9){
           const NewNum =  cartasTotal[cartasTotal.length - 10]
           const NewCola2 = [NewNum, ...newCola]
           return NewCola2;
         } 
         return newCola;
       } 
       return prevCola;
     });
     setCartasTotal(prevCola => {
       if (prevCola.length >= 0) {
         const newCola = prevCola.slice(0, prevCola.length - 1);
         return newCola;
       } 
       return prevCola;
     });
   }  
   setDisabledButton(false)
}

export default deleteCard;