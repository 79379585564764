import clearHands from "../helpers/clearHands";
import editBankRoll from "../helpers/editBankRoll";
import getBestBet from "../helpers/getBestBet";
import getBestDecision from "../helpers/getBestDecision";
import toPeso from "../helpers/toPeso";
import { IoClose } from 'react-icons/io5';
import { focusInput } from "./WithKeyboard";
import resetBet from '../helpers/resetBet';
import { useState } from 'react';


function NoKeyboard(subscriptionPlan, subscribed, setSubscribed, percentages, darkMode, calculatorMode, isAuthenticated, hands, ror, desv_cond, initial_bankroll, setInitBankroll, roundsPlayed, setRoundsPlayed, currentBankroll, setCurrentBankroll){

  function Tooltip(objective){
    const player_visible = !document.getElementById("tooltip_player").classList.contains("invisible");
    const dealer_visible = !document.getElementById("tooltip_dealer").classList.contains("invisible");
    if(objective === "player"){
      if(dealer_visible){
        document.getElementById("tooltip_dealer").classList.toggle("invisible");
      }
      document.getElementById("tooltip_player").classList.toggle("invisible");
    } else if(objective === "dealer") {
      if(player_visible){
        document.getElementById("tooltip_player").classList.toggle("invisible");
      }
      document.getElementById("tooltip_dealer").classList.toggle("invisible");
    } else {
      document.getElementById("tooltip_bankroll").classList.toggle("invisible");
    }
    
  }

  function NotLoggedBet() {
    const bankroll = document.getElementById("money").value
    const stringWithoutDots = bankroll.replace(/\./g, '');
    const parsedInt = parseInt(stringWithoutDots, 10);
    if(parsedInt > 0){
      const min = -0.1 * parsedInt;
      const max = 0.6 * parsedInt;
      const randomValue = Math.random() * (max - min) + min;
      document.getElementById('amount').innerHTML = "$" + randomValue.toFixed(0)
      document.getElementById('amount').value = randomValue.toFixed(0)

      //Se guarda el estimated_value
      document.getElementById('estimated_v').innerHTML = "EV: Demo" 

      //Se guarda la desviacion estandar 
      document.getElementById('std').innerHTML = "STD: Demo" 
    } else {
      document.getElementById("tooltip_bankroll").classList.toggle("invisible");
    }
  }

  const handleRoundsChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 0) {
      setRoundsPlayed(newValue);
    }
    if (e.target.value === ''){
      setRoundsPlayed(0)
    }
  };

  const editRounds = (operator) => {  
    if (operator === '+'){
      setRoundsPlayed((prevRounds) => prevRounds + 1);
    }
    if (operator === '-'){
      setRoundsPlayed((prevRounds) => Math.max(prevRounds - 1, 0));
    }
  };

  return (
    <div className={darkMode ? "flex flex-col gap-2 h-full dark" :"flex flex-col gap-2 h-full"}>

      
      <div className="flex justify-between gap-2">
        {/* --------------------------------------------------- Columna izquierda ----------------------------------------------*/}
        <div id="col1" className="flex flex-col justify-between gap-2 w-1/2">

          <div className="flex flex-col justify-between gap-2 w-full h-[124px]" id="bestBet" >
            <div className=" flex flex-col border border-black pt-2 pb-1 h-full  rounded-md shadow-xl gap-2 bg-white dark:bg-newBlue">
              <div className="flex items-center justify-between px-4">
                <div className='flex flex-col items-center justify-start h-full w-1/2'>
                  {isAuthenticated ?
                      <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>{getBestBet(setInitBankroll); focusInput()}}> Best Bet! </button>
                    : <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>{NotLoggedBet()}}> Best Bet! </button>
                  }
                  <label className='font-body text-sm text-black dark:text-gray-400'>Strategy</label>
                  <label className='font-body text-[14px] text-red-500 text-center'> 
                  H={hands}, R={ror*100}%
                  </label>
                </div>
                {subscriptionPlan==0||subscriptionPlan==2? (
                  <div className="flex flex-col items-center justify-start h-full w-1/2">
                    <label id="amount" className="font-body text-lg text-black dark:text-gray-400"></label>
                    <label id='estimated_v' className="font-body text-sm text-black dark:text-gray-400"></label>
                    <label id='std' className="font-body text-sm text-black dark:text-gray-400"></label>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full w-1/2">
                    <span> &#128274;</span>
                  </div>
                )} 
              </div>
            </div>
          </div>

          <div className={calculatorMode === "ci" ? "flex flex-col gap-5 border border-gray-600 p-2 rounded-md bg-white dark:bg-newBlue pointer-events-none blur h-[174.5px]" : "flex flex-col gap-5 border border-gray-600 p-2 rounded-md bg-white dark:bg-newBlue h-[174.5px]"} id="decisions" >
            <div className="flex justify-center gap-2">
              <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={() => getBestDecision()}> Best Decision </button>
              <p className='font-body text-lg text-black dark:text-gray-400' id='scores'></p>
            </div>             
            <div className="flex flex-col justify-between h-2/3">

              <div className="flex flex-row h-1/3 gap-2 justify-between">
                <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a1">
                  <label id="t1" className="font-body text-sm text-black dark:text-gray-400">Hit</label> 
                  {percentages ? <label className="font-body text-sm text-white " id="e1">0</label>:<></>}
                </div>

                <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a2">   
                  <label id="t2" className="font-body text-sm text-black dark:text-gray-400">Stand</label> 
                  {percentages ? <label className="font-body text-sm text-white " id="e2">0</label>:<></>}
                </div>
              </div>

              <div className="flex felx-row h-1/3 gap-2 justify-between">
                <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a3">   
                  <label id="t3" className="font-body text-sm text-black dark:text-gray-400">Split</label> 
                  {percentages ? <label className="font-body text-sm text-white " id="e3">0</label>:<></>}
                </div>

                <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a4">  
                  <label id="t4" className="font-body text-sm text-black dark:text-gray-400">Double</label> 
                  {percentages ? <label className="font-body text-sm text-white " id="e4">0</label>:<></>}
                </div>
              </div> 
            </div>    
          </div>


          {/* <div className={calculatorMode === "ci" ? "flex flex-col justify-around border border-black p-2 rounded-md h-full bg-white dark:bg-newBlue pointer-events-none blur" : "flex flex-col justify-around border border-black p-2 rounded-md h-full bg-white dark:bg-newBlue"} id="decisions">
            <div className="flex justify-center gap-2">
            </div>             
            <div className="flex flex-col ">
            </div>    
          </div> */}


        </div>

        {/* --------------------------------------------------- Columna derecha----------------------------------------------*/}
        <div id="col2" className="flex flex-col justify-between gap-2  w-1/2">

          <div className={calculatorMode === "ci" ? "flex flex-col gap-2 border border-black p-2 rounded-md bg-white dark:bg-newBlue  pointer-events-none blur" : "flex flex-col gap-2 border border-black p-2 rounded-md bg-white dark:bg-newBlue"} id="hands">
            <div className="flex justify-center">
              <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick= {()=>clearHands()}> Clear Hands </button>
            </div>
            <div className="flex items-center justify-around">
              <label className="font-body text-sm text-black dark:text-gray-400"> Player Hand </label>
              <div id='tooltip_player' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[200px] rounded px-2 pb-1 absolute transform -translate-x-[45%] -translate-y-[50%]">
                <IoClose onClick={()=>Tooltip("player")}  className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>To enter the player's hand, hold down the “Q" key and click his cards or type them on the keyboard.</p>
              </div>
              <div id='validation_player' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[170px] rounded px-2 py-1 absolute transform -translate-x-[45%] translate-y-[70%]">
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>Player cannot exceed 21.</p>
              </div>
              <div id="cartas_jugador" onClick={()=>Tooltip("player")} className="flex justify-start items-center gap-1 px-2 py-0.5 border border-green-600 text-gray-900 rounded-full w-2/5 h-[28px] bg-gray-300 dark:bg-darkBlue2 hover:cursor-pointer"></div>
            </div>
            <div className="flex items-center justify-around">
              <label className="font-body text-sm text-black dark:text-gray-400"> Dealer Card </label>
              <div id='tooltip_dealer' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[200px] rounded px-2 pb-1 absolute transform -translate-x-[45%] -translate-y-[50%]">
                <IoClose onClick={()=>Tooltip("dealer")}  className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>To enter the player's hand, hold down the “Q" key and click his cards or type them on the keyboard.</p>
              </div>
              <div id='validation_dealer' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[200px] rounded px-2 pb-1 absolute transform -translate-x-[45%] translate-y-[70%]">
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>Dealer cannot have more than 1 card.</p>
              </div>
              <div id="cartas_dealer" onClick={()=>Tooltip("dealer")} className="flex justify-start items-center gap-1 px-2 py-0.5 border border-red-600 text-gray-900 rounded-full w-2/5 h-[28px] bg-gray-300 dark:bg-darkBlue2 hover:cursor-pointer"></div>
            </div>  
          </div>

          <div className="flex flex-col gap-4 items-center border border-gray-600 rounded-md bg-white dark:bg-newBlue">
            <div className="flex justify-center items-center " id="roundsPlayed">
              <div className="flex flex-col gap-1 ">
                <div className="flex justify-center">
                  <label className="font-body text-md text-black dark:text-gray-400">ROUNDS PLAYED</label>
                </div>
                <div className="flex">
                  <div className="flex items-center justify-center">
                    <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-l-full font-body font-bold text-lg px-3 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editRounds('-')}> - </button>
                    <input className="w-3/5 border-y border-gray-600 pl-1 h-full font-body placeholder-gray-800 bg-gray-300 dark:bg-darkBlue2 dark:placeholder-gray-400 text-black dark:text-gray-400 text-center" value={roundsPlayed} onChange={handleRoundsChange} id="rounds"/>
                    <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-r-full font-body font-bold text-lg px-2.5 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editRounds('+')}> + </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="flex flex-col justify-center items-center" id="bankroll">
              <div className="flex flex-col gap-1">
                <div className="flex justify-center">
                  <label className="font-body text-md text-black dark:text-gray-400">CURRENT BANKROLL</label>
                </div>
                <div className="flex flex-col items-center">
                  <div id='tooltip_bankroll' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[150px] rounded px-2 pb-1 absolute transform -translate-x-[75%] -translate-y-[95%]">
                    <IoClose onClick={()=>Tooltip("bankroll")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                    <p className='font-body font-semibold text-gray-800 text-xs text-justify'>Best Bet cannot be calculated without bankroll.</p>
                  </div>
                  <div className="flex items-center justify-center">
                    <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-l-full font-body font-bold text-lg px-3 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editBankRoll('-', setCurrentBankroll, currentBankroll,  initial_bankroll)}> - </button>
                    <input className="w-3/5 border-y border-gray-600 pl-1 h-full font-body placeholder-gray-800 bg-gray-300 dark:bg-darkBlue2 dark:placeholder-gray-400 text-black dark:text-gray-400 text-center" value={currentBankroll} placeholder="Insert amount" onChange={(e)=>setCurrentBankroll(e.target.value)} id="money"/>
                    <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-r-full font-body font-bold text-lg px-2.5 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editBankRoll('+', setCurrentBankroll, currentBankroll,  initial_bankroll)}> + </button>
                  </div>
                </div>
              </div>

              <label className='font-body text-[11px] text-red-500 text-center'>
                INITIAL BANKROLL <span style={{ color: 'white' }}>${initial_bankroll}</span>
              </label>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
  
}

export default NoKeyboard;