import resetBestDecs from "./resetBestDecs";

function changeDecision(response) { 
    
    resetBestDecs();

    // esperanza de cada decision
    var prob1 = !isNaN(parseFloat(response.esp_stand)) ? parseFloat(response.esp_stand*100).toFixed(1) + '%' : '';
    var prob2 = !isNaN(parseFloat(response.esp_hit)) ? parseFloat(response.esp_hit*100).toFixed(1) + '%': '';
    var prob3 = !isNaN(parseFloat(response.esp_double)) ? parseFloat(response.esp_double*100).toFixed(1) + '%': '';
    var prob4 = !isNaN(parseFloat(response.esp_split)) ? parseFloat(response.esp_split*100).toFixed(1) + '%': '';

    try{
        document.getElementById('e1').innerHTML = prob2
        document.getElementById('e2').innerHTML = prob1
        document.getElementById('e3').innerHTML = prob4
        document.getElementById('e4').innerHTML = prob3
    } catch {}

    if(response.decision === 0){ 
        //cambio de color al texto de la accion
        document.getElementById('t2').className = document.getElementById('t2').className.slice(0, 17) + ' text-black'

        //cambio de color de fondo de la accion
        document.getElementById('a2').className = document.getElementById('a2').className.slice(0, 81) + ' animate-wiggle bg-green-500'
    }
    else if(response.decision === 1){
        document.getElementById('t1').className = document.getElementById('t1').className.slice(0, 17) + ' text-black'
        document.getElementById('a1').className = document.getElementById('a1').className.slice(0, 81) + ' animate-wiggle bg-green-500'
    }
    else if(response.decision === 2){
        document.getElementById('t4').className = document.getElementById('t4').className.slice(0, 17) + ' text-black'
        document.getElementById('a4').className = document.getElementById('a4').className.slice(0, 81) + ' animate-wiggle bg-green-500'
    }
    else if(response.decision === 3){
        document.getElementById('t3').className = document.getElementById('t3').className.slice(0, 17) + ' text-black'
        document.getElementById('a3').className = document.getElementById('a3').className.slice(0, 81) + ' animate-wiggle bg-green-500'
    }
    
}

export default changeDecision;