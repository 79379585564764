import { useAuth0 } from "@auth0/auth0-react";
import { HiMenu } from 'react-icons/hi';
import { FaCrown } from 'react-icons/fa';
import { useNavigate } from "react-router-dom";
import logo from "../content/logov4.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";


function Header({ subscriptionPlan, expirationDate, newWindow, setShowMenu , showMenu}) {
  const [cargando, setCargando] = useState(false);
  const [veces, setVeces] = useState(0);
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();

  const handleProfileClick = () => {
    const queryParams = new URLSearchParams({
      subscriptionPlan: subscriptionPlan,
      expirationDate: expirationDate,
    });
    navigate(`/profile?${queryParams.toString()}`);
  };
	
	return (
		<nav className="flex w-full gap-20 justify-between bg-footer pl-2 pr-6 md:px-6 fixed h-14 md:h-20 z-20">
			<div className="flex w-fit items-center py-1"><img src={logo} alt="logo_bj" className=' w-28 md:w-52'/></div>
			{!cargando ? (
  !isAuthenticated ? (
    <div className="md:hidden flex items-center justify-end gap-3">
      <button
        onClick={() => loginWithRedirect()}
        className="font-menu font-bold text-white underline decoration-2 text-lg hover:text-red-500 whitespace-nowrap"
      >
        Log In
      </button>
      <button
        onClick={() => loginWithRedirect({ screen_hint: "signup", appState: { returnTo: '/?status=signedup' } })}
        className="flex justify-center items-center rounded-full bg-red-500 text-white font-menu font-bold h-fit hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-0.5 whitespace-nowrap px-2"
      >
        Sign Up
      </button>
      <button onClick={() => setShowMenu(!showMenu)}>
        <HiMenu className="text-3xl text-white" />
      </button>
    </div>
  ) : (
    <div className="md:hidden flex items-center justify-end gap-3">
      <button onClick={() => setShowMenu(!showMenu)}>
        <HiMenu className="text-3xl text-white" />
      </button>
    </div>
  )
) : null}


			<div className="hidden md:flex w-full justify-end gap-14">
				<div className="flex w-4/5 ">
					<div className="flex w-full justify-center hover:border-y-4 border-red-500 duration-100 cursor-pointer"><Link to='/' className="flex w-full justify-center items-center text-menuText font-menu font-bold p-3 hover:cursor-pointer hover:text-red-500 duration-500">HOME</Link></div>
					<div className="flex w-full justify-center hover:border-y-4 border-red-500 duration-100 cursor-pointer"><Link to='/simulations' className="flex w-full justify-center items-center text-menuText font-menu font-bold p-3 hover:cursor-pointer hover:text-red-500 duration-500">SIMULATIONS</Link></div>
					<div className="flex w-full justify-center hover:border-y-4 border-red-500 duration-100 cursor-pointer"><Link to='/subscriptions' className="flex w-full justify-center items-center text-menuText font-menu font-bold p-3 hover:cursor-pointer hover:text-red-500 duration-500">SUBSCRIPTIONS</Link></div>
					<div className="flex w-full justify-center hover:border-y-4 border-red-500 duration-100 cursor-pointer"><Link to='/blog' className="flex w-full justify-center items-center text-menuText font-menu font-bold p-3 hover:cursor-pointer hover:text-red-500 duration-500">BLOG</Link></div>
					<div className="flex w-full justify-center hover:border-y-4 border-red-500 duration-100 cursor-pointer"><Link to='/aboutus' className="flex w-full justify-center items-center text-menuText font-menu font-bold p-3 hover:cursor-pointer hover:text-red-500 duration-500">ABOUT US</Link></div>
					<div className="flex w-full justify-center hover:border-y-4 border-red-500 duration-100 cursor-pointer"><Link to='/research' className="flex w-full justify-center items-center text-menuText font-menu font-bold p-3 hover:cursor-pointer hover:text-red-500 duration-500">RESEARCH</Link></div>
					
				</div>
				{isAuthenticated 
					? <div className="flex items-center gap-10">
							<button className="hidden md:flex justify-center items-center rounded-full bg-red-500 text-white font-menu font-bold px-3 py-2 hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-0.5" onClick={() => newWindow()}>TRY NOW!</button>
							<div className="flex items-center justify-between ">
								<button onClick={handleProfileClick} className="relative flex items-center gap-2 cursor-pointer py-2">
									<img alt='imagen de perfil' src={user.picture} className='rounded-full w-10'></img>
									<p className="text-menuText font-menu">{user.given_name} </p>
									{subscriptionPlan!=-1 && (
										<FaCrown className="absolute top-0 left-5 transform -translate-x-1/2 -translate-y-1/2 text-yellow-500 w-4 h-4"/>
									)}
								</button>
							</div>
						</div>
					: <div className="flex items-center py-1 gap-5">
							<button onClick={() => loginWithRedirect()} className="font-menu font-bold text-white underline decoration-2 text-lg hover:text-red-500">Log In</button>
							<button onClick={() => loginWithRedirect({screen_hint: 'signup', appState: { returnTo: '/?status=signedup' } })} className="flex justify-center items-center rounded-full bg-red-500 text-white font-menu font-bold px-5 h-fit  py-1 hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-0.5">Sign Up</button>
						</div>
				}
		</div>	
		</nav>
	)

}

export default Header;