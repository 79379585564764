export default function SecondGraph({ getGraficos, manos, setManos }) {
    return (
      <div className="flex flex-col bg-darkBlue2 p-8 gap-6">
        {/*<div className="flex justify-center items-center">
          <h2 className="font-body text-gray-400 text-2xl text-justify">
            Hi-Lo count and Basic Strategy
          </h2>
        </div>*/}
  
        {/*<button
          onTouchStart={() => {
            getGraficos("sim");
          }}
          className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm py-1.5 px-3 hover:cursor-pointer bg-gradient-to-br from-green-600 to-green-600 text-gray-700"
        >
          <div className="flex flex-row gap-3">
            <p className="font-body text-justify">Simulate</p>
          </div>
        </button>
  
        <div className="flex flex-col justify-center items-center w-full h-full">
          <canvas id="simulacion" className="h-full w-full"></canvas>
        </div>*/}
        
        <div className="flex flex-col items-center justify-between p-5 w-fit bg-gray-300 h-[300px] rounded-lg">
					<div className="flex justify-between w-full relative">
						<h3 className="font-title font-medium text-black text-2xl md:text-4xl">Simulator</h3>
						<button onClick={()=>getGraficos("sim")} className="absolute bg-red-500 rounded-lg py-2 px-3 font-title text-white font-medium right-0">SIMULATE</button>
					</div>
					<canvas id="simulacion" height="200"></canvas>			
				</div>
        
        {/*<div className="flex justify-center items-center gap-7">
          <label className="font-title font-medium text-menuText">
            Number of hands:
          </label>
          <input
            type="range"
            id="n_manos"
            min="100"
            max="2000"
            step="10"
            className="w-1/2 h-2 mb-6 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700 accent-red-500"
            onChange={(e) => setManos(e.target.value)}
            value={manos}
          />
          <label id="n_manos_v" className="font-title font-medium text-menuText">
            {manos}
          </label>
        </div>*/}
  
        
      </div>
    );
  }
  
