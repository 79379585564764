import React, { useState } from 'react';
import Header from "../Header";
import Footer from "../Footer";
import newWindow from "../../helpers/newWindow";
import CelMenu from "../CelMenu";
import useFetchUser from "../../hooks/fetchUser";
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

function Research() {
    const [showMenu, setShowMenu] = useState(false);
    const { subscriptionPlan, expirationDate, cachedUser } = useFetchUser();
    const defaultLayoutPluginInstancePlaying = defaultLayoutPlugin();
    const defaultLayoutPluginInstanceBetting = defaultLayoutPlugin();

    return (
        <div className="flex flex-col min-h-screen">
            <Header subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu} />
            <CelMenu showMenu={showMenu} />
            <div className={`flex justify-center px-5 md:px-[20%] w-full ${showMenu ? 'pt-64' : 'pt-20'} md:pt-24 bg-darkBlue pb-20 flex-grow`}>
                <div className="flex flex-col pt-5 w-full">
                    <div className="flex justify-center">
                        <p className="font-title font-medium text-white text-2xl md:text-4xl">RESEARCH</p>
                    </div>

                    <div className="flex flex-col justify-center mt-5 w-full">
                        <p className="text-justify font-body text-menuText md:text-xl mt-10">
                            Our calculator is the result of extensive formal research. In this section, you will find two documents specifically adapted for the website: <span className="font-bold">Playing Policy: Mathematical Formulation</span>, and <span className="font-bold">Betting Policy: Mathematical Formulation</span>. These documents formalize the strategies that completely define the behavior of a blackjack player and rigorously outline the optimization problem that the player must solve to find the optimal strategies. The documents aim to provide answers for those most interested, and we will be delighted to address any questions you may have. (last updated: June 28, 2024).
                        </p>
                    </div>

                    <div className="flex flex-col justify-center mt-10 w-full">
                        <h2 className="text-center font-title font-medium text-white text-2xl md:text-2xl">Playing Policy</h2>
                        <div className="flex justify-center">
                            <div style={{ height: '80vh', width: '80vw' }}>
                                <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
                                    <Viewer fileUrl="/pdfs/Research__Playing_Policy.pdf" plugins={[defaultLayoutPluginInstancePlaying]} />
                                </Worker>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col justify-center mt-10 w-full">
                        <h2 className="text-center font-title font-medium text-white text-2xl md:text-2xl">Betting Policy</h2>
                        <div className="flex justify-center">
                            <div style={{ height: '80vh', width: '80vw' }}>
                                <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
                                    <Viewer fileUrl="/pdfs/Research_Betting_Policy.pdf" plugins={[defaultLayoutPluginInstanceBetting]} />
                                </Worker>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Research;
