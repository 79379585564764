import { FiDelete } from 'react-icons/fi';
import addChild from "../helpers/addChildNode";
import clearHands from "../helpers/clearHands";
import editBankRoll from "../helpers/editBankRoll";
import EditLatestCards from "../helpers/editLatestCards";
import getBestBet from "../helpers/getBestBet";
import getBestDecision from "../helpers/getBestDecision";
import toPeso from "../helpers/toPeso";
import { IoClose } from 'react-icons/io5';
import { ShowValidationMessage } from '../helpers/ShowValidationMessages';
import { validarAgregarCartaJugador } from "../helpers/showScores"
import { useState } from 'react';

var keysPressed = []

document.addEventListener('keydown', (event) => {
  event.stopPropagation()
  keysPressed[event.key] = true; 
});
document.addEventListener('keyup', (event) => {
  event.stopPropagation()
  delete keysPressed[event.key]; 
});

function focusInput() {
  // cambia el focus del boton para que al presionar spacebar o enter no se active el boton
  const aux = document.getElementById("bestDecision");
  try{
    aux.focus();
  } catch {}
  // textField.blur();
}

function WithKeyboard(subscriptionPlan, subscribed, setSubscribed, percentages, darkMode, calculatorMode, isAuthenticated, hands, ror, desv_cond, initial_bankroll, setInitBankroll, 
  roundsPlayed, setRoundsPlayed, currentBankroll, setCurrentBankroll){

  const handleRoundsChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 0) {
      setRoundsPlayed(newValue);
    }
    if (e.target.value === ''){
      setRoundsPlayed(0)
    }
  };

  const editRounds = (operator) => {  
    if (operator === '+'){
      setRoundsPlayed((prevRounds) => prevRounds + 1);
    }
    if (operator === '-'){
      setRoundsPlayed((prevRounds) => Math.max(prevRounds - 1, 0));
    }
  };

  const mouseInput = (value) => {
    if (keysPressed['q']  || keysPressed['Q']){
      const jugador = document.getElementById("cartas_jugador")
      if(validarAgregarCartaJugador(value)){
        addChild(jugador, 'green', value) 
        EditLatestCards(value, 'green')
        getBestDecision()
      } else {
        ShowValidationMessage("validation_player")
      }
    }
    else if(keysPressed['e']  || keysPressed['E']){
      const dealer = document.getElementById("cartas_dealer")
      if(dealer.childElementCount < 1){
        addChild(dealer, 'red', value) 
        EditLatestCards(value, 'red')
        getBestDecision()
      } else {
        ShowValidationMessage("validation_dealer")
      }
    }
    else{
      EditLatestCards(value, 'black')
    }
  }

  function Tooltip(objective){
    const player_visible = !document.getElementById("tooltip_player").classList.contains("invisible");
    const dealer_visible = !document.getElementById("tooltip_dealer").classList.contains("invisible");
    if(objective === "player"){
      if(dealer_visible){
        document.getElementById("tooltip_dealer").classList.toggle("invisible");
      }
      document.getElementById("tooltip_player").classList.toggle("invisible");
    } else if(objective === "dealer") {
      if(player_visible){
        document.getElementById("tooltip_player").classList.toggle("invisible");
      }
      document.getElementById("tooltip_dealer").classList.toggle("invisible");
    } else {
      document.getElementById("tooltip_bankroll").classList.toggle("invisible");
    }  
  }

  function NotLoggedBet() {
    const bankroll = document.getElementById("money").value
    const stringWithoutDots = bankroll.replace(/\./g, '');
    const parsedInt = parseInt(stringWithoutDots, 10);
    if(parsedInt > 0){
      const min = -0.1 * parsedInt;
      const max = 0.6 * parsedInt;
      const randomValue = Math.random() * (max - min) + min;
      document.getElementById('amount').innerHTML = "$" + randomValue.toFixed(0)
      document.getElementById('amount').value = randomValue.toFixed(0)

      //Se guarda el estimated_value
      document.getElementById('estimated_v').innerHTML = "EV: Demo" 

      //Se guarda la desviacion estandar 
      document.getElementById('std').innerHTML = "STD: Demo" 
    } else {
      document.getElementById("tooltip_bankroll").classList.toggle("invisible");
    } 
  }

  return (
    <div className={darkMode ? "flex gap-2 dark" : "flex gap-2"} >

      {/* --------------------------------------------------- Columna izquierda ----------------------------------------------*/}
      <div className="flex flex-col justify-between gap-2 w-1/2" id="col1">
        <div className="flex flex-col justify-between gap-2 w-full h-[95px]" id="bestBet">
          <div className=" flex flex-col border border-gray-600 pt-2 pb-2 rounded-md shadow-xl gap-2 bg-white dark:bg-newBlue h-full">
            <div className="flex items-center justify-around h-full">
              <div className='flex flex-col items-center justify-start h-full w-1/2'>
                {isAuthenticated ?
                    <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>{getBestBet(setInitBankroll); focusInput()}}> Best Bet! </button>
                  : <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>{NotLoggedBet()}}> Best Bet! </button>
                }
                <label className='font-body text-sm text-black dark:text-gray-400'>Strategy</label>
                <label className='font-body text-[11px] text-red-500 text-center'>
                  {/* H={hands}, R={ror * 100}%, D={
                    desv_cond === 0
                      ? "Level 1"
                      : desv_cond === 1.25
                      ? "Level 2"
                      : `${(parseFloat(desv_cond) * 100).toFixed(2)}%`
                  } */}
                  H={hands}, R={ror}
                </label>
              </div>
              {/* <div className="flex flex-col items-center justify-start h-full w-1/2">
                <label id="amount" className="font-body text-lg text-black dark:text-gray-400"></label>
                <label id='estimated_v' className="font-body text-sm text-black dark:text-gray-400"></label>
                <label id='std' className="font-body text-sm text-black dark:text-gray-400"></label>
              </div>  */}
                {subscriptionPlan==0||subscriptionPlan==2? (
                  <div className="flex flex-col items-center justify-start h-full w-1/2">
                    <label id="amount" className="font-body text-lg text-black dark:text-gray-400"></label>
                    <label id='estimated_v' className="font-body text-sm text-black dark:text-gray-400"></label>
                    <label id='std' className="font-body text-sm text-black dark:text-gray-400"></label>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full w-1/2">
                    <span> &#128274;</span>
                  </div>
                )}
            </div>
          </div>
          
        </div>

        <div className={calculatorMode === "ci" ? "flex flex-col gap-2 border border-gray-600 p-2 rounded-md bg-white dark:bg-newBlue pointer-events-none blur" : "flex flex-col gap-2 border border-gray-600 p-2 rounded-md bg-white dark:bg-newBlue"} id="hands">
            <div className="flex justify-center">
              <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700  hover:bg-blue-300 duration-500" onClick= {()=>{clearHands(); focusInput()}}> Clear Hands</button>
            </div>
            <div className="group relative flex items-center justify-between mx-1 ">
              <label className="font-body text-sm text-black dark:text-gray-400"> Player Hand</label>
              <div id='tooltip_player' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[200px] rounded px-2 pb-1 absolute transform translate-x-[88%] -translate-y-[50%]">
                <IoClose onClick={()=>Tooltip("player")}  className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>To enter the player's hand, hold down the “Q" key and click his cards or type them on the keyboard.</p>
              </div>
              <div id='validation_player' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[170px] rounded px-2 py-1 absolute transform translate-x-[103%] translate-y-[70%]">
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>Player cannot exceed 21.</p>
              </div>
              <div id="cartas_jugador" onClick={()=>Tooltip("player")} className="flex justify-start items-center gap-1 px-2 py-0.5 border border-green-600 text-gray-900 rounded-full w-2/5 h-[28px] bg-gray-300 dark:bg-darkBlue2 hover:cursor-pointer"></div>
            </div>
            <div className="group relative flex items-center justify-between mx-1 ">
              <label className="font-body text-sm text-black dark:text-gray-400"> Dealer Card</label>
              <div id='tooltip_dealer' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[200px] rounded px-2 pb-1 absolute transform translate-x-[88%] -translate-y-[50%]">
                <IoClose onClick={()=>Tooltip("dealer")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>To enter the dealer's hand, hold down the “E" key and click his cards or type them on the keyboard.</p>
              </div>
              <div id='validation_dealer' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[200px] rounded px-2 pb-1 absolute transform translate-x-[88%] translate-y-[70%]">
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>Dealer cannot have more than 1 card.</p>
              </div>
              <div id="cartas_dealer" onClick={()=>Tooltip("dealer")} className="flex justify-start items-center gap-1 px-2 py-0.5 border border-red-600 text-gray-900 rounded-full w-2/5 h-[28px] bg-gray-300 dark:bg-darkBlue2 hover:cursor-pointer"></div>
            </div>  
        </div>

        <div className={calculatorMode === "ci" ? "flex flex-col gap-5 border border-gray-600 p-2 rounded-md bg-white dark:bg-newBlue pointer-events-none blur" : "flex flex-col gap-5 border border-gray-600 p-2 rounded-md bg-white dark:bg-newBlue"} id="decisions" style={{ height: '100%', flexGrow: 1 }}>

          <div className="flex justify-center gap-2">
            <button id="bestDecision"className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700  hover:bg-blue-300 duration-500" onClick={() =>{getBestDecision(); focusInput()}}> Best Decision </button>
            <p className='font-body text-lg text-black dark:text-gray-400' id='scores'></p>
          </div>       

          <div className="flex flex-col justify-between h-2/3">

            <div className="flex flex-row h-1/3 gap-2 justify-between">
              <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a1">
                <label id="t1" className="font-body text-sm text-black dark:text-gray-400">Hit</label> 
                {percentages ? <label className="font-body text-sm text-white " id="e1">0</label>:<></>}
              </div>

              <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a2">   
                <label id="t2" className="font-body text-sm text-black dark:text-gray-400">Stand</label> 
                {percentages ? <label className="font-body text-sm text-white " id="e2">0</label>:<></>}
              </div>
            </div>

            <div className="flex flex-row h-1/3 gap-2 justify-between">
              <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a3">   
                <label id="t3" className="font-body text-sm text-black dark:text-gray-400">Split</label> 
                {percentages ? <label className="font-body text-sm text-white " id="e3">0</label>:<></>}
              </div>

              <div className="flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full dark:bg-darkBlue2 bg-gray-300" id="a4">  
                <label id="t4" className="font-body text-sm text-black dark:text-gray-400">Double</label> 
                {percentages ? <label className="font-body text-sm text-white " id="e4">0</label>:<></>}
              </div>
            </div> 
            
          </div>    
        </div>
      </div>

      {/* --------------------------------------------------- Columna derecha ----------------------------------------------*/}
      <div className="flex flex-col gap-2 w-1/2" id="col2">

        <div className={calculatorMode === "ci" ? "flex justify-center pointer-events-none blur": "flex justify-center"} id="teclado">
          <div className="grid grid-cols-3 w-full h-[222px]">

            <button onClick={(e)=>mouseInput('2')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center rounded-tl-md">2</button>
            <button onClick={(e)=>mouseInput('3')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center">3</button>
            <button onClick={(e)=>mouseInput('4')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center rounded-tr-md">4</button>
            <button onClick={(e)=>mouseInput('5')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center">5</button>
            <button onClick={(e)=>mouseInput('6')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center">6</button>
            <button onClick={(e)=>mouseInput('7')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center">7</button>
            <button onClick={(e)=>mouseInput('8')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center">8</button>
            <button onClick={(e)=>mouseInput('9')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center">9</button>
            <button onClick={(e)=>mouseInput('0')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center">10</button>
            <button onClick={(e)=>mouseInput('1')} className="font-body text-black dark:text-white text-md bg-white dark:bg-newBlue hover:bg-gray-400 dark:hover:bg-darkBlue3 border border-gray-600 p-1.5 text-base text-center rounded-bl-md">As</button>
            <button onClick={(e)=>mouseInput('d')} className="flex justify-center items-center col-span-2 font-body text-black dark:text-white text-md bg-red-800 hover:bg-red-900 border border-gray-600 p-1.5 text-base text-center rounded-br-md"><FiDelete className="font-body font-black text-2xl"/></button>

          </div>
        </div> 
        <div className="flex flex-col gap-4 items-center border border-gray-600 rounded-md bg-white dark:bg-newBlue">
          <div className="flex justify-center items-center " >
            <div className="flex flex-col gap-1 ">
              <div className="flex justify-center">
                <label className="font-body text-md text-black dark:text-gray-400">ROUNDS PLAYED</label>
              </div>
              <div className="flex">
                <div className="flex items-center justify-center">
                  <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-l-full font-body font-bold text-lg px-3 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editRounds('-')}> - </button>
                  <input className="w-3/5 border-y border-gray-600 pl-1 h-full font-body placeholder-gray-800 bg-gray-300 dark:bg-darkBlue2 dark:placeholder-gray-400 text-black dark:text-gray-400 text-center" value={roundsPlayed} onChange={handleRoundsChange} id="rounds"/>
                  <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-r-full font-body font-bold text-lg px-2.5 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editRounds('+')}> + </button>
                </div>
              </div>
            </div>
          </div>
          
          <div className="flex flex-col justify-center items-center" id="bankroll">
            <div className="flex flex-col gap-1">
              <div className="flex justify-center">
                <label className="font-body text-md text-black dark:text-gray-400">CURRENT BANKROLL</label>
              </div>
              <div className="flex flex-col items-center">
                <div id='tooltip_bankroll' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[150px] rounded px-2 pb-1 absolute transform -translate-x-[75%] -translate-y-[95%]">
                  <IoClose onClick={()=>Tooltip("bankroll")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                  <p className='font-body font-semibold text-gray-800 text-xs text-justify'>Best Bet cannot be calculated without bankroll.</p>
                </div>
                <div className="flex items-center justify-center">
                  <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-l-full font-body font-bold text-lg px-3 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editBankRoll('-', setCurrentBankroll, currentBankroll,  initial_bankroll)}> - </button>
                  <input className="w-3/5 border-y border-gray-600 pl-1 h-full font-body placeholder-gray-800 bg-gray-300 dark:bg-darkBlue2 dark:placeholder-gray-400 text-black dark:text-gray-400 text-center" value={currentBankroll} placeholder="Insert amount" onChange={(e)=>setCurrentBankroll(e.target.value)} id="money"/>
                  <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-r-full font-body font-bold text-lg px-2.5 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500" onClick={()=>editBankRoll('+', setCurrentBankroll, currentBankroll,  initial_bankroll)}> + </button>
                </div>
              </div>
            </div>

            <label className='font-body text-[11px] text-red-500 text-center'>
              INITIAL BANKROLL <span style={{ color: 'white' }}>${initial_bankroll}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
  
}

export {WithKeyboard,focusInput};

