import Header from "../Header";
import Footer from "../Footer";
import newWindow from "../../helpers/newWindow";
import CelMenu from "../CelMenu";
import { useState } from "react";
import PhoneAlert from "../phoneAlert";
import useFetchUser from "../../hooks/fetchUser";

function TermsAndConditions() {
  const [showMenu, setShowMenu] = useState(false);
  const {subscriptionPlan, expirationDate, cachedUser} = useFetchUser();
  return(
    <div className="flex flex-col w-full bg-darkBlue">
      <Header subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu}/>
      <CelMenu showMenu={showMenu}/>
      <div className={showMenu ? "flex flex-col py-32 items-center justify-center gap-10 py-52 md:py-28 md:px-60 px-5" : "flex flex-col py-32 items-center justify-center gap-10 py-20 md:py-28 md:px-60 px-5"}>
        <div className="w-full flex flex-col items-center justify-center">
          <p className="font-title font-medium text-center text-white text-2xl md:text-4xl">Terms And Conditions</p>
        </div>
        <div className="flex flex-col w-full gap-5">
          <p className="font-title font-medium text-white text-xl md:text-2xl underline">1. Introduction</p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">By registering and using the blackjack odds calculator service ("the Service") 
            at www.bjtheorem.com ("the Site"), you ("the User") agree to be bound by the 
            following terms and conditions. If you do not agree to these terms and conditions, 
            you should not register for or use the Service.
          </p>
        </div>
        <div className="flex flex-col w-full gap-5">
          <p className="font-title font-medium text-white text-xl md:text-2xl underline">2. Description of the Service</p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">The Site provides a calculator that allows the User to keep score and odds in a game 
            of blackjack for entertainment and educational purposes only. The User can choose between daily, weekly 
            or monthly access plans, according to his/her needs.
          </p>
        </div>
        <div className="flex flex-col w-full gap-5">
          <p className="font-title font-medium text-white text-xl md:text-2xl underline">3. Legal Use and User's Responsibility</p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">You acknowledge and agree that the use of software to gamble on online blackjack sites 
            may be illegal in your jurisdiction. The User agrees not to use the Service for illegal activities or in 
            violation of any applicable law.
          </p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">The User is solely responsible for determining whether use of the Service is legal in 
            his or her jurisdiction and for complying with all applicable laws.
          </p>
        </div>
        <div className="flex flex-col w-full gap-5">
          <p className="font-title font-medium text-white text-xl md:text-2xl underline">4. Disclaimer of liability of the Service provider</p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">The User acknowledges and agrees that the Service provider, based in Chile, is not promoting, 
            encouraging and facilitating the use of the Service to gamble on online blackjack games or in physical casinos.
          </p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">The User agrees to hold the Service provider harmless from any claim, demand, damage, loss, cost, 
            expense or liability arising, directly or indirectly, from the User's use or misuse of the Service, including, but 
            not limited to, non-compliance with applicable laws relating to gambling or betting.
          </p>
        </div>
        <div className="flex flex-col w-full gap-5">
          <p className="font-title font-medium text-white text-xl md:text-2xl underline">5. Changes to Terms and Conditions</p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">The Service provider reserves the right to change these terms and conditions at any time, without notice. 
            The User is responsible for periodically reviewing these terms and conditions and complying with the updated versions. 
            The User's continued use of the Service following the posting of changes to these terms and conditions constitutes acceptance 
            of such changes.
          </p>
        </div>
        <div className="flex flex-col w-full gap-5">
          <p className="font-title font-medium text-white text-xl md:text-2xl underline">6. Termination</p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">The Service provider may, in its sole discretion, suspend or terminate the User's access to the Service at 
            any time and for any reason, without notice.
          </p>
        </div>
        <div className="flex flex-col w-full gap-5">
          <p className="font-title font-medium text-white text-xl md:text-2xl underline">7. Governing Law and Jurisdiction</p>
          <p className="font-body text-gray-400 md:w-3/4 text-justify">These terms and conditions are governed by and construed in accordance with the laws of Chile. Any dispute arising 
            in connection with these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Chile.
          </p>
        </div>
      </div>
      <Footer />
    </div>
    
  )

};

export default TermsAndConditions;