import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './index.css';

import { Auth0Provider } from '@auth0/auth0-react';
import AllPosts from './componentes/Blog/AllPosts';
import NewPost from './componentes/Blog/NewPost';
import EditPost from './componentes/Blog/EditPost';
import CalculatorPage from './componentes/CalculatorPage';
import FrontPage from './componentes/FrontPage';
import Profile from './componentes/Profile';
import Foto from './componentes/foto';
import AboutUs from './componentes/home/AboutUs';
import Algorithm from './componentes/home/Algorithm';
import PlayerEdge from './componentes/home/Simulations';
import Subscriptions from './componentes/home/Subscriptions';
import TermsAndConditions from './componentes/home/TaC';
import Research from './componentes/home/Research';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";


const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const management_client_id = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_ID; 
const management_client_secret = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_SECRET; 
const management_domain = process.env.REACT_APP_MANAGEMENT_BJ_DOMAIN; 

const onRedirectCallback = (appState) => {
  // Usa window.history.replaceState para actualizar la URL sin recargar la página
  window.history.replaceState({}, document.title, appState?.returnTo || window.location.pathname);
};

//paypal clientId se refiere a la API de paypal llamada, en este caso es una API sandbox
const PaypalOptions = {
  clientId: `${paypalClientId}`,
  currency: "USD",
  intent: "capture",
};

ReactGA.initialize('G-JX6HQGTCJH');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Auth0Provider domain={domain} clientId={clientId} redirectUri={window.location.origin} onRedirectCallback={onRedirectCallback} scope={[
    'openid',
    'profile',
    'email',
    'read:current_user',
    'create:current_user_metadata',
    'update:current_user_metadata',
    'update:users',
  ]}
  useRefreshTokens >
  <PayPalScriptProvider options={PaypalOptions}>
    <BrowserRouter>
      <PageViewTracker/>
      <Routes>
        <Route path='/' element={<FrontPage/>} />
        {/* Va a ser necesario modificar la forma en que se llama calculatorPage, para poder verificar subscripciones */}
        <Route path='/calculadora' element={<CalculatorPage/>} />
        <Route path='/profile' element={<Profile/>} />
        <Route path='/simulations' element={<PlayerEdge/>} />
        <Route path='/subscriptions' element={<Subscriptions/>} />
        <Route path='/aboutus' element={<AboutUs/>} />
        <Route path='/terms&conditions' element={<TermsAndConditions/>} />
        <Route path='/foto' element={<Foto/>} />
        <Route path='/blog' element={<AllPosts/>} />
        <Route path='/blog/newPost' element={<NewPost/>} />
        <Route path='/blog/editPost/:id' element={<EditPost/>} />
        <Route path='/research' element={<Research/>} />
      </Routes>
    </BrowserRouter>
  </PayPalScriptProvider>
  </Auth0Provider>
);

function PageViewTracker(){
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  //Logs para chequear variables de entorno:
  useEffect(() => {
    // console.log("clientId: ", clientId); // works
    // console.log('paypalClientId: ', paypalClientId); // works
    // console.log('management_client_id: ', management_client_id); // returns undefined
    // console.log('management_client_secret: ', management_client_secret); // returns undefined
    // console.log('management_domain: ', management_domain); // returns undefined
  }, []);
  return null;
}

