import addChild from "./addChildNode";



function EditLatestCards(value, color){
  var element = document.getElementById("new_cards");
  if(value === 'd' || value === 'Backspace'){
    
    try{
      var child = element.lastElementChild;
      const computedStyle = window.getComputedStyle(child);
      const color = computedStyle.backgroundColor;
      //const color = child.style.backgroundColor
      if(color === 'rgb(21, 128, 61)'){
        const jugador = document.getElementById("cartas_jugador")
        var p = jugador.lastElementChild;
        try{
          jugador.removeChild(p);
        }catch{} 
      }
      if(color === 'rgb(239, 68, 68)'){
        const dealer = document.getElementById("cartas_dealer")
        var d = dealer.lastElementChild;
        try{
          dealer.removeChild(d);
        }catch{}
      }

      

      if(child.innerHTML === 'As'){
        const valor = parseInt(document.getElementById(`value_c_${1}`).innerHTML) + 1
        document.getElementById(`value_c_${1}`).innerHTML = valor
      }
      else if(child.innerHTML === '10'){
        const valor = parseInt(document.getElementById(`value_c_${0}`).innerHTML) + 1
        document.getElementById(`value_c_${0}`).innerHTML = valor 
      }
      else{
        const valor = parseInt(document.getElementById(`value_c_${child.innerHTML}`).innerHTML) + 1
        document.getElementById(`value_c_${child.innerHTML}`).innerHTML = valor
      }
      element.removeChild(child);
      
    }
    catch(error){}
      
  }
  else{
    
    if(value !== '0'){
      addChild(element, color, value)
      const valor = parseInt(document.getElementById(`value_c_${value}`).innerHTML) - 1
      if(valor >= 0){
        document.getElementById(`value_c_${value}`).innerHTML = valor 
      }   
    }
    else{
      addChild(element, color, 10)
      const valor = parseInt(document.getElementById(`value_c_${0}`).innerHTML) - 1
      if(valor >= 0){
          document.getElementById(`value_c_${0}`).innerHTML = valor 
      }
    }
    if(element.childElementCount > 9){
      const firstchild = element.firstElementChild;
      try{
        element.removeChild(firstchild);
      }catch{}
    }
  }
}

export default EditLatestCards;