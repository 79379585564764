const BestDecisionMobile = async(setDisabledButton, cartasPlayerTotal, cartasDealerTotal, sumaPlayer, sumaDealer, 
    cartas, s17, decks, double, enhc, setBestDecision, setProb, setCargandoDecision, countMethod, decisionMethod, 
    hHands, returnTarget, percentile, hl_count, depth, alpha, k_value,c_value) => {
    setCargandoDecision(true);  
    setDisabledButton(true);
    try{
      if(cartasPlayerTotal.length > 1 && cartasDealerTotal.length > 0 && sumaPlayer <= 21 && sumaDealer <= 21){
        const cartasFetch = Object.values(cartas);
        const cartasPlayerTotalFetch = cartasPlayerTotal.map(subarreglo => subarreglo[0]);
        const cartasDealerTotalFetch = cartasDealerTotal.map(subarreglo => subarreglo[0]);
        const newDecks = "" + (decks);
        const newDouble = "" + (double);
        let newEnhc;
        let newS17;
        if (enhc){
          newEnhc = "0";
        } else {
          newEnhc = "1";
        }
        if (s17){
          newS17 = "0";
        } else {
          newS17 = "1";
        }
  
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/decision`,{
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            "comando":"decision",
            "array": cartasFetch,
            "jugador": cartasPlayerTotalFetch,
            "croupier": cartasDealerTotalFetch,
            "s17": newS17,
            "decks": newDecks,
            "double": newDouble,
            "enhc": newEnhc,
            "hl_count": hl_count,
            "depth": depth,
            "hands": hHands,
            "returnTarget": returnTarget,
            "percentile": percentile,
            "alphaValue": alpha,
            "k_value": k_value,
            "CM": countMethod,
            "DM": decisionMethod,
          })
        });
        const json = await response.json();
        console.log(json)
        setBestDecision(parseInt(json.decision));
        //var string_limpio = json.replace(/-inf/g, '"-Infinity"');
        //var array_resultante = JSON.parse(string_limpio);
        //const decision = array_resultante[0];
        //setBestDecision(decision);
        let prob_hit = !isNaN(parseFloat(json.esp_hit)) ? parseFloat(json.esp_hit*100).toFixed(1) + '%' : '';
        let prob_stand = !isNaN(parseFloat(json.esp_stand)) ? parseFloat(json.esp_stand*100).toFixed(1) + '%' : '';
        let prob_split = !isNaN(parseFloat(json.esp_split)) ? parseFloat(json.esp_split*100).toFixed(1) + '%' : '';
        let prob_double = !isNaN(parseFloat(json.esp_double)) ? parseFloat(json.esp_double*100).toFixed(1)  + '%': '';
        setProb({
          "prob_hit": prob_hit,
          "prob_stand": prob_stand, 
          "prob_split": prob_split, 
          "prob_double": prob_double
        })
      } else {
        setBestDecision(undefined)
      }
    } catch (error){
      console.error(error)
    }
    setDisabledButton(false);
    setCargandoDecision(false);
  }

  export default BestDecisionMobile;