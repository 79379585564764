import { HiX } from "react-icons/hi";
import Modal from 'react-modal';

function TutorialMobile({ modalIsOpen2, setModalIsOpen2, darkMode, setModalIsOpen }) {

  const steps = [
    "Go to Settings, and adjust the game rules: ENHC, Soft17, Double and the number of decks.",
    "At the middle section of Settings, configure your strategy mechanisms and your initial bankroll. Choose the counting method: Perfect Count/ Hi-Lo True Count, and the decision method: Perfect Decision/Basic Strategy.",
    "At the bottom of Settings, define the parameters that determine the strategy. Select the number of rounds “H” that make up the betting session, and the player's risk profile parameter \"R\" (it is strongly recommended to visit the SIMULATIONS section for a better understanding of the performance of the strategies).",
    "Go back to the calculator and enter your initial bankroll.",
  ];

  const perfectCountSteps = [
    "Starting a game from the beginning, with the full deck, start entering all the cards that are played on the table. You can do this with the keyboard or with the mouse manually in the clickable panel.",
    "Each time you finish a round, press the 'Best Bet' button, which will tell you how much to bet for the next round. This value is 0 most of the time and will only turn positive when the deck is profitable to bet on (positive EV).",
    "If pressing Best Bet gives you a positive amount, you should bet that amount in the game. This amount is optimized according to the strategy defined in the Settings section.",
    "Now, when it is time to register the cards that were dealt to your hand, you must press the green button \"Player Cards\". These will be recorded in the normal count, but they will also be added to your hand where it says \"Player\".",
    "Similarly, when you register the dealer's card, you must press the red button \"Dealer Cards\". This card will also be added to the general count, but it will also be added to the dealer's hand, in the \"Dealer\" area.",
    "If you have entered your hand and the dealer's hand validly, the best decision to play your hand on green will be displayed automatically, according to the decision method set.",
    "When playing your hand, follow the decision dictated by the calculator.",
    "If the decision involves receiving more cards, you must enter these as mentioned above, to update the best decision.",
    "Don't forget, that at all times, all cards played must be recorded in the general account.",
    "Once the round is over, update your Current Bankroll with the \"+\" or \"-\" buttons according to the result. You will probably not be able to match your calculator bankroll exactly with your actual bankroll. Don't worry, this will not affect your performance, just keep it as close as possible.",
    "If a round has ended and you did bet and participated in it, you must also update the Rounds Played, adding one more round with the \"+\" button, or manually by typing in the number.",
    "Check \"Best Bet\" again before the next round starts and bet if necessary.",
    "Once a full game is completed, the dealer resets the deck to its full state. Press \"Reset Count\" to reset the deck to its full state, and adjust the number of Rounds Played."
  ];

  const hiLoSteps = [
    "A table will be displayed, whose values represent the best bet, optimized according to the strategy defined in the Settings section.",
    "The best bet is determined by 3 variables: your current bankroll (horizontal axis of the table), the True Count (vertical axis of the table) and the number of rounds bet so far (slider).",
    "Following this strategy would imply betting what the table indicates at each moment, according to the value of the 3 variables mentioned above."
  ];

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const openSettings = () => {
    setModalIsOpen(true);
    closeModal2();
  }

  return (
    <Modal isOpen={modalIsOpen2} onRequestClose={closeModal2} style={
      { overlay: {}, content: { padding: 0, inset: 20, backgroundColor: darkMode ? "#252f5a" : 'white' } }}>
      <div className={darkMode ? "flex dark" : "flex "}>
        <div className="bg-white dark:bg-newBlue p-4 my-2">
          <div className="md:hidden flex items-center justify-end gap-2">
            <button onClick={openSettings} className="flex shadow-xl justify-center items-center border 
                border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 hover:cursor-pointer 
                bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700"> Settings </button>
            <button onClick={closeModal2}> <HiX className="text-3xl dark:text-white text-black" /> </button>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="text-2xl font-body text-sm text-black dark:text-gray-400 my-2 uppercase text-center"> STEP BY STEP GUIDE </h1>
          </div>
          <div className="bg-white dark:bg-newBlue gap-6 my-2">
            {steps.map((step, index) => (
              <div className="flex flex-row my-2 gap-3" key={index}>
                <p className="font-body text-sm text-blue dark:text-white">{index + 1}.</p> 
                <p className="font-body text-sm text-black dark:text-gray-400">{step}</p>
              </div>
            ))}
            <div className="my-4">
              <h2 className="font-body text-lg underline text-blue dark:text-white">5.1. If the counting method is Perfect Count:</h2>
              {perfectCountSteps.map((step, index) => (
                <div className="flex flex-row my-2 gap-3" key={`5.1.${index}`}>
                  <p className="font-body text-sm text-blue dark:text-white">5.1.{index + 1}.</p> 
                  <p className="font-body text-sm text-black dark:text-gray-400">{step}</p>
                </div>
              ))}
              <h2 className="font-body text-lg underline text-blue dark:text-white mt-4">5.2. If the counting method is Hi-Lo True Count:</h2>
              {hiLoSteps.map((step, index) => (
                <div className="flex flex-row my-2 gap-3" key={`5.2.${index}`}>
                  <p className="font-body text-sm text-blue dark:text-white">5.2.{index + 1}.</p> 
                  <p className="font-body text-sm text-black dark:text-gray-400">{step}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TutorialMobile;
