import { useAuth0 } from "@auth0/auth0-react";
import { BsHandThumbsUpFill } from 'react-icons/bs';
import { MdDashboardCustomize } from 'react-icons/md';
import { TfiTarget } from 'react-icons/tfi';
import newWindow from "../../helpers/newWindow";
import Footer from "../Footer";
import Header from "../Header";
import Plans from "../Plans";
import logo from "../../content/logo1.svg";
import CelMenu from "../CelMenu";
import { useState } from "react";
import backgroundMobile from "../../content/fondo_mobile.png";
import CalculadoraMobilImage from "../../content/CalculadoraMobile2.png";
import { Link } from "react-router-dom";
import {BsFillGearFill} from "react-icons/bs";
import {HiMenu} from "react-icons/hi";

import PhoneAlert from "../phoneAlert";

import { Helmet } from "react-helmet";


function HomeMobile({loginWithRedirect, isAuthenticated, subscriptionPlan, expirationDate}){
    const [showMenu, setShowMenu] = useState(false);
    const step = [
        "We've conducted an in-depth analysis of Blackjack, focusing on the highest possible theoretical returns achievable by an optimal player who precisely counts and bets based on the remaining cards in the deck. Our research led us to create a real-time calculator for the best strategy. accessible for free after logging in or through the demo. You can explore different strategies' profitability in the 'SIMULATIONS' section, learn about the theory behind perfect calculated strategies in the 'ALGORITHM' section, and find answers to common questions in the 'BLOG' section.",
        "Open the calculator, and access the step-by-step tutorial by clicking on the top icon of the calculator",
        "Review an analysis of the returns obtained in the SIMULATION section, by accessing through the top icon of the web page!"
    ]

    return(
        <div className="flex flex-col w-screen">
        <Helmet>
          <title>Blackjack Theorem</title>
          <meta name="description" content="Master the art of Blackjack and elevate your game to the next level with Blackjack Theorem." />
          <meta property="og:title" content="Blackjack Theorem" />
          <meta property="og:description" content="Blackjack Theorem helps you perfect your Blackjack game skills. Our intuitive tool guides you in every decision, allowing you to optimize your skills and knowledge. Elevate your game to the next level with us!" />
          <meta property="og:image" content="https://bjtheorem.com/static/media/logo.c5df72f6a84df8674398eb65331bb53.png" />
          <meta property="og:url" content="https://bjtheorem.com" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Blackjack Theorem" />
          <meta property="og:locale" content="en_US" />
        </Helmet>
        <Header newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu}/>
        <CelMenu showMenu={showMenu} subscriptionPlan={subscriptionPlan} expirationDate={expirationDate}/>

        <div className={showMenu ? "flex flex-col w-full items-center bg-newBlue py-60 md:py-15" : "flex flex-col w-full items-center bg-newBlue py-15"}>
        <div className="flex w-full items-center justify-between h-full pt-10 relative" style={{backgroundImage: `url(${backgroundMobile})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%"}}>
    <div className="flex justify-start w-1/2">
      <img src={logo} alt="logo_bj" className='h-3/5'/>
    </div>
  
    <div className="absolute bottom-0 right-0 flex flex-col justify-end items-end w-2/5 h-full">
      {!isAuthenticated 
        ? <div className="flex w-full items-end justify-end p-3">
            <button onClick={() => loginWithRedirect()} className="flex px-6 justify-center items-center rounded-md 
            bg-red-500 text-white font-title font-bold hover:cursor-pointer hover:bg-red-600 duration-500 
            hover:-translate-y-0.5 whitespace-nowrap"> 
              GET STARTED NOW! 
            </button>
          </div> 
           
        : 
        <div className="flex w-full items-end justify-end p-3">
        
        
          <button onClick={() => newWindow()} className="flex px-6 justify-center items-center rounded-md bg-red-500 text-white font-title 
          font-bold hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-0.5 mb-5 whitespace-nowrap">
            TRY NOW!
          </button>
        
        </div> 
      }
      </div>
      </div>
          <div className="flex justify-center font-title font-medium text-white text-4xl font-bold py-4 w-3/4 mt-4">
            <p> Blackjack Theorem </p>
            
          </div> 
          <div className="flex justify-center font-body text-gray-400 pb-8 w-3/4">
            <div className="flex flex-col gap-8">
            <p style={{ textAlign: 'justify' }}>
              We are Blackjack Theorem, a team of researchers with backgrounds in mathematics, financial theory, and computer science. We have developed an in-depth analysis of 21 Blackjack, exploring the theoretical limits of the advantage for a player with perfect information. Using Markov Decision Processes, stochastic optimization, and machine learning, we have found optimal betting strategies according to the optimality criteria set by the player. Our research includes the use of dynamic programming algorithms, Expected Utility Theory, and reinforcement neural networks to obtain the best possible strategies.
            </p>
            <p style={{ textAlign: 'justify' }}>
              We offer a real-time optimal betting calculator for different counting and decision methods, and a <Link to={"/simulations"} className="text-red-500 underline italic">SIMULATION</Link> section where you can visualize the performance of the strategies. You can use the demo for free, or SUBSCRIBE (currently free beta version) to access the full calculator and compute the best bet depending on the counting method you select, including the Hi-LO method. Visit our <Link to={"/research"} className="text-red-500 underline italic">RESEARCH</Link> and <Link to={"/blog"} className="text-red-500 underline italic">BLOG & FAQ</Link> sections for more information.
            </p>

            <p className="font-body text-justify text-white text-2xl md:text-2xl italic font-bold">
              "Master the art of Blackjack and elevate your game to the next level."
            </p>
            </div>
          </div> 

        <div className="flex flex-row bg-darkBlue2 pt-10 pb-5 gap-2 px-3">
        <div className="flex flex-col justify-center items-center w-[150px] h-[350px] gap-4">
          <img src={CalculadoraMobilImage} alt="calculadora" className='w-full h-full'/>
          {isAuthenticated?      
          
          <button onClick={() => newWindow()} className="flex px-6 justify-center items-center rounded-md bg-red-500 text-white font-title font-bold hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-0.5 mb-5 whitespace-nowrap">TRY FULL VERSION</button>
          
          :
          <button className="flex px-6 justify-center items-center rounded-md bg-red-500 text-white font-title font-bold hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-0.5 mb-5 whitespace-nowrap" onClick={() => {loginWithRedirect()}}>TRY DEMO</button>
          }   
        </div>
          <div className="flex flex-col w-3/5 gap-2">
          <div className="flex items-center justify-start w-full">
            <div className="flex flex-row gap-1">    
              <div className="flex h-1/2 mt-2">
              <BsFillGearFill className="text-3xl text-white"/>
              </div>       
              <div className="flex w-full">
              <p className="font-body text-justify p-2 text-gray-400 md:text-xl w-full">
                {step[1]}
              </p>
            </div>
            </div>

          </div>
          <div className="flex items-center md:justify-center justify-end gap-5">
          <div className="flex flex-row gap-1">    
            <div className="flex h-1/4  mt-2">
              <HiMenu className="text-3xl text-white"/>
              </div> 
            <div className="flex w-full">
              <p className="font-body text-justify p-2 text-gray-400 md:text-xl w-full">
                {step[2]}
                </p>
            </div>
          </div>
          </div>
          </div>
        </div>

        <div className="flex justify-center font-title font-medium text-white text-4xl font-bold py-4 w-3/4 mt-2">
            <p>Presentation Video</p>
          </div> 
        
        <div className="flex justify-center items-center w-[90%] h-[235px] bg-black mt-5 mb-20">
          <div className="video-container flex h-full w-full">
            <iframe
               className="flex w-full h-full"
                src="https://www.youtube.com/embed/J5wolPYRUKI?si=guj0ry3RO8X8q2p3" 
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>    
            </iframe>
         </div>
        </div>
        </div>
        <Plans/>
        <Footer />
      </div> 
    )
}

export default HomeMobile;