import Calculadora from "./Calculator";
import CalculadoraMobil from "./Mobile/CalculatorMobile";
import { useState, useEffect } from "react";
import { isMobile } from 'react-device-detect';
import { useAuth0 } from '@auth0/auth0-react';
import checkExpirationDate from "../helpers/checkExpirationDate";
import useFetchUser from "../hooks/fetchUser";


function CalculatorPage() {

  const [started, setStarted] = useState(false);
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const {subscriptionPlan, expirationDate, cachedUser} = useFetchUser();

  // Produccion:
  
  return (
    <div>
      {isMobile
        ? <div>
            <CalculadoraMobil 
              subscriptionPlan={isAuthenticated
                ? 2
                : -1
              } 
              isAuthenticated={isAuthenticated}
            />
            
          </div>
        : <div>
            <Calculadora 
              isAuthenticated={isAuthenticated} 
              subscriptionPlan={subscriptionPlan}
            /> 
            
          </div> 
      } 
    </div> 
  )
  

  // Para testear movil: 

  // return (
  //       <div className="flex">
  //         <CalculadoraMobil subscriptionPlan={subscriptionPlan}/>
  //       </div>
  // )
}

export default CalculatorPage;