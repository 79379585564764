export default function CalculateBet({}) {
    return (
      <div className="flex bg-darkBlue2 flex-col gap-4 pl-2 pr-2 pb-20 h-full">
        <div className="flex justify-center rounded-full">
          <table className="w-[95%] rounded-lg overflow-hidden">
            <thead>
              <tr className="bg-darkBlue2">
                <th className="font-body text-gray-400 text-xl text-center border-b-2 border-t-2 border-l-2 border-gray-400 w-full py-2 px-4 w-[50%]">
                  RULES
                </th>
                <th className="font-body text-gray-400 text-xl text-center border-gray-400 w-full py-2 px-4 border-2 w-[50%]">
                  STRATEGY GENERATOR
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-darkBlue2">
                <td className="w-[50%] border-b-2 border-l-2 border-r-2 border-gray-400 py-2 px-4">
                  <p className="font-body text-gray-400 text-xl text-justify">ENHC</p>
                  <p className="font-body text-gray-400 text-xl text-justify">Soft17</p>
                  <p className="font-body text-gray-400 text-xl text-justify">Double</p>
                  <p className="font-body text-gray-400 text-xl text-justify">Depth</p>
                  <p className="font-body text-gray-400 text-xl text-justify">Initial Bankroll (IB)</p>
                </td>
                <td className="w-[50%] border-b-2 border-l-2 border-r-2 border-gray-400 py-2 px-4">
                  <p className="font-body text-gray-400 text-xl text-justify">Perfect Decision(PD)</p>
                  <p className="font-body text-gray-400 text-xl text-justify">Basic Strategy(BS)</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
  
        <div className="flex justify-center">
          <table className="w-[95%]">
            <tbody>
              <tr className="bg-darkBlue2">
                <td className="w-[50%]">
                  <div className="w-full border-2 py-2 px-4">
                    <p className="font-body text-gray-400 text-xl text-justify">Initial Bankroll (BI)</p>
                    <p className="font-body text-gray-400 text-xl text-justify">Actual Bankroll (AB)</p>
                    <p className="font-body text-gray-400 text-xl text-justify">True Count</p>
                  </div>
                </td>
                <td className="py-2 px-4 w-[50%]">
                  <div className="flex flex-col justify-center items-center">
                    <button className="flex shadow-xl justify-center items-center border border-gray-600 rounded-lg font-body font-bold text-sm py-1.5 px-2 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700">
                      CALCULATE
                    </button>
                    <h2 className="font-body text-gray-400 text-2xl text-center">OPTIMAL BET</h2>
                    <label className="font-body text-gray-400 text-2xl text-center">30%</label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  