import { FiDelete } from 'react-icons/fi';
import {cssButton} from "../../helpers/Mobile/cssClass";

function ZoneKeyboards({
  darkMode, handleTouchStart, disabledButton, StatePlayer, StateDealer, ClearHandsMobile,
  setStateDealer, setStatePlayer, setDisabledButton, ChangeStatePlayer, ChangeStateDealer, deleteCard,
  cola, setCola, setColaPlayer, setColaDealer, cartasPlayerTotal, 
  setCartasPlayerTotal, setCartasDealerTotal, setCartasTotal, cartasDealerTotal, cartasTotal, ModeCalculator
}) {
    return(
        <div className={darkMode && ModeCalculator === "1" ? "flex flex-row bg-white dark:bg-newBlue dark h-[60%] rounded-md pointer-events-none blur" 
        :
          (darkMode? "flex flex-row bg-white dark:bg-newBlue dark h-[60%] rounded-md" :"flex flex-row bg-white dark:bg-newBlue h-[60%] rounded-md")
        }>
          <div className="grid grid-cols-3 w-[78%] h-full">
          <button onTouchStart={(e) => handleTouchStart('2')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 2 </button>
          <button onTouchStart={(e) => handleTouchStart('3')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 3 </button>
          <button onTouchStart={(e) => handleTouchStart('4')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 4 </button>
          <button onTouchStart={(e) => handleTouchStart('5')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 5 </button>
          <button onTouchStart={(e) => handleTouchStart('6')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 6 </button>
          <button onTouchStart={(e) => handleTouchStart('7')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 7 </button>
          <button onTouchStart={(e) => handleTouchStart('8')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 8 </button>
          <button onTouchStart={(e) => handleTouchStart('9')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 9 </button>
          <button onTouchStart={(e) => handleTouchStart('10')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> 10 </button>
          <button onTouchStart={(e) => handleTouchStart('1')} disabled={disabledButton} 
            className={cssButton(StatePlayer, StateDealer)}> As </button>
          <button onTouchStart={() => {ClearHandsMobile()}} disabled={disabledButton} className="flex justify-center 
          items-center col-span-2 font-body text-black dark:text-white text-md bg-blue-400  dark:bg-blue-400 
          border border-gray-600 p-1.5 text-base text-center"> Clear Hands </button>
        </div>
        <div className='flex flex-col h-full w-[22%]'>
          <button disabled={disabledButton} onTouchStart={() => 
            {ChangeStatePlayer(setStateDealer, setStatePlayer, StatePlayer)}} className="flex justify-center
             items-center  font-body text-black dark:text-white text-md bg-green-500  border border-gray-600 
             p-1.5 text-base text-center h-full w-full"> 
          <p className='text-1xl'> Player Cards </p> 
          </button>
          <button disabled={disabledButton} onTouchStart={() => 
            {ChangeStateDealer(setStateDealer, setStatePlayer, StateDealer)}} className="flex justify-center 
            items-center  font-body text-black dark:text-white text-md bg-red-800  border border-gray-600 p-1.5 
            text-base text-center h-full w-full"> <p className='text-1xl'> Dealer Cards</p> 
          </button>
          <button disabled={disabledButton} onTouchStart={() => 
          {deleteCard(setDisabledButton, cola, setCola, setColaPlayer, setColaDealer, cartasPlayerTotal, 
            setCartasPlayerTotal, setCartasDealerTotal, setCartasTotal, cartasDealerTotal, cartasTotal)}} 
            className="flex justify-center items-center  font-body text-black dark:text-white text-md bg-red-900 
            border border-gray-600 p-1.5 text-base text-center rounded-br-md h-[25%] w-full"> 
            <FiDelete className="font-body font-black w-full h-full"/> 
          </button>
        </div>
        </div>

    )
}

export default ZoneKeyboards;