import { useAuth0 } from "@auth0/auth0-react";
import { BsHandThumbsUpFill } from 'react-icons/bs';
import { MdDashboardCustomize } from 'react-icons/md';
import { TfiTarget } from 'react-icons/tfi';
import newWindow from "../helpers/newWindow";
import Footer from "./Footer";
import Header from "./Header";
import Plans from "./Plans";
import calculadora_pc from "../content/calculadora-pc.png";
import calculadora_mobile from "../content/calculadora_mobile.svg"
import background from "../content/fondo_home.png";
import logo from "../content/logo1.svg";
import ampolleta from "../content/lamparita.png";
import barras from "../content/graph_icon.png";
import circulo from "../content/circular_icon.png";
import CelMenu from "./CelMenu";
import { useState, useEffect, useRef } from "react";
import Timer from "./Timer.jsx";
import Calculadora from "./Calculator";
import resetCalculator from "../helpers/resetCalculator";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import PhoneAlert from "./phoneAlert";

import { Helmet } from "react-helmet";
import { isMobile } from 'react-device-detect';
import HomeMobile from './Mobile/HomeMobile'
import { useLocation } from "react-router-dom";
import './FrontPage.css'

import { PayPalButtons } from "@paypal/react-paypal-js";
import PaypalPayment from "./PaypalPayment.jsx";

import checkExpirationDate from "../helpers/checkExpirationDate";

import useFetchUser from "../hooks/fetchUser.js";

function FrontPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [status, setStatus] = useState(queryParams.get('status'));
  const [showAlert, setShowAlert] = useState(false);
  //const [subscriptionPlan, setSubscriptionPlan] = useState(-1); //options: -1 (no plan)/ 0 (Perfect)/ 1 (HiLo)/ 2 (Full)
  // const { user, isLoading, error, getAccessTokenSilently } = useAuth0();
  //const [expirationDate, setExipirationDate] = useState(null); 
  //const [cachedUser, setCachedUser] = useState(null);
  const [cachedToken, setCachedToken] = useState(null);

  const {subscriptionPlan, expirationDate, cachedUser} = useFetchUser();

  // Management BJ credentials
  const clientId = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_ID;
  const client_secret = process.env.REACT_APP_MANAGEMENT_BJ_CLIENT_SECRET;
  const domain = process.env.REACT_APP_MANAGEMENT_BJ_DOMAIN;

  // Login
  const {
    user, 
    isLoading, 
    error, 
    getAccessTokenSilently,
    loginWithRedirect,
    isAuthenticated,
  } = useAuth0();
  /*
  useEffect(() => {
    const fetchData = (user) => {
      const managementApiOptions = {
        method: 'POST',
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({
          grant_type: 'client_credentials',
          client_id: clientId,
          client_secret: client_secret,
          audience: `https://${domain}/api/v2/`
        })
      };
  
      // console.log("managementApiOptions: ", managementApiOptions);
    
      fetch(`https://${domain}/oauth/token`, managementApiOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          const managementApiToken = data.access_token;
          // console.log("managementApiToken: ", managementApiToken);
  
          if (user) {
            console.log("Querying current user metadata");
            const queryMetadataOptions = {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${managementApiToken}`,
                    'Content-Type': 'application/json',
                },
            };
  
            fetch(`https://${domain}/api/v2/users/${user.sub}`, queryMetadataOptions)
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                }).then(userData => {

                  const planNumber = checkExpirationDate(userData.user_metadata);
                  console.log("planNumber: ", planNumber)
                  setSubscriptionPlan(planNumber);
                  setExipirationDate(userData.user_metadata.expiration_date);
                  // console.log("expirationDate: ", expirationDate)
                })
                .catch(error => {
                  console.error('There was a problem with the API call:', error);
                });
          } else {
            console.log("No user found");
          }
        });
    };
    fetchData(cachedUser);
  }, [cachedUser]);
  
  useEffect(() => {
    if (user!=undefined && user!=null) {
      setCachedUser(user); 
    }
  }, [user]);
  */
  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const newStatus = urlParams.get('status');
    if (newStatus) {
      setStatus(newStatus);
    }
  }, [window.location.search]);


  useEffect(() => {
    if (status === 'signedup') {
      
      setShowAlert(true);
  
      // Configurar un temporizador para ocultar la alerta después de 5 segundos
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 5000);
  
      // Limpiar el temporizador si el componente se desmonta
      return () => clearTimeout(timer);
    }
  }, [status]);

  useEffect(() => {
    // Agrega un event listener para el evento de scroll
    window.addEventListener('scroll', handleScroll);

    // Limpia el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Obtiene la posición actual de desplazamiento vertical
    const scrollY = window.scrollY;

    // Ajusta la posición de la alerta según la posición de desplazamiento
    const alertElement = document.querySelector('.confirmation-message');
    if (alertElement) {
      const headerHeight = 60; // Altura del header
      alertElement.style.top = `${headerHeight + scrollY}px`;
    }
  };  
  
   // query se ejecuta solamente al conocer el user

  if (!isMobile){
  return (

      <div className="flex flex-col w-full">


        <Helmet>
          <title>Blackjack Theorem</title>
          <meta name="description" content="Master the art of Blackjack and elevate your game to the next level with Blackjack Theorem." />
          <meta property="og:title" content="Blackjack Theorem" />
          <meta property="og:description" content="Blackjack Theorem helps you perfect your Blackjack game skills. Our intuitive tool guides you in every decision, allowing you to optimize your skills and knowledge. Elevate your game to the next level with us!" />
          <meta property="og:image" content="https://bjtheorem.com/static/media/logo.c5df72f6a84df8674398eb65331bb53.png" />
          <meta property="og:url" content="https://bjtheorem.com" />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Blackjack Theorem" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:image:width" content="305" />
          <meta property="og:image:height" content="295" />
        </Helmet>
        <Header subscriptionPlan={subscriptionPlan} expirationDate={expirationDate} newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu}/>


        <CelMenu showMenu={showMenu} subscriptionPlan={subscriptionPlan} expirationDate={expirationDate}/>
        {showAlert && (
          <div className="confirmation-message" style={{ marginTop: '30px' }}>
            <p>You are now signed up and can access all the functionalities of Blackjack Theorem.</p>
          </div>
        )}
        <div className="hidden md:flex w-full items-center justify-around  md:mt-20 pr-40 h-[75vh]" style={{backgroundImage: `url(${background})`, backgroundRepeat: "no-repeat", backgroundSize: "100% 100%"}}>
          <div className="flex w-1/2">
            <img src={logo} alt="logo_bj" className='h-[80vh]'/>
          </div>
          
          <div className="flex flex-col justify-end items-end w-1/2 h-full pb-[8vh]">
            {!isAuthenticated 
              ? <div className="flex w-full items-end justify-end  p-3"><button onClick={() => loginWithRedirect()} className="flex py-2 px-16 justify-center items-center rounded-md bg-red-500 text-white text-3xl font-title font-bold hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-0.5">GET STARTED NOW!</button></div> 
              : <button className="flex py-4 px-16 w-1/2 justify-center items-center rounded-md bg-red-500 text-white text-3xl font-title font-bold hover:cursor-pointer hover:bg-red-600 duration-500 hover:-translate-y-1" onClick={() => newWindow(subscriptionPlan)}>TRY FULL VERSION</button>
            }
          </div>
        </div>
        <div className={showMenu ? "flex flex-col w-full items-center bg-darkBlue2 py-52 md:py-20" : "flex flex-col w-full items-center bg-darkBlue2 py-20"}>
          <div className="flex justify-center font-title font-medium text-white text-4xl font-bold pb-6 w-3/4">
            Blackjack Theorem
          </div> 
          <div className="flex justify-center items-center w-full px-20">
          <div className="flex flex-col bg-newBlue rounded-lg p-4 gap-3 w-[60%] h-fit">
            <p className="font-body text-justify text-gray-400 md:text-lg">
              We are Blackjack Theorem, a team of researchers with backgrounds in mathematics, financial theory and computer science. We have developed an in-depth analysis of the popular game 21 Blackjack, exploring the theoretical limits of the advantage for a player with perfect information about the game. We asked ourselves what is the best strategy that can be followed by a player who knows at all times the exact composition of the deck. Modeling the problem as a Markov Decision Process, we obtained the best strategies using dynamic programming algorithms, Expected Utility Theory, and reinforcement neural networks.
            </p>
            <p className="font-body text-justify text-gray-400 md:text-lg">
              As a result of the study, we have developed a calculator that computes the optimal bet and decision in real time, depending on the configuration of the rules of the game, the count method, the decision method, the number of rounds that make up the betting session, and the optimality criterion defined by the player’s individual preferences. In the <Link to={"/simulations"} className="text-red-500 underline italic">SIMULATIONS</Link> section we offer a tool that allows you to visualize in a detailed and graphical way the expected Bankroll Distributions, depending on the defined strategy and the configuration of the game in general.
            </p>
            <p className="font-body text-justify text-gray-400 md:text-lg">
              You can compute the best decision for free at any time. If you <Link to={"/subscriptions"} className="text-red-500 underline italic">SUBSCRIBE</Link> (currently free beta version), you will be able to calculate the best bet for any configuration of rules and strategy, according to the counting method you select. In the <Link to={"/research"} className="text-red-500 underline italic">RESEARCH</Link> section you can learn about the formal theory on which our research is based. If you have any questions, go to the <Link to={"/blog"} className="text-red-500 underline italic">BLOG & FAQ</Link> section, where you will find answers to common questions and information of interest.
            </p>
          </div>

            <div className="flex flex-col items-center w-[40%]">
              <img src={calculadora_mobile} alt="Calculator on mobile" className="w-[450px] mb-0"/>
              <img src={calculadora_pc} alt="Calculator on pc" className="w-[400px]"/>
            </div>
          </div>
          <div className="flex justify-center font-body text-white italic text-2xl border-b-2 border-gray-600 pb-6 w-3/4 py-10">
            "Master the art of Blackjack and elevate your game to the next level."
          </div> 
          <div className="flex px-20 py-16">
            <div className="flex justify-center items-start w-1/2" id="calc">
              <div className=" flex flex-col w-[425px]">
                <div className="flex items-center justify-center p-2">
                  {isAuthenticated
                  ? <label className="text-justify mt-6 font-title font-medium text-menuText text-xl text-red-500">FULL VERSION</label>
                  : <label className="text-justify mt-6 font-title font-medium text-menuText text-xl text-red-500">DEMO (Best bet randomly generated)</label> 
                  }
                </div>
                <Calculadora 
                  subscriptionPlan={
                    isAuthenticated
                      ? 2
                      : -1
                  } 
                  isAuthenticated={isAuthenticated}
                />
              </div>	
            </div>
            <div className="flex justify-center w-1/2 max-h-[600px] overflow-auto px-5 bg-darkBlue3 rounded-xl" id="instrucciones">
  <div className="flex justify-center">
    <div className="flex flex-col items-center">
      <p className="mt-6 font-title font-medium text-menuText text-xl text-red-500">
        STEP BY STEP GUIDE
      </p>
      <div className="mx-12 mt-2 text-justify">
        <div className="font-body text-menuText text-xl py-2">
          1. Go to <span className="font-medium">Settings</span> and adjust the game rules: ENHC, Soft17, Double, and Depth (deck penetration).
        </div>
        <div className="font-body text-menuText text-xl py-2">
          2. In the middle section of <span className="font-medium">Settings</span>, configure your strategy mechanisms and your initial bankroll. Choose the counting method: Perfect Count or Hi-Lo True Count, and the decision method: Perfect Decision or Basic Strategy.
        </div>
        <div className="font-body text-menuText text-xl py-2">
          3. At the bottom of <span className="font-medium">Settings</span>, define the parameters that determine the strategy. Select the number of rounds "H" for the betting session, and the player's risk profile parameter "R" (it is recommended to visit the <Link to="/simulations" className="text-red-500 underline italic">SIMULATIONS</Link> section for a better understanding of the strategies).
        </div>
        <div className="font-body text-menuText text-xl py-2">
            4. Go to the <span className="font-medium">Calculator</span>.
        </div>
        <div className="font-body text-menuText text-xl py-2">
          5. If the counting method is <span className="underline">Perfect Count</span>:
          <div className="pl-5 mt-2">
            <p className="font-body text-menuText text-xl py-2">5.1.1. Starting with a full deck, enter all the cards played on the table using the keyboard or the clickable panel.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.2. After each round, press the "Best Bet" button to get the optimal bet for the next round. This value is 0 approximately 80% of the time and will turn positive when the deck is profitable to bet on (positive EV).</p>
            <p className="font-body text-menuText text-xl py-2">5.1.3. If the "Best Bet" is positive, bet that amount in the game, optimized according to the strategy defined in <span className="font-medium">Settings</span>.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.4. When registering your hand, press the "Q" key while entering your cards. These will be recorded in the count and added to your "Player Hand".</p>
            <p className="font-body text-menuText text-xl py-2">5.1.5. For the dealer's card, press the "E" key while entering the card. This card will be added to the count and the "Dealer Hand".</p>
            <p className="font-body text-menuText text-xl py-2">5.1.6. The best decision to play your hand will be displayed in green automatically, based on the decision method set.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.7. Follow the decision indicated by the calculator when playing your hand.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.8. If more cards are needed, enter them using the "Q" key to update the best decision.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.9. Ensure all cards played are recorded in the general count at all times.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.10. After the round, update your Current Bankroll using the "+" or "-" keys. Keep it as close as possible to your actual bankroll.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.11. If you participated in the round, update the Rounds Played with the "+" key or manually.</p>
            <p className="font-body text-menuText text-xl py-2">5.1.12. Check "Best Bet" before the next round starts and bet if necessary. The "X" key automatically executes both "Clear Hands" and "Best Bet".</p>
            <p className="font-body text-menuText text-xl py-2">5.1.13. After a full game, reset the deck to its full state by pressing "Reset Count" and adjust the number of Rounds Played.</p>
          </div>
        </div>
        <div className="font-body text-menuText text-xl py-2">
          5.2 If the counting method is <span className="underline">Hi-Lo True Count</span>:
          <div className="pl-5 mt-2">
            <p className="font-body text-menuText text-xl py-2">5.2.1. A table will display the best bet, optimized according to the strategy defined in <span className="font-medium">Settings</span>.</p>
            <p className="font-body text-menuText text-xl py-2">5.2.2. The best bet is determined by three variables: your current bankroll (horizontal axis of the table), the True Count (vertical axis), and the number of rounds bet so far (slider).</p>
            <p className="font-body text-menuText text-xl py-2">5.2.3. Follow the strategy by betting the amount indicated by the table at each moment, based on the values of the three variables mentioned above.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>







				</div>
        <div className="flex flex-col w-full justify-center items-center py-10 gap-5">
          <h2 className="font-title text-white text-3xl font-bold">PRESENTATION VIDEO</h2>
          <iframe 
            className="w-2/3 h-[700px]" 
            src="https://www.youtube.com/embed/J5wolPYRUKI?si=guj0ry3RO8X8q2p3" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen>  
          </iframe>
        </div>
          {/*<div className="flex flex-col md:flex-row justify-around  px-5 md:px-20 py-4">
            <div className="flex flex-col items-center justify-center md:w-1/2 gap-6">
              <img src={calculadora} alt="calculator on pc" className="w-5/6"/>
              <a href={'/tutorial'} className="hidden md:block font-body text-center text-white text-2xl font-bold underline hover:text-red-500">Learn to use it NOW!</a>

            </div>
            <div className="flex flex-col items-center place-self-end md:w-3/5 gap-3 p-3">
            <BsHandThumbsUpFill className="text-4xl text-white"/>
              <p className="font-title font-medium text-center text-white text-md text-xl md:text-3xl">USABILITY</p>
              <p className="font-body text-center text-gray-400 md:text-xl">It offers an exceptional user experience thanks to its intuitive interface 
              and the possibility of using the keyboard for ease and speed.
              </p>
            </div>
            <div className="flex flex-col items-center place-self-start md:w-3/5 gap-3 p-3">
              <MdDashboardCustomize className="text-4xl text-white"/>
              <p className="font-title font-medium text-center text-white text-md text-xl md:text-3xl">CUSTOMIZABLE</p>
              <p className="font-body text-center text-gray-400 md:text-xl">Customize your gaming experience according to your preferences. Adjust the 
              level of risk you wish to bet with, adapting it to your style of play and your objectives. Also, 
              modify the design and rules of the game to your liking.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-newBlue py-20 gap-10 px-3">
        <div className="flex items-center md:justify-center justify-start w-full">
          <img src={ampolleta} alt="light" className="h-32 md:h-48"/>
          <div className="md:w-1/2">
            <p className="font-body text-justify text-gray-400 md:text-xl">Visit our tutorials section to learn how to use the betting and decision 
            calculator effectively. Find step-by-step guides that will teach you how to get the most out 
            of BlackJack Theorem.</p>
          </div>
        </div>
        <div className="flex items-center md:justify-center justify-end ">
          <div className="md:w-1/2">
            <p className="font-body text-justify text-gray-400 md:text-xl">Explore how our strategies impact your Blackjack profitability. In this section, 
            you'll find clear graphs and charts showing the effect of risk levels on your profitability. Learn 
            how to make informed decisions and tailor your playing style to your goals and preferences.</p>
          </div>
          <img src={barras} alt="bar_graph" className="h-32 md:h-48"/>
          
        </div>
        <div className="flex items-center md:justify-center justify-end gap-5">
          <img src={circulo} alt="circle_logo" className="h-24 md:h-48"/>
          <div className="md:w-1/2">
            <p className="font-body text-justify text-gray-400 md:text-xl">Learn the algorithms and strategies we employ to calculate the optimal bets 
            and best decisions in the game of Blackjack. Get a better understanding of the decisions in the 
            game and how to improve your skills.</p>
          </div>
        
          <Plans/>*/}

        {/* <PayPalButtons>
        </PayPalButtons> */}
        {/* <PaypalPayment planId={0}/> */}
        
        </div>

        <Footer />
        
      </div> 
  );
  } else {
    return(
      
      <HomeMobile loginWithRedirect={loginWithRedirect} isAuthenticated={isAuthenticated} subscriptionPlan={subscriptionPlan} expirationDate={expirationDate}/>
    
    )

    
  }


};


export default FrontPage;
