import Chart from 'chart.js/auto';

var math = require('mathjs')
var LogNormal = require( '@stdlib/stats-base-dists-lognormal' ).LogNormal;
var chart;
const mus0 = 0.00001674124157786;
const sigmas0 = 0.00578799239754929;
const mus1 = 0.00007759924789;
const sigmas1 = 0.01223052406320;
const mus2 = 0.000011789097503;
const sigmas2 = 0.005597157753689;

function plot() {

	var tipo_de_sim = 0
	var sigma = 0
	var mu = 0
	var datos;
	var riesgo = 1;

  //recopilar inputs
  var n_manos = document.getElementById('n_manos').value
  document.getElementById('n_manos_v').innerHTML = document.getElementById('n_manos').value

	//tipo de funcion a mostrar
	var densidad = document.getElementById('densidad').checked

	/*//tipo de simulacion
  if(document.getElementById('s2').checked){
    tipo_de_sim = 1
  }
  else if(document.getElementById('s3').checked){
    tipo_de_sim = 2
  }*/
	
	if (tipo_de_sim === 0) {
		sigma = math.sqrt(n_manos) * sigmas0;
		mu = n_manos * mus0;
	}
	else if (tipo_de_sim === 1) {
		sigma = math.sqrt(n_manos) * sigmas1;
		mu = n_manos * mus1;
	}
	else if (tipo_de_sim === 2) {
		sigma = math.sqrt(n_manos) * sigmas2;
		mu = n_manos * mus2;
	}

	//nivel de riesgo para la funcion
  var risk = {
		1: 1,
		2: 4,
		3: 7,
    4: 10,
    5: 15,
	}
	document.getElementById("densidad").disabled = false;
	/*
	if(document.getElementById('r2').checked){
    riesgo = 2
  }
  else if(document.getElementById('r3').checked){
    riesgo = 3
  }
	else if(document.getElementById('r4').checked){
		riesgo = 4
  }
	else if(document.getElementById('r5').checked){
    riesgo = 5
  }
	*/
	if(densidad && riesgo > 1){
		densidad = false;
		document.getElementById('acumulada').checked = true;
		document.getElementById('densidad').checked = false;
	}
	else if(densidad && riesgo === 1){
		document.getElementById('acumulada').checked = false;	
	}
  //creacion de la funcion
	var lognorm_dist = new LogNormal(mu, sigma);
  var x = math.range(0, 60, 0.05).toArray();
	const pdf = x.map((val) => {
		if(lognorm_dist.pdf((val + risk[riesgo] - 1)/risk[riesgo]).toFixed(3) > 0.001){
			return lognorm_dist.pdf((val + risk[riesgo] - 1)/risk[riesgo]);
		}
	});
	const cdf = x.map((val) => {
		if(lognorm_dist.cdf(((val + risk[riesgo] - 1)/risk[riesgo]).toFixed(2)) < 0.99){
			return lognorm_dist.cdf((val + risk[riesgo] - 1)/risk[riesgo]);
		}
	})
	
	if(densidad){
		datos = pdf.filter(x => x !== undefined);
	}
	else{
		datos = cdf.filter(x => x !== undefined);
	}
	x = x.slice(0, datos.length)
	x = x.map((val) => val.toFixed(2))
  //cambiar valores de la tabla
  document.getElementById('t1').innerHTML = `${(lognorm_dist.cdf((0.25 + risk[riesgo] - 1)/risk[riesgo])*100).toFixed(2)}%`
  document.getElementById('t2').innerHTML = `${(lognorm_dist.cdf((0.5 + risk[riesgo] - 1)/risk[riesgo])*100).toFixed(2)}%`
  document.getElementById('t3').innerHTML = `${(lognorm_dist.cdf((0.75 + risk[riesgo] - 1)/risk[riesgo])*100).toFixed(2)}%`
  document.getElementById('t4').innerHTML = `${((1 - lognorm_dist.cdf((1 + risk[riesgo] - 1)/risk[riesgo]))*100).toFixed(2)}%`
  document.getElementById('t5').innerHTML = `${((1 - lognorm_dist.cdf((1.25 + risk[riesgo] - 1)/risk[riesgo]))*100).toFixed(2)}%`
  document.getElementById('t6').innerHTML = `${((1 - lognorm_dist.cdf((1.5 + risk[riesgo] - 1)/risk[riesgo]))*100).toFixed(2)}%`
  document.getElementById('t7').innerHTML = `${((1 - lognorm_dist.cdf((1.75 + risk[riesgo] - 1)/risk[riesgo]))*100).toFixed(2)}%`


	if(chart){chart.destroy();}

	chart = new Chart("myChart", {
		type: "line",
		data: {
			labels: x,
			datasets: [{
				backgroundColor: "rgba(239,68,68,255)",
				borderColor: "rgba(239,68,68,255)",
				data: datos
			}]
		},
		options: {
			plugins: {
				legend: {
					display: false,
       	} 
			}, 
			scales: {
        y: {
					title: {
						display: true,
						text: 'Prob(Bankroll<=B)', // Etiqueta para el eje y
						color: 'white'
					},
          ticks: {
            color: 'white'
          },
          grid: {
            color: 'gray'
          }
        },
        x: {
					title: {
						display: true,
						text: 'Bankroll "B"', // Etiqueta para el eje y
						color: 'white'
					},
          ticks: {
            color: 'white'
          },
          grid: {
            color: 'gray'
          }
        }
      } 
    }
	});
    
}

export default plot;