import logo from "../content/logo1.png";

function Foto(){
  return (
    <div>
      <img alt="logo" src={logo}></img>
    </div>
  )
}

export default Foto;