
function getData( calculatorMode ){
	
	var s17 = document.getElementById('s17').innerHTML;
	var decks = document.getElementById('decks').innerHTML;
	var double = document.getElementById('double').innerHTML;
	var enhc = document.getElementById('enhc').innerHTML;
	var depth = document.getElementById('depth').innerHTML;
	var hands = document.getElementById('hands').innerHTML;
	var returnTarget = document.getElementById('returnTarget').innerHTML;
	var ror = 1//document.getElementById('ror').innerHTML;
	var percentile = 1//document.getElementById('percentile').innerHTML;
	var alphaValue = document.getElementById('alphaValue').innerHTML;
	var k_value = document.getElementById('k_value').innerHTML;
	var c_value = document.getElementById('c_value').innerHTML;
	var countingMethod = document.getElementById('countingMethod').innerHTML;
	var decisionMethod = document.getElementById('decisionMethod').innerHTML;
	var desv_cond = 0//document.getElementById('desv_cond').innerHTML;

	// variables nuevas: 
	var surrender = document.getElementById('surrender').innerHTML;
	var BJpays = document.getElementById('BJpays').innerHTML;
	var DAS = document.getElementById('DAS').innerHTML;
	var roundsPlayed = document.getElementById('roundsPlayed').innerHTML;
	var split = document.getElementById('split').innerHTML;
	var droppedSlideBarHands = document.getElementById('droppedSlideBarHands').innerHTML;
	

	try{
		var sb = []
		var player = []
		var crupier = []
		var hl_count = 0
		var pa = parseInt(document.getElementById('money').value.split('.').join(''))
	
		if(calculatorMode === "fcc"){
			for (var step = 1; step < 10; step++) {
				sb.push(document.getElementById(`value_c_${step}`).innerHTML)
			}
			sb.push(document.getElementById(`value_c_${0}`).innerHTML)  
		} else {
			hl_count = document.getElementById('hl_count').value
		}
		const jugador = document.getElementById("cartas_jugador").childNodes
		const dealer= document.getElementById("cartas_dealer").childNodes
		for (const key in jugador) {
			if(jugador[key].innerHTML){
				if(jugador[key].innerHTML === 'As'){
					player.push('1');
				} else {
					player.push(jugador[key].innerHTML);
				}	
			}
		}
		for (const key in dealer) {
			if(dealer[key].innerHTML){
				if(dealer[key].innerHTML === 'As'){
					crupier.push('1');
				} else {
					crupier.push(dealer[key].innerHTML);
				}
			}
		}
	} catch {}
	return {
		"array": sb, 
		"jugador": player, 
		"croupier": crupier, 
		"pa": pa, 
		"s17": s17, 
		"decks": decks, 
		"double": double, 
		"enhc": enhc, 
		"hl_count": hl_count,
		"depth": depth,
		"decks": decks,
		"hands": hands,
		"ror": ror,
		"percentile": percentile,
		"desvcond": desv_cond,
		"alphaValue": alphaValue,
		"k_value": k_value,
		"c_value": c_value,
		"CM": countingMethod,
		"DM": decisionMethod,
		"returnTarget": returnTarget,
		"nHands":roundsPlayed,
		"droppedSlideBarHands": droppedSlideBarHands


	}
	// descomentar este return para recibir variables nuevas: 
	// (y comentar el anterior) 

	// return {
	// 	"array": sb, 
	// 	"jugador": player, 
	// 	"croupier": crupier, 
	// 	"pa": pa, 
	// 	"s17": s17, 
	// 	"decks": decks, 
	// 	"double": double, 
	// 	"enhc": enhc, 
	// 	"hl_count": hl_count,
	// 	"depth": depth,
	// 	"decks": decks,
	// 	"hands": hands,
	// 	"ror": ror,
	// 	"percentile": percentile,
	// 	"desvcond": desv_cond,
	// 	"alphaValue": alphaValue,
	// 	"k_value": k_value,
	// 	"c_value": c_value,
	// 	"CM": countingMethod,
	// 	"DM": decisionMethod, 
	// 	"surrender": surrender,
	// 	"BJpays": BJpays,
	// 	"DAS": DAS,
	// 	"roundsPlayed": roundsPlayed,
	// 	"split": split,
	// 	"droppedSlideBarHands": droppedSlideBarHands
	// }
}

export default getData;