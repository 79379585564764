import { useEffect, useState } from "react";
import renderBlackjackApp  from "./CalculatorMobileHTML";
import Modal from 'react-modal';
import ReactModal from 'react-modal';
import calcularPuntaje from "../../helpers/Mobile/CalcularPuntajeMobile"
import { enqueue, enqueuePlayer, enqueueDealer } from '../../helpers/Mobile/queueFunctions';
import BestDecisionMobile from "../../helpers/Mobile/BestDecisionMobile";
import GetBestBet from "../../helpers/Mobile/GetBestBetMobile";
import {changeBackRoll, manejoFocus} from "../../helpers/Mobile/changeBackRoll";
import { useAuth0 } from "@auth0/auth0-react";
import deleteCard from "../../helpers/Mobile/DeleteCard"
import {ChangeStatePlayer, ChangeStateDealer} from "../../helpers/Mobile/ChangeState"

ReactModal.setAppElement('#root');
Modal.setAppElement('#root');

function CalculadoraMobil({ subscriptionPlan, isAuthenticated }) {

  //const { loginWithRedirect, isAuthenticated, user } = useAuth0();
//-------------- VARIABLES -------------//
  const [showPercentaje, setShowPercentaje] = useState(true)
  const [darkMode, setDarkMode] = useState(true)
  const [s17, setS17] = useState(false)
  const [enhc, setEnhc] = useState(true)

  const [risk, setRisk] = useState("1")
  const [double, setDouble] = useState("0")
  const [decks, setDecks] = useState("8")

  const [changerisk, setChangeRisk] = useState("1")
  const [changeDecks, setChangeDecks] = useState("8")

  const [inputValue, setInputValue] = useState(100);
  const [Edge, setEdge] = useState(0);
  const [apuesta, setApuesta] = useState(0);
  const [BestDecision, setBestDecision] = useState(undefined);

  const[cartas, setCartas] = useState({
    "1": 32,
    "2": 32,
    "3": 32,
    "4": 32,
    "5": 32,
    "6": 32,
    "7": 32,
    "8": 32,
    "9": 32,
    "10": 128,
  })
  //Colas con max
  const[cola, setCola] = useState(new Array(9));
  const[colaPlayer, setColaPlayer] = useState(new Array(3));
  const[colaDealer, setColaDealer] = useState(new Array(3));

  //Colas sin maximo
  const[cartasTotal, setCartasTotal] = useState([])
  const[cartasDealerTotal, setCartasDealerTotal] = useState([])
  const[cartasPlayerTotal, setCartasPlayerTotal] = useState([])

  const[StatePlayer, setStatePlayer] = useState(false)
  const[StateDealer, setStateDealer] = useState(false)

  const[pa, setPa] = useState(100)
  const[p0, setP0] = useState(100)

  const[sumaPlayer, setSumaPlayer] = useState(0)
  const[sumaDealer, setSumaDealer] = useState(0)

  const[disabledButton, setDisabledButton] = useState(false)
  
  const [modalIsOpen, setModalIsOpen] = useState(true); // abre la pestaña de settings
  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const [prob, setProb] = useState({
    "prob_hit": 0,
    "prob_stand": 0, 
    "prob_split": 0, 
    "prob_double": 0
  });
  const [showMenu, setShowMenu] = useState(false);

  const [cargandoDecision, setCargandoDecision] = useState(false);
  const [cargandoBet, setCargandoBet] = useState(false);

  //const [countMethod, setCountMethod] = useState("0");
  //const [decisionMethod, setDecisionMethod] = useState("0");

  const [countMethod, setCountMethod] = useState("pc");
  const [decisionMethod, setDecisionMethod] = useState("pd");

  const [hands, setHands] = useState("100");
  const [returnTarget, setreturnTarget] = useState("1");
  const [percentile, setPercentile] = useState("0.5");
  const [desv_cond, setDesvCond] = useState(0); 

  const [EV, setEV] = useState("0");
  const [std, setStd] = useState("0");

  const [ModeCalculator, setModeCalculator] = useState("fcc");
  const [alpha, setAlpha] = useState(0);
  const [k_value, setKValue] = useState(1);
  const [c_value, setCValue] = useState(1);
  const [hl_count, setHL_Count] = useState(0.0);
  const [depth, setDepth] = useState(0.5);

  const [surrender, setSurrender] = useState('notAllowed'); //options: notAllowed / any / anyExceptAce
  const [BJpays, setBJpays] = useState('3to2');  //options: 3to2 
  const [split210, setSplit210] = useState(1); 
  const [DAS, setDAS] = useState(false); 
  const [splitAces, setSplitAces] = useState(false);
  const [BI, setBI] = useState(100); //es initial bankroll

  const [roundsPlayed, setRoundsPlayed] = useState(0); 

  //---------------------useEffect---------------------///
  useEffect(() => {
    if (!isAuthenticated){
      //loginWithRedirect();
    }
  }, [])

  useEffect(() => {
    console.log(colaPlayer, colaDealer)
  }, [colaPlayer, colaDealer])

  useEffect(() => {
    BestDecisionMobile(setDisabledButton, cartasPlayerTotal, cartasDealerTotal, sumaPlayer, sumaDealer, 
      cartas, s17, decks, double, enhc, setBestDecision, setProb, setCargandoDecision, countMethod, decisionMethod, 
      hands, returnTarget, percentile, hl_count, depth, alpha, k_value,c_value);
  }, [sumaPlayer, sumaDealer])

  useEffect(() => {
    if (cartasPlayerTotal.length > 0){
      const cartasPlayerTotal2 = cartasPlayerTotal.map(subarreglo => subarreglo[0]);
      const puntaje = calcularPuntaje(cartasPlayerTotal2);
      setSumaPlayer(puntaje)
    }
  }, [cartasPlayerTotal])

  useEffect(() => {
    if (cartasDealerTotal.length > 0){
      const cartasDealerTotal2 = cartasDealerTotal.map(subarreglo => subarreglo[0]);
      const puntaje = calcularPuntaje(cartasDealerTotal2);
      setSumaDealer(puntaje)
    }
  }, [cartasDealerTotal])

  useEffect(() => {
    if (!modalIsOpen){
      if (decks != changeDecks){
        setChangeDecks(decks);
        ResetCountMobile();
      }
      if (risk != changerisk){
        setChangeRisk(risk);
        setP0(pa);
      }
    }
  }, [modalIsOpen])

  //-------------Funciones-----------------///
  ///-Agregar Carta-///
  const handleTouchStart = (i) => {
    setDisabledButton(true)
    // Acción que deseas ejecutar cuando se toca el botón
    setStateDealer(false);
    setStatePlayer(false);
    if (cartas[i] > 0){
        if (StatePlayer) {
          const cartasPlayerTotalAux = cartasPlayerTotal.map(subarreglo => subarreglo[0]);
          const aux = [...cartasPlayerTotalAux, i]
          const suma = calcularPuntaje(aux)
          console.log(suma)
          if (suma <= 21 || suma === "As"){

              enqueuePlayer([i, "Green"], setColaPlayer, setCartasPlayerTotal)
              enqueue([i, "Green"], setCola, setCartasTotal)

            discountCard(i)
          }

        } else if (StateDealer) {
          if (colaDealer.every(elemento => elemento === "")){

              enqueueDealer([i, "Red"], setColaDealer, setCartasDealerTotal)
              enqueue([i, "Red"], setCola, setCartasTotal)

            discountCard(i)
          }
        } else {
          enqueue([i, "Gray"], setCola, setCartasTotal)
          discountCard(i)
        }
      setDisabledButton(false)
  }
  };
  ///-DescontarCarta-///
  const discountCard = (i) => {
    const nuevoValor = cartas[i] - 1
    setCartas(prevCartas => ({
      ...prevCartas, // Copia los elementos existentes en el diccionario
      [i]: nuevoValor // Actualiza el valor del elemento especificado por la clave
    }));
  }



  // Limpiar mano
  const ClearHandsMobile = () => {
    setDisabledButton(true);
    setStateDealer(false);
    setStatePlayer(false);
    setCartasPlayerTotal([]);
    setCartasDealerTotal([]);
    setSumaPlayer(0);
    setSumaDealer(0);
    setColaPlayer(new Array(3));
    setColaDealer(new Array(3));
    setBestDecision(undefined);
    setDisabledButton(false);
  }

 // Volver al inicio
  const ResetCountMobile = () => {
    setDisabledButton(true)
    setCartas({
      "1": parseInt(decks) * 4,
      "2": parseInt(decks) * 4,
      "3": parseInt(decks) * 4,
      "4": parseInt(decks) * 4,
      "5": parseInt(decks) * 4,
      "6": parseInt(decks) * 4,
      "7": parseInt(decks) * 4,
      "8": parseInt(decks) * 4,
      "9": parseInt(decks) * 4,
      "10": parseInt(decks) * 16,
    })
    setStateDealer(false);
    setStatePlayer(false);
    setCola(new Array(9));
    setCartasTotal([]);
    setCartasDealerTotal([]);
    setCartasPlayerTotal([]);
    setColaPlayer(new Array(3));
    setColaDealer(new Array(3));
    setSumaPlayer(0);
    setSumaDealer(0);
    setInputValue(BI);
    setApuesta(0);
    setEdge(0);
    setBestDecision(undefined);
    setDisabledButton(false);
    setRoundsPlayed(0);
  }

  const handleInputChange = (event) => {
    setInputValue(parseInt(event.target.value));
  };

  const changeStrategy = () => {
    const cartasFetch = Object.values(cartas);
    const newDecks = "" + (decks);
    const newDouble = "" + (double);
    const newP0 = "" + (p0);
    const newPa = "" + (pa);
    const newRisk = "" + (risk);
    let newEnhc;
    let newS17;
    if (enhc){
      newEnhc = "0";
    } else {
      newEnhc = "1";
    }
    if (s17){
      newS17 = "0";
    } else {
      newS17 = "1";
    }
    fetch(`${process.env.REACT_APP_BACKEND_URL}/apply_strategy`,{
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "comando": "apuesta",
        "array": cartasFetch,
        "risk": newRisk,
        "p0": newP0,
        "pa": newPa,
        "s17": newS17,
        "decks": newDecks,
        "double": newDouble,
        "enhc": newEnhc,
        "hl_count": hl_count,
        "depth": depth,
        "hands": hands,
        "ror": returnTarget,
        "percentile": percentile,
        "desvcond": desv_cond,
        "alphaValue": alpha,
        "k_value": k_value,
        "c_value": c_value,
        "CM": countMethod,
        "DM": decisionMethod,

        
      })
    }).then(res => res.json()).then(res => {setAlpha(res.alfa); setKValue(res.k);setCValue(res.c)})
  }

  const blackjackAppOutput = renderBlackjackApp({subscriptionPlan: 2, BI, setBI, roundsPlayed, setRoundsPlayed, surrender, setSurrender, BJpays, setBJpays, split210, setSplit210, DAS, setDAS, splitAces, setSplitAces, darkMode, setDarkMode, showMenu, setShowMenu, modalIsOpen, setModalIsOpen, 
    modalIsOpen2, setModalIsOpen2, showPercentaje, setShowPercentaje, s17, setS17, enhc, setEnhc, double, 
    setDouble, decks, setDecks, risk, setRisk, cola, colaPlayer, colaDealer, StatePlayer, 
    StateDealer, pa, p0, sumaPlayer, sumaDealer, disabledButton, prob, ResetCountMobile, apuesta, 
    handleTouchStart, setStatePlayer, setStateDealer, deleteCard, cartas, ClearHandsMobile, 
    GetBestBet, changeBackRoll, handleInputChange, inputValue, manejoFocus, BestDecision, 
    ChangeStatePlayer, ChangeStateDealer, setApuesta, setEdge, setDisabledButton, setInputValue, setP0, setPa,
    cargandoDecision, cargandoBet, setCargandoBet, countMethod, setCountMethod, decisionMethod, setDecisionMethod,
    hands, setHands, returnTarget, setreturnTarget, percentile, setPercentile,desv_cond, setDesvCond, setColaPlayer, setColaDealer, 
    cartasPlayerTotal, setCartasPlayerTotal, setCartasDealerTotal, setCartasTotal, cartasDealerTotal, cartasTotal, setCola, 
    changeStrategy, EV, setEV, std, setStd, ModeCalculator, setModeCalculator, hl_count, setHL_Count,
    depth, setDepth, alpha, k_value, c_value, 
  })
  

  return (
    
  <div>
    <div>
      <label className="hidden" id="mode">{ModeCalculator}</label>
      {/* opciones de la calculadora van ocultos */}
      <label className="hidden" id="p0">{BI}</label>
      <label className="hidden" id="depth">{depth}</label>
      <label className="hidden" id="double">{double}</label>
      <label className="hidden" id="decks">{decks}</label>
      {enhc ? <label className="hidden" id="enhc">0</label> : <label className="hidden" id="enhc">1</label>}
      {s17 ? <label className="hidden" id="s17">0</label> : <label className="hidden" id="s17">1</label>}
      <label id="hands" className="hidden">{hands}</label>
      <label id="k_value" className="hidden">{k_value}</label>
      <label id="c_value" className="hidden">{c_value}</label>
      <label id="countingMethod" className="hidden">{countMethod}</label>
      <label id="decisionMethod" className="hidden">{decisionMethod}</label>
      {/* variables nuevas */}
      <label id="surrender" className="hidden">{surrender}</label>
      <label id="BJpays" className="hidden">{BJpays}</label>
      <label id="DAS" className="hidden">{DAS}</label>
      <label id="roundsPlayed" className="hidden">{roundsPlayed}</label>
      <label id="returnTarget" className="hidden">{1}</label>
      <label id="alphaValue" className="hidden">{1}</label>
      <label id="split" className="hidden">{1}</label>
      {blackjackAppOutput}
    </div>
  </div>
  )
}
export default CalculadoraMobil;