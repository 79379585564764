import cambioMoneda from "./cambioMoneda"

function editBankRoll(opcion, setCurrentBankroll, current_bankroll, initial_bankroll){

    if(current_bankroll >= 0 && initial_bankroll){
        if(opcion === '+'){
            setCurrentBankroll(parseInt(parseInt(current_bankroll) + (0.05 * parseInt(initial_bankroll))));
        }
        
        else if(opcion === '-'){
            if(parseInt(current_bankroll) - (0.05 * parseInt(initial_bankroll)) >= 0){
                setCurrentBankroll(parseInt(parseInt(current_bankroll) - (0.05 * parseInt(initial_bankroll))));
            }
            else{
                setCurrentBankroll(0);
            }
        }
    }
    
}

export default editBankRoll;