
export function calcularPuntaje(mano) {
  let puntaje = 0;
  let ases = 0;

  for(let i=0; i<mano.length; i++) {
		if(mano[i] === 1) {
				ases += 1;
				puntaje += 11;
		} else {
				puntaje += mano[i];
		}
  }

  // Si el puntaje es mayor que 21 y la mano tiene un As, se puede contar el As como 1 en lugar de 11.
  while(puntaje > 21 && ases > 0) {
		puntaje -= 10;
		ases -= 1;
  }
	if(mano.length === 1 && puntaje === 11){
		return 'As'
	}
  return puntaje;
}
export function getCards(){
	var player = []
	var crupier = []
  const jugador = document.getElementById("cartas_jugador").childNodes
	const dealer= document.getElementById("cartas_dealer").childNodes
	for (const key in jugador) {
		if(jugador[key].innerHTML){
			if(jugador[key].innerHTML === 'As'){
				player.push(1);
			} else {
				player.push(parseInt(jugador[key].innerHTML));
			}	
		}
	}
	for (const key in dealer) {
		if(dealer[key].innerHTML){
			if(dealer[key].innerHTML === 'As'){
				crupier.push(1);
			} else {
				crupier.push(parseInt(dealer[key].innerHTML));
			}	
		}
	}
	return { player, crupier }
}

export function showScores() {
	var { player, crupier } = getCards()
	document.getElementById("scores").innerHTML = `${calcularPuntaje(player)} vs ${calcularPuntaje(crupier)}`
}

export function validarAgregarCartaJugador(carta) {
	var { player, crupier } = getCards()
	var valorTotalConNuevaCarta = 0
	// Calculamos el valor total de la mano incluyendo la nueva carta.
	if(carta === '0'){
		valorTotalConNuevaCarta = calcularPuntaje([...player, 10]);
	} else {
		valorTotalConNuevaCarta = calcularPuntaje([...player, parseInt(carta)]);
	}
	//console.log(valorTotalConNuevaCarta)
	if(valorTotalConNuevaCarta === "As"){
		valorTotalConNuevaCarta = 11
	}
	// Si el valor total con la nueva carta es 21 o menos, podemos agregarla.
	return valorTotalConNuevaCarta <= 21;
}




