
function addChild(element, color, value){

	var tag = document.createElement("p");
	var text;
	if(value === '1'){
		text = document.createTextNode("As");
	} else if(value === '0'){
		text = document.createTextNode('10');
	} else {
		text = document.createTextNode(value);
	}
	
	var clas = "font-body text-gray-200 bg-red-500 border border-gray-600 px-0.5 rounded-sm "
	if(color === 'green'){ 
		clas = "font-body text-gray-200 bg-green-700 border border-gray-600 px-0.5 rounded-sm " 
	}
	else if(color === 'black'){ 
		clas = "font-body text-gray-200 bg-gray-800 border border-gray-600 px-0.5 rounded-sm " 
	}
	tag.className = clas
	tag.appendChild(text);
	element.appendChild(tag);
	
}

export default addChild;