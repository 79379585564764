import React from 'react';
import { Table } from 'antd';
import { useEffect } from 'react';

// cambiar proporciones (tabla rectangular) check!
// buscar maximo y minimo de la matriz para heatmap 
// row index debe partir desde 0.2 no 0 check!

function HiloAntTable({ subscriptionPlan, heatMap, betAsP, initial_bankroll, ror, slideBarHands, hands, decisionMethod}) {

  function generateRandomMatrix(rows, columns, scale) {
    let matrix = [];
    for (let i = 0; i < rows; i++) {
      let row = [];
      for (let j = 0; j < columns; j++) {
        // Generate a random value (for simplicity, using Math.random() here)
        let randomValue = Math.random();
        row.push(randomValue*scale);
      }
      matrix.push(row);
    }
    return matrix;
  }

  // Function to calculate the color based on the cell value and min/max values
  const calculateColor = (value, betAsP) => {
    const normalizedValue = (value - minValue) / (maxValue - minValue);
    const hue = ((1 - normalizedValue) * 120).toString(10); // 0 - 120 degrees for color gradient
    return `hsl(${hue}, 100%, 50%)`; // Using HSL color space for smooth gradient
  };
  
  const MaxBankroll = 400; // Set your maximum bankroll value here
  // *cambiar incremetos de 5% sobre el initial bankroll (no el target return): 
  const bankrollIncrements = 10;

  const columns = [];
  const dataSource = [];
  const numberOfRows = 30; // 29 values y 1 label
  const numberOfColumns = MaxBankroll / bankrollIncrements + 1; // +1 por la columna de labels

  // -------------------    Generación de matriz: se reemplaza por la matriz de la API -------------------
  
  const randomMatrix = generateRandomMatrix(numberOfRows, numberOfColumns, 200);
  let minValue = randomMatrix[0][0];
  let maxValue = randomMatrix[0][0];

  // Iterate through the randomMatrix to find the actual min and max values
  for (let row of randomMatrix) {
    for (let value of row) {
      if (value < minValue) {
        minValue = value;
      }
      if (value > maxValue) {
        maxValue = value;
      }
    }
  }

  if (betAsP){
    minValue = maxValue/initial_bankroll;
    maxValue = minValue/initial_bankroll;
  }

  // ------------------------------------------------------------------------------------------------------

  for (let index = 0; index < numberOfColumns+1; index++) {
    columns.push({
      key: `col_${index}`,
      title: index === 0 ? '' : (index-1) * bankrollIncrements,
      dataIndex: `col_${index}`,
      fixed:index===0?'left':index===numberOfColumns?'right':false,  
      width: 300, // Adjust the width as needed
      // render: (text) => (
      //   <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      //     {text}
      //   </div>
      // ),
      render: (text, record, rowIndex, columnIndex, a, b, c) => {
        if (isNaN(parseFloat(text))) {
          // Render headers without background color

          // caso en que se tiene 'HiLo Label - {num}' 
          const numMatch = text.match(/\d+(\.\d+)?/);
          // Check if a number was found
          if (numMatch) {
            const num = parseFloat(numMatch[0]).toFixed(1); // Convert the matched string to a float and fix to 1 decimal place
            return num; // Output: "123.5" (as a string)
          } else {
            return text;
          }
        } else if (heatMap){ 
          const cellValue = parseFloat(text);
          let backgroundColor = '';
          if (!isNaN(cellValue)) {
            backgroundColor = calculateColor(cellValue, betAsP);
          }
          return (
            <div
              style={{
                backgroundColor: backgroundColor,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {text}
            </div>
          );
        } else {
          return text; 
        }
      },
    });
  }

  for (let rowIndex = 0; rowIndex < numberOfRows; rowIndex++) {
    const rowData = {};
    for (let colIndex = 0; colIndex < numberOfColumns+1; colIndex++) {
      if (colIndex === 0) {
        // For the left column, generate labels from 0.2 to 6.0 in increments of 0.2
        rowData[`col_${colIndex}`] = `HiLo Label - ${((rowIndex + 1) * 0.2 ).toFixed(1)}`; // Adjust as needed for decimal precision
      } 
      else {
        // For other columns, generate sample data
        // rowData[`col_${colIndex}`] = `${rowIndex}, ${colIndex-1}`;
        if (betAsP) {
          rowData[`col_${colIndex}`] = parseFloat(randomMatrix[rowIndex][colIndex - 1]/initial_bankroll).toFixed(2);
        }
        else{
          rowData[`col_${colIndex}`] = parseFloat(randomMatrix[rowIndex][colIndex - 1]).toFixed(2);
        }
      }
    }
    // dataSource.push(rowData);
    dataSource.push({
      key: `row_${rowIndex}`, // Unique key for each row
      ...rowData,
    });
  }

  return (
    <>
      {subscriptionPlan==1||subscriptionPlan==2? null: (
        <p className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/3 -rotate-12 text-red-500 font-title text-8xl font-bold border-dashed border-8 border-red-500 p-5 rounded-lg"> Subscribe</p>
      )}
      <div className={subscriptionPlan==1||subscriptionPlan==2? 'flex flex-row':'flex flex-row blur-md'}>
        <div className='flex flex-col'>
          <div className='h-[6%]'></div>
          <div className='flex items-center justify-center h-[94%] w-[25px] bg-red-500 rounded-md'>
            <span className='text-white -rotate-90 whitespace-nowrap'> Hi-Lo Count </span>
          </div>
        </div>
        <div className='flex flex-col'>
          <div className='flex items-center justify-center bg-red-500 rounded-md'> 
          <p className='text-white'> Current Bank Roll</p>
          </div>
          <Table 
            columns={columns} 
            dataSource={dataSource} 
            style={{maxWidth: 385}}
            scroll={{ x: true, y:330 }}
            tableLayout="fixed" // Set tableLayout to "fixed" for custom styles
            bordered={true} // Remove borders to reduce padding further
            size="large" // Optionally set the size to "small" for compact cells
            rowClassName={() => 'ant-table-row-custom'}
            pagination={false}
            />
        </div>
      </div>
    </>
  );
}

export default HiloAntTable;
