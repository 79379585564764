
function saveData(){
  try{
    sessionStorage.setItem('playerHand', document.getElementById('cartas_jugador').innerHTML)  
    sessionStorage.setItem('dealerHand', document.getElementById('cartas_dealer').innerHTML)  
    

    sessionStorage.setItem('amount', document.getElementById('amount').innerHTML)
    sessionStorage.setItem('estimated_v', document.getElementById('estimated_v').innerHTML)
    sessionStorage.setItem('std', document.getElementById('std').innerHTML)
  }catch{}

  try{
    sessionStorage.setItem('a1', document.getElementById('a1').style.backgroundColor) 
    sessionStorage.setItem('a2', document.getElementById('a2').style.backgroundColor) 
    sessionStorage.setItem('a3', document.getElementById('a3').style.backgroundColor) 
    sessionStorage.setItem('a4', document.getElementById('a4').style.backgroundColor)
  }catch{}
  
  try{
     
    sessionStorage.setItem('cards', document.getElementById('new_cards').innerHTML) 
    sessionStorage.setItem('value_c_1', document.getElementById('value_c_1').innerHTML) 
    sessionStorage.setItem('value_c_2', document.getElementById('value_c_2').innerHTML) 
    sessionStorage.setItem('value_c_3', document.getElementById('value_c_3').innerHTML) 
    sessionStorage.setItem('value_c_4', document.getElementById('value_c_4').innerHTML) 
    sessionStorage.setItem('value_c_5', document.getElementById('value_c_5').innerHTML) 
    sessionStorage.setItem('value_c_6', document.getElementById('value_c_6').innerHTML) 
    sessionStorage.setItem('value_c_7', document.getElementById('value_c_7').innerHTML) 
    sessionStorage.setItem('value_c_8', document.getElementById('value_c_8').innerHTML) 
    sessionStorage.setItem('value_c_9', document.getElementById('value_c_9').innerHTML) 
    sessionStorage.setItem('value_c_0', document.getElementById('value_c_0').innerHTML) 
  }catch{}
}

function catchData(){
  const player = document.getElementById('cartas_jugador')
  const dealer = document.getElementById('cartas_dealer')
  const cards = document.getElementById('new_cards')
  const amount = document.getElementById('amount')
  const estimated_v = document.getElementById('estimated_v')
  const std = document.getElementById('std')
  const f_real = document.getElementById('f_real')
  try{
    for(var i=1; i<10; i++){
      const card = document.getElementById(`value_c_${i}`)
      if(card){
        const data = sessionStorage.getItem(`value_c_${i}`) 
        if(data){
          card.innerHTML = data 
          sessionStorage.removeItem(`value_c_${i}`) 
        }
        else{
          card.innerHTML = 32
          sessionStorage.removeItem(`value_c_${i}`)
        }
      }
      sessionStorage.removeItem(`value_c_${i}`)
    }
  
  
    const card = document.getElementById(`value_c_0`)
    if(card){
      const data = sessionStorage.getItem(`value_c_0`) 
      if(data){
        card.innerHTML = data 
        sessionStorage.removeItem(`value_c_0`) 
      }
      else{
        card.innerHTML = 128
        sessionStorage.removeItem(`value_c_0`)
      }
    }
    sessionStorage.removeItem(`value_c_0`)
  } catch {}
  
  if(player){
    player.innerHTML = sessionStorage.getItem('playerHand') 
    sessionStorage.removeItem('playerHand') 
  }
  if(dealer){
    dealer.innerHTML = sessionStorage.getItem('dealerHand') 
    sessionStorage.removeItem('dealerHand') 
  }
  if(cards){
    cards.innerHTML = sessionStorage.getItem('cards') 
  }
  sessionStorage.removeItem('cards') 
  if(amount){
    amount.innerHTML = sessionStorage.getItem('amount') 
    sessionStorage.removeItem('amount') 
  }
  if(estimated_v){
    estimated_v.innerHTML = sessionStorage.getItem('estimated_v') 
    sessionStorage.removeItem('estimated_v') 
  }
  if(std){
    std.innerHTML = sessionStorage.getItem('std') 
    sessionStorage.removeItem('std') 
  }
  
  if(f_real){
    f_real.innerHTML = sessionStorage.getItem('f_real') 
    sessionStorage.removeItem('f_real') 
  }
  if(document.getElementById('a1')){
    document.getElementById('a1').style.backgroundColor = sessionStorage.getItem('a1')
    document.getElementById('a2').style.backgroundColor = sessionStorage.getItem('a2')
    document.getElementById('a3').style.backgroundColor = sessionStorage.getItem('a3')
    document.getElementById('a4').style.backgroundColor = sessionStorage.getItem('a4')

    sessionStorage.removeItem('a1') 
    sessionStorage.removeItem('a2') 
    sessionStorage.removeItem('a3') 
    sessionStorage.removeItem('a4') 
  }
}

export {saveData, catchData};