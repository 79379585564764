import {cssLastCards, DecisionButons, DecisionLabels} from "../../helpers/Mobile/cssClass";
import ClipLoader from "react-spinners/ClipLoader";
// import {handleRoundsChange, editRounds} from "../../helpers/roundsHelper";

function zoneMedium({subscriptionPlan, BI, setBI, roundsPlayed, setRoundsPlayed, GetBestBet, setDisabledButton, cartas, s17, decks, double, enhc, p0, pa, 
  risk, setApuesta, setEdge, setCargandoBet, disabledButton, cargandoBet,
  changeBackRoll, inputValue, setInputValue, setP0, setPa, manejoFocus,
  colaPlayer, colaDealer, sumaPlayer, sumaDealer, BestDecision, cargandoDecision, prob, showPercentaje, apuesta,
  handleInputChange, EV, setEV, std, setStd, hands, returnTarget, percentile, ModeCalculator, countMethod, 
  decisionMethod, hl_count, depth, alpha, k_value, c_value, desv_cond
}){ 
  const handleRoundsChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 0) {
      setRoundsPlayed(newValue);
    }
    if (e.target.value === ''){
      setRoundsPlayed(0)
    }

  };

  const editRounds = (operator) => {  
      if (operator === '+'){
          setRoundsPlayed((prevRounds) => prevRounds + 1);
      }
      if (operator === '-'){
          setRoundsPlayed((prevRounds) => Math.max(prevRounds - 1, 0));
      }
  };
  console.log("EV", EV)

  return(
    <div className='flex flex-col h-[60%] gap-2'>
      <div className="flex flex-col justify-between gap-2 w-full h-[32%] rounded-md" id="bestBet">
        <div className=" flex flex-col border border-black py-1 rounded-md gap-2 bg-white dark:bg-newBlue h-full">
          <div className="grid grid-cols-4 justify-center h-full w-full pb-1">
            <div id="col1" className="flex flex-col w-full justify-center items-center">
              <label className="font-body text-sm text-red-400 dark:text-red-400 text-center pl-2 "> 
                Strategy
              </label>
              <label className="font-body text-sm text-red-400 dark:text-red-400 text-center pl-2 "> 
                H={hands} 
              </label>
              <label className="font-body text-sm text-red-400 dark:text-red-400 text-center pl-2"> 
                R={returnTarget}
              </label>
              {/* <label className="font-body text-sm text-red-400 dark:text-red-400 text-center pl-2 pb-1 "> 
                D={
                    desv_cond === 0
                      ? "Level 1"
                      : desv_cond === 1.25
                      ? "Level 2"
                      : `${parseFloat(desv_cond) * 100}%`
                  }
              </label> */}
            </div>
          
          <div id='col2' className='flex justify-center items-center gap-2'>
            <button disabled={disabledButton} className="flex shadow-xl justify-center 
            items-center border border-gray-600 rounded-full font-body font-bold text-sm px-2 py-1.5 
            hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 
            duration-500 w-[80%] h-[80%]" 
            onClick={()=> {GetBestBet(setDisabledButton, cartas, s17, decks, double, enhc, p0, pa, 
              risk, setApuesta, setEdge, setCargandoBet, setEV, setStd, countMethod, decisionMethod, 
              hands, returnTarget, percentile,desv_cond , hl_count, depth, alpha, k_value,c_value,ModeCalculator,roundsPlayed)}}> Best Bet! 
            </button>
          </div>
          <div id="col3" className="flex flex-col justify-center items-center">
            
            {subscriptionPlan === 0 || subscriptionPlan === 2 ? (
              cargandoBet? 
                (<div className='flex justify-center items-center'>
                  <ClipLoader color={"white"}  loading={cargandoBet} />
                </div>
                ): (showPercentaje?
                  (pa?
                    (<p className="text-3xl text-black dark:text-gray-400 text-black">
                      {`$${(apuesta * 100 / pa).toFixed(2)}%`}  
                    </p>
                    ): (<p className="text-3xl text-black dark:text-gray-400 "> 
                      $0 
                    </p>)
                  ): (<p className="text-3xl text-black dark:text-gray-400 "> 
                    {`$${(apuesta)}`} 
                </p> )
                )
              ) : (
              <div className="flex items-center justify-center h-full w-1/2">
                <span> &#128274;</span>
              </div>
            )}
          </div>
          <div id='col4' className='flex flex-col justify-center items-center'>
              {cargandoBet? <div className='flex justify-center items-center'>
                <ClipLoader color={"white"}  loading={cargandoBet} /></div> 
                : 
                <div className="flex flex-col justify-center items-center">
                  <p className="text-mg text-black dark:text-gray-400"> 
                  {`EV: ${(parseFloat(EV*100)).toFixed(2)}% `}
                  </p>
                  <p className="text-mg text-black dark:text-gray-400"> 
                    {`STD: ${(parseFloat(std)*100).toFixed(2)}`}% 
                  </p>
                </div>
              }
          </div>
        </div>
      </div>
    </div>

      <div className="flex border border-black py-1 rounded-md bg-white dark:bg-newBlue h-[35%] 
      rounded-md w-full h-full" id="bankroll">

        <div className="flex flex-col items-center w-1/2 gap-2 p-1">

          {/* <div id="col1" className="flex flex-col items-center justify-center w-1/3 h-full">
            <label className="font-body text-md text-black dark:text-gray-400 text-justify"> Actual Bankroll </label>
          </div> */}
          <label className="font-body text-md text-black dark:text-gray-400 text-justify"> Current Bankroll </label>
          <div id="col2" className="flex flex-col w-full h-full">
            <div className="flex items-center justify-center w-[95%] h-10">
              <button disabled={disabledButton} 
              className="flex shadow-xl justify-center items-center border border-gray-600 
              rounded-l-full font-body font-bold text-sm px-5 py-1.5 hover:cursor-pointer 
              bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500 h-[80%] 
              w-[25%]" 
              onClick={()=>changeBackRoll('-', inputValue, setInputValue, setP0, setPa, BI)}> - </button>
              <input onBlur={() => {manejoFocus(setP0, setPa, inputValue)}} 
              className="text-black w-full h-[80%] border-y border-gray-600 pl-1 font-body placeholder-gray-800 
              bg-gray-300 dark:bg-blue-200 dark:placeholder-gray-400 text-center  
              " placeholder="Ingresa monto" type="number" pattern="[0-9]+" value={inputValue} 
              onChange={(e)=>{handleInputChange(e)}} id="money" min="0"/>
              <button disabled={disabledButton} className="flex shadow-xl justify-center 
              items-center border border-gray-600 rounded-r-full font-body font-bold text-sm 
              px-5 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 
              hover:bg-blue-300 duration-500 h-[80%] w-[25%]" 
              onClick={()=>changeBackRoll('+', inputValue, setInputValue, setP0, setPa, BI)}> + </button>
            </div>
            <label className='font-body text-[11px] text-red-500 text-center'>
              INITIAL BANKROLL <span style={{ color: 'white' }}>${BI}</span>
            </label>
          </div>
          
        </div>

        <div className="flex flex-col items-center w-1/2 h-full gap-2">

          {/* <div id="col1" className="flex flex-col items-center justify-center w-1/3 h-full">
            <label className="font-body text-md text-black dark:text-gray-400 text-justify"> Actual Bankroll </label>
          </div> */}
          <label className="font-body text-md text-black dark:text-gray-400 text-justify"> Rounds Played </label>
          <div id="col2" className="flex w-full">
            
            <div className="flex items-center justify-center w-[95%] h-10">
              <button disabled={disabledButton} 
              className="flex shadow-xl justify-center items-center border border-gray-600 
              rounded-l-full font-body font-bold text-sm px-5 py-1.5 hover:cursor-pointer 
              bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 hover:bg-blue-300 duration-500 h-[80%] 
              w-[25%]" 
              onClick={()=>editRounds('-')}> - </button>

              <input  
              className="text-black w-full h-[80%] border-y border-gray-600 pl-1 font-body placeholder-gray-800 
              bg-gray-300 dark:bg-blue-200 dark:placeholder-gray-400 text-center  
              " placeholder={0}
              value={roundsPlayed} onChange={handleRoundsChange} id="roundsPlayed"/>

              <button disabled={disabledButton} className="flex shadow-xl justify-center 
              items-center border border-gray-600 rounded-r-full font-body font-bold text-sm 
              px-5 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700 
              hover:bg-blue-300 duration-500 h-[80%] w-[25%]" 
              onClick={()=>editRounds('+')}> + </button>
            </div>
          </div>
        </div>
        
      </div>
      
    
    <div className={ModeCalculator === "0"?"flex justify-between h-[50%] bg-white dark:bg-newBlue gap-1 rounded-md":
    "flex justify-between h-[50%] bg-white dark:bg-newBlue gap-1 rounded-md pointer-events-none blur"}>
      <div id="col1" className="w-3/5 flex-grow max-h-screen">
        <div className="flex flex-row  bg-white dark:bg-newBlue h-full" id="decisions">
          <div className="flex flex-row w-5/6">
          <div className="flex flex-col p-2 rounded-md bg-white dark:bg-newBlue h-full w-full" id="hands">
              <div className="flex items-center justify-start h-full w-full gap-2">
                <label className="font-body text-sm text-black dark:text-gray-400"> Player </label>
                <div id="cartas_jugador" className="flex justify-evenly items-center gap-1 px-2
                  py-0.5 border border-green-600 text-gray-900 rounded-full w-2/4 h-3/5 bg-gray-300 
                  dark:bg-darkBlue2 text-white">
                {colaPlayer.map((elemento, index) => (
                <div key={index} id="new_cards2" className="flex gap-1">
                  {elemento  ? (
                    elemento[0] === "1"?
                      (<p className={cssLastCards(elemento[1])}>
                        As
                      </p>)
                    :
                      (<p className={cssLastCards(elemento[1])}>
                        {elemento[0]}
                      </p>)
                ) : null}
                </div>
                ))}              
                  </div>
              </div>
              <div className="flex items-center justify-start h-full w-full gap-2">
                <label className="font-body text-sm text-black dark:text-gray-400"> Dealer </label>
                <div id="cartas_dealer" className="flex justify-evenly items-center gap-1 px-2 py-0.5 border
                  border-red-600 text-gray-900 rounded-full w-2/4 h-3/5 bg-gray-300 dark:bg-darkBlue2 text-white">
                {colaDealer.map((elemento, index) => (
                <div key={index} id="new_cards2" className="flex gap-1">
                  {elemento  ? (
                    elemento[0] === "1"?
                    (<p className={cssLastCards(elemento[1])}>
                    As
                    </p>):
                  (<p className={cssLastCards(elemento[1])}>
                  {elemento[0]}
                  </p>)
                ) : null}
                </div>
                ))}  
                  </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center w-1/8">
            <div className="flex flex-col justify-center items-center bg-white dark:bg-newBlue w-full h-full">
              <p className="font-body text-sm text-green-500 text-2xl"> {sumaPlayer} </p>
              <div className='flex justify-center'>
                <p className="font-body text-sm text-black dark:text-gray-400"> vs </p>
                </div>
              <p className="font-body text-sm text-red-500 text-2xl "> {sumaDealer} </p>
          </div>
        </div>
      </div>
      </div>

      <div id="col2" className="flex w-1/2 h-full justify-center items-center rounded-md">
        <div className="flex flex-col bg-white dark:bg-newBlue justify-center items-center py-2 h-full w-full">
          <div className='flex h-[25%] w-full'>
            <label className={DecisionButons(1, BestDecision)} style={{}} id="a1">
            <div className='flex flex-row justify-between'>
              <label id="t2" className={DecisionLabels(1, BestDecision)}>
                {!cargandoDecision ? 
                  (showPercentaje ? <div><p> Hit {prob.prob_hit} </p></div> : <p> Hit </p>) : 
                  <div className='flex items-center justify-center'>
                    <ClipLoader color={"#ffffff"} size={20} loading={cargandoDecision} />
                  </div>}
              </label> 
            </div>
            </label>
          </div>

          <div className='flex h-[25%] w-full'>
          <label className={DecisionButons(0, BestDecision)} style={{}} id="a2">
            <div className='flex flex-row justify-between'> 
            <label id="t2" className={DecisionLabels(0, BestDecision)}>
              {!cargandoDecision ? 
                (showPercentaje ? <div><p> Stand {prob.prob_stand} </p></div> : 
                <p> Stand </p>) 
                : 
              <div className='flex items-center justify-center' >
                <ClipLoader color={"#ffffff"} size={20} loading={cargandoDecision} />
              </div>}
              </label> 
            </div>
          </label>
          </div>
          
          <div className='flex h-[25%] w-full'>
          <label className={DecisionButons(3, BestDecision)} style={{}} id="a3">
          <div className='flex flex-row justify-between'> 
            <label id="t2" className={DecisionLabels(3, BestDecision)}>
              {!cargandoDecision ? (showPercentaje ? <div><p> Split {prob.prob_split} </p></div> : <p> Split </p>) :
                <div className='flex items-center justify-center' >
                <ClipLoader color={"#ffffff"} size={20} loading={cargandoDecision} />
                </div>}
              </label> 
          </div> 
          </label>
          </div>
          <div className='flex h-[25%] w-full'>
          <label className={DecisionButons(2, BestDecision)} style={{}} id="a4">
          <div className='flex flex-row justify-between'>
            <label id="t2" className={DecisionLabels(2, BestDecision)}>
              {!cargandoDecision ? (showPercentaje ? <div><p> Double {prob.prob_double} </p></div> : 
              <p> Double </p>) 
              : <div className='flex items-center justify-center'>
                <ClipLoader color={"#ffffff"} size={20} loading={cargandoDecision} />
                </div>}
              </label> 
          </div> 
          </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default zoneMedium;