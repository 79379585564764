import { useEffect, useState } from 'react';
import newWindow from "../../helpers/newWindow";
import CelMenu from "../CelMenu";
import Footer from "../Footer";
import Header from "../Header";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function EditPost() {
  const [showMenu, setShowMenu] = useState(false);
  const [data, setData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    loginWithRedirect,
    isAuthenticated,
    user
    } = useAuth0();
  
  const getRole = () => {
    const userRoles = user['https://parametros_auth0.com/roles'].map((role) => role);
    return userRoles
  };  


  const editElement = (tipo, indice, nuevoContenido) => {
    const estadoActualizado = { ...data };
    const day = new Date();
    estadoActualizado.fecha = `${day.getDate()}/${day.getMonth() + 1}/${day.getFullYear()}`
    if (tipo === "content") {
      estadoActualizado.contenido[indice].content = nuevoContenido;
    } else {
      estadoActualizado.titulo = nuevoContenido;
    }
    setData(estadoActualizado);
  };

  const updatePost = () => {
    console.log(data)
    fetch(`${process.env.REACT_APP_BACKEND_URL}/actualizar_publicacion`,{
      headers: { "Content-Type": "application/json" },
      method: "PUT",
      body: JSON.stringify(data)
    }).then((data) => data.json()).then((data) => navigate('/blog'))
  };

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_BACKEND_URL}/obtener_publicacion/${id}`,{
      method: "GET"
    }).then((data) => data.json()).then((data) => setData(data))
  }, [id])

  if (isAuthenticated && getRole().includes("Admin")) {
    return(
      <div className="bg-darkBlue h-fit">
        <Header newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu}/>
        <CelMenu showMenu={showMenu}/>
        <div className={showMenu ? "flex flex-col py-32 items-center justify-center gap-10 py-52 md:py-28 md:px-36 px-5" : "flex flex-col pt-24 items-center justify-center gap-10 py-20 md:py-28 md:px-16 px-5"}>
          <h1 className="font-title font-medium text-center text-white text-2xl md:text-4xl"> Edit Post</h1>
          <div className='flex flex-col w-1/2'>
            <div className="top-28 flex flex-col w-5/6 gap-10">
              <div className='flex flex-col w-full'>
              <label className='font-title text-white text-2xl '>Title:</label>
                <input className='w-full h-10 rounded-lg bg-gray-400 pl-2 font-title' value={data?.titulo} onChange={(e) => editElement("title", null, e.target.value)}/>
              </div>
              <div className='flex flex-col gap-10 items-center justify-center '>
                {data?.contenido?.map((item, idx) => (
                  item.type === "text" ? (
                    <textarea 
                      key={idx} 
                      value={item.content}
                      onChange={(e) => editElement("content", idx, e.target.value)}
                      className='w-full h-40 rounded-lg bg-gray-400 pl-2 font-title'/>
                  ) : (
                    <input 
                      key={idx} 
                      value={item.content}  
                      onChange={(e) => editElement("content", idx, e.target.value)}
                      className='w-full h-10 rounded-lg bg-gray-400 pl-2 font-title'/>
                  ) 
                ))}
              </div>
              <div className='flex place-self-end gap-1'>
                <button onClick={updatePost} className='text-white text-lg font-title rounded-full bg-green-700 hover:bg-green-500 px-5 py-2 text-center hover:bg-darkBlue2'>Update Post</button>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )} else {
      return(<h1>No admin master</h1>)
    }
  }
export default EditPost;