// 'split' debe ser dropdown

import { useEffect, useState } from "react";

import addChild from "../helpers/addChildNode";
import clearHands from "../helpers/clearHands";
import EditLatestCards from "../helpers/editLatestCards";
import getBestBet from "../helpers/getBestBet";
import getBestDecision from "../helpers/getBestDecision";
import resetCount from "../helpers/resetCount";
import { catchData, saveData } from "../helpers/saveData";
import verifyCard from "../helpers/verifyCard";
import resetBestDecs from "../helpers/resetBestDecs";
import calculateSabot from "../helpers/calculateSabot";
import { ShowValidationMessage } from "../helpers/ShowValidationMessages";
import { validarAgregarCartaJugador, getCards } from "../helpers/showScores"
import ApplyStrategy from "../helpers/applyStrategy";


import NoKeyboard from "./NoKeyboard";
import {WithKeyboard, focusInput} from "./WithKeyboard";
import HiLoTable from "./HiLoTable";
import HiloAntTable2 from "./HiloAntTable2";
import HiloAntTable from "./HiloAntTable";

import carta1 from "../content/carta1.jpg";
import carta10 from "../content/carta10.png";
import carta2 from "../content/carta2.png";
import carta3 from "../content/carta3.png";
import carta4 from "../content/carta4.png";
import carta5 from "../content/carta5.png";
import carta6 from "../content/carta6.png";
import carta7 from "../content/carta7.png";
import carta8 from "../content/carta8.png";
import carta9 from "../content/carta9.png";
import logo from "../content/logov4.svg";
import { IoClose } from 'react-icons/io5';
import { count } from "mathjs";
import refreshIcon from "../content/refresh-159.png";

var keysPressed = []

function Calculadora({ subscriptionPlan, isAuthenticated }) {
  useEffect(() => {
    document.addEventListener('keydown', (event) => {
      try {
      event.stopPropagation()
      if(verifyCard(event.key)){
        // roundsPlayed
        const focusMoney = document.getElementById('money');
        const focusRounds = document.getElementById('rounds');
        var hasFocus = (focusMoney.matches(':focus') || focusRounds.matches(':focus'));
        if(!hasFocus){
          keysPressed[event.key] = true;
          if(event.key !== 'q' && event.key !== 'e' && event.key !== 'Backspace' && event.key !== 'x' && event.key !== 'X'){
            var valor = parseInt(document.getElementById(`value_c_${event.key}`).innerHTML) - 1
            if(valor >= 0){
              if (keysPressed['q'] || keysPressed['Q']) {
                const jugador = document.getElementById("cartas_jugador")
                if(validarAgregarCartaJugador(event.key)){
                  if(event.key !== '0'){
                    addChild(jugador, 'green', event.key)
                  }
                  else{
                    addChild(jugador, 'green', 10)
                  }
                  EditLatestCards(event.key, 'green')
                  getBestDecision()
                } else {ShowValidationMessage("validation_player")}
              }
              else if (keysPressed['e'] || keysPressed['E']) {  
                const dealer = document.getElementById("cartas_dealer")
                if(dealer.childElementCount < 1){
                  if(event.key !== '0'){
                    addChild(dealer, 'red', event.key)
                  }
                  else{
                    addChild(dealer, 'red', 10)
                  }
                  EditLatestCards(event.key, 'red') 
                  getBestDecision() 
                } else {ShowValidationMessage("validation_dealer")}
              }
              else{EditLatestCards(event.key, 'black')}   
            }
          }
          else if(event.key === 'Backspace'){
            EditLatestCards(event.key);
            var { player, crupier } = getCards()
            if(player.length > 1 && crupier.length === 1){
              getBestDecision() 
            } else {
              resetBestDecs();
            }
            
          }
          else if(event.key === 'x' || event.key === 'X'){
            try {
              clearHands();
              resetBestDecs();
            } catch {
              console.log("es aqui")
            }
            getBestBet( setInitBankroll )  
          }
        }
      }

    } catch {}
    });
    
    document.addEventListener('keyup', (event) => {
      event.stopPropagation()
      delete keysPressed[event.key]; 
    });
  },[])
  //-------------- VARIABLES -------------
  
  const [hideKeyboard, setHideKeyboard] = useState(false)
  const [showPercentaje, setShowPercentaje] = useState(true)
  const [darkMode, setDarkMode] = useState(true)
  const [risk, setRisk] = useState(1)
  const [s17, setS17] = useState(false)
  const [option, setOption] = useState(1)
  const [enhc, setEnhc] = useState(true)
  const [double, setDouble] = useState(0)
  const [decks, setDecks] = useState(8)
  const [depth, setDepth] = useState(0.5)

  const [surrender, setSurrender] = useState('notAllowed'); //options: notAllowed(0) / any(1) / anyExceptAce
  const [BJpays, setBJpays] = useState('3to2');  //options: 3to2 
  const [DAS, setDAS] = useState(false); 
  const [roundsPlayed, setRoundsPlayed] = useState(0); 
  const [split, setSPlit] = useState(0); //options: max 1 time(0)

  // -------- Estrategias ----------
  const [countingMethod, setCountingMethod] = useState('pc'); // options: pc / hlc
	const [decisionMethod, setDecisionMethod] = useState('pd');
  const [calculatorMode, setCalculatorMode] = useState('fcc'); // optins: fcc / ic
  const [hands, setHands] = useState(100);
  const [hHands, setHHands] = useState(100);
  const [nHands, setNHands] = useState(0);
  
	const [ror, setRor] = useState(0.5);
	const [returnTarget, setreturnTarget] = useState(1);
  const [hlCount, setHLCount] = useState(0);
  const [k_value, setKValue] = useState(1);
  const [alphaValue, setAlphaValue] = useState(0);
  const [c_value, setCValue] = useState(1);  
  const [initial_bankroll, setInitBankroll] = useState(100);
  const [current_bankroll, setCurrentBankroll] = useState(100);


  const [slideBarHands, setSlideBarHands] = useState(0); // para usar en el display de la barra Hi-Lo
  const [droppedSlideBarHands, setDroppedSlideBarHands] = useState(0); // para usar en la consulta de la tabla de Hi-Lo
  const [betAsP, setBetAsP] = useState(false); 
  const [heatMap, setHeatMap] = useState(false); 
  const [desv_cond, setDesvCond] = useState(0); 

  // const [subriptionPlan, setSubscriptionPlan] = useState(-1); //options: -1 (no plan)/ 0 (Perfect)/ 1 (HiLo)/ 2 (Full)
  const [subscribed, setSubscribed] = useState(true); // aun se usa en keyBard (cambiar)
  //-------------- FUNCIONES (SOLO LOCALES) --------
  
  useEffect(() => {
    if(option === 1){
      catchData()
    }  
  },[option])

  function Tooltip(id){
    document.getElementById(id).classList.toggle("invisible");   
  }

  function limitBankroll(){
    const initial_bankroll = document.getElementById('initial_bankroll');
    const numero = parseFloat(initial_bankroll.value);
    if (numero > 1000000) {
      initial_bankroll.value = 1000000;  
    }
    if (numero < 0) {
      initial_bankroll.value = 0;  
    }
    setInitBankroll(initial_bankroll.value);
    setCurrentBankroll(initial_bankroll.value);
  }

  function MoveSlideBarHands(e, id){
		if(id === "hands"){
			setSlideBarHands(e.target.value)
		} else {
			// setHands(e.target.value)
		}
		const label = document.getElementById(id);
		const value = e.target.value;
  }

  const hanldeSliderDrop = (e) => {
    console.log("slider dropped on value: ", e.target.value)
    setDroppedSlideBarHands(e.target.value);
  }

  const handleMapUpdate = (e) => {
    // Hacer fetch de los datos para el mapa en Hi-Lo Count
  }

  return (
    <div id='wrapper' className={darkMode ? "dark h-full w-full bg-footer " : "h-full w-full bg-gray-100"}>
      <div className=" flex flex-col justify-start items-end pt-1 w-full h-full">

        <div className="flex w-full justify-between h-8" id="settings_options">
          <div className="flex items-center pl-5 pb-1">
            <img src={logo} alt="logo_bj" className="w-24"/>
          </div>
          {/* opciones de la calculadora van ocultos */}
          <label className="hidden" id="p0">{initial_bankroll}</label>
          <label className="hidden" id="mode">{calculatorMode}</label>
          <label className="hidden" id="depth">{depth}</label>
          <label className="hidden" id="double">{double}</label>
          <label className="hidden" id="decks">{decks}</label>
          {enhc ? <label className="hidden" id="enhc">0</label> : <label className="hidden" id="enhc">1</label>}
          {s17 ? <label className="hidden" id="s17">0</label> : <label className="hidden" id="s17">1</label>}
          <label id="hands" className="hidden">{hands}</label>
          <label id="hHands" className="hidden">{hHands}</label>
          <label id="nHands" className="hidden">{nHands}</label>
          <label id="returnTarget" className="hidden">{returnTarget}</label>
          <label id="alphaValue" className="hidden">{alphaValue}</label>
          <label id="k_value" className="hidden">{k_value}</label>
          <label id="c_value" className="hidden">{c_value}</label>
          <label id="countingMethod" className="hidden">{countingMethod}</label>
          <label id="decisionMethod" className="hidden">{decisionMethod}</label>
          {/* variables nuevas */}
          <label id="surrender" className="hidden">{surrender}</label>
          <label id="BJpays" className="hidden">{BJpays}</label>
          <label id="DAS" className="hidden">{DAS}</label>
          <label id="roundsPlayed" className="hidden">{roundsPlayed}</label>
          <label id="split" className="hidden">{split}</label>
          <label id="droppedSlideBarHands" className="hidden">{droppedSlideBarHands}</label>
          


          <div className="flex">
            <div className="flex items-center justify-center border border-gray-600 p-0.5 w-[90px] text-center hover:cursor-pointer hover:bg-gray-300 rounded-tl-md bg-white dark:bg-darkBlue hover:bg-gray-300 dark:hover:bg-darkBlue3" onClick={()=> {setOption(1)}}>
              <p className="font-body text-sm text-black dark:text-gray-400">Calculator</p>
            </div>
            <div className="flex items-center justify-center border border-gray-600 p-0.5 w-[90px] text-center hover:cursor-pointer hover:bg-gray-300 bg-white dark:bg-darkBlue hover:bg-gray-300 dark:hover:bg-darkBlue3" onClick={()=> {saveData(); setOption(2)}}>
              <p className="font-body text-sm text-black dark:text-gray-400">Settings</p>
            </div>
            <div className="flex items-center justify-center border border-gray-600 p-0.5 w-[90px] text-center hover:cursor-pointer hover:bg-gray-300 rounded-tr-md bg-white dark:bg-darkBlue hover:bg-gray-300 dark:hover:bg-darkBlue3" onClick={()=> {saveData(); setOption(3)}}>
              <p className="font-body text-sm text-black dark:text-gray-400">Info</p>
            </div>
          </div>
        </div>
        
        {option === 1 && countingMethod === 'pc'? (          // Pestaña CALCULATOR: PERFECT-COUNT

          <div className="flex flex-col p-1 w-full h-[550px] gap-2 bg-white dark:bg-darkBlue">
            {calculatorMode === "ic" ? 
            <div className={hideKeyboard ? "flex flex-col items-center justify-center gap-2 border border-gray-600 p-1.5 rounded-md shadow-xl bg-white dark:bg-newBlue h-[210px]" : "flex flex-col items-center justify-center gap-2 border border-gray-600 p-1.5 rounded-md shadow-xl bg-white dark:bg-newBlue h-[130px]"}>
              <div className="flex justify-around gap-5">
                <h2 className="font-bold font-title text-black text-3xl dark:text-gray-400">Hi-Lo True Count:</h2>
                <select id="hl_count" value={hlCount} onChange={(e)=>setHLCount(e.target.value)} className="w-16 rounded-md p-1 text-black text-lg bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center">
                  {(() => {
                    const options = [];
                    for (let i = 0; i <= 100; i++) {
                      const value = (i * 0.1).toFixed(1);
                      options.push(<option key={value} value={value}>{value}</option>);
                    }
                    return options;
                  })()}
                </select>
              </div>
              <div className="flex justify-center items-center gap-5">
                <label className="font-body font-bold text-black dark:text-gray-400">Count Method</label>
                {countingMethod === "pc" 
                  ?<label className="font-body font-bold text-lg text-red-500 uppercase ">PC</label>
                  :<label className="font-body font-bold text-lg text-red-500 uppercase ">HLTC</label>
                }
              </div>
            </div>  
            : <div className="flex flex-col gap-2 border border-gray-600 p-1.5 rounded-md shadow-xl bg-white dark:bg-newBlue">
              <div className="flex justify-start gap-2 pl-5 border border-gray-600 text-gray-900 rounded-full w-full h-[40px] p-1 bg-gray-300 dark:bg-darkBlue2">
                <div className="flex items-center" >
                  <label className="font-body text-sm text-black dark:text-gray-400">Latest cards dealt:</label>
                </div>
                <div className="flex items-center" >
                  <div id="new_cards" className="flex gap-1"></div>
                </div>                       
              </div>
              <div className="flex items-center justify-around" id="cardVector">
                <div className="flex flex-col justify-center items-center">
                  <button onClick={() =>{ resetCount(setRoundsPlayed); focusInput()}} className="flex shadow-xl justify-center items-center border border-gray-600 rounded-full font-body font-bold text-[13px] px-2 py-1.5 hover:cursor-pointer bg-gradient-to-br from-blue-100 to-blue-400 text-gray-700  hover:"> Reset Count </button>
                  {/* <label className="font-body font-bold text-[13px] text-black dark:text-gray-400">Count Method</label>
                  {countingMethod === "pc" 
                    ?<label className="font-body font-bold text-red-500 uppercase ">PC</label>
                    :<label className="font-body font-bold text-red-500 uppercase ">HLTC</label>
                  } */}
                </div>  
                <div className="flex flex-col">
                  <div className="flex border border-gray-600 rounded-t bg-gray-300 dark:bg-darkBlue2">
                    <label id='value_c_1' className="text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_2' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_3' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_4' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_5' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_6' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_7' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_8' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_9' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 32 </label>
                    <label id='value_c_0' className="border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md"> 128 </label>
                  </div>
                  <div className="flex justify-between bg-white border-x border-b border-gray-600">
                    <img src={carta1} alt="carta1" className="h-10"></img>
                    <img src={carta2} alt="carta2" className="h-10 border-l border-gray-600"></img>
                    <img src={carta3} alt="carta3" className="h-10 border-l border-gray-600"></img>
                    <img src={carta4} alt="carta4" className="h-10 border-l border-gray-600"></img>
                    <img src={carta5} alt="carta5" className="h-10 border-l border-gray-600"></img>
                    <img src={carta6} alt="carta6" className="h-10 border-l border-gray-600"></img>
                    <img src={carta7} alt="carta7" className="h-10 border-l border-gray-600"></img>
                    <img src={carta8} alt="carta8" className="h-10 border-l border-gray-600"></img>
                    <img src={carta9} alt="carta9" className="h-10 border-l border-gray-600"></img>
                    <img src={carta10} alt="carta10"  className="h-10 border-l border-gray-600"></img>
                  </div>
                </div> 
              </div>
            </div>}

            {hideKeyboard
              ? NoKeyboard(subscriptionPlan, subscribed, setSubscribed, showPercentaje, darkMode, calculatorMode, isAuthenticated, hands, returnTarget, desv_cond, initial_bankroll, setInitBankroll, roundsPlayed, setRoundsPlayed, current_bankroll, setCurrentBankroll)
              : WithKeyboard(subscriptionPlan, subscribed, setSubscribed, showPercentaje, darkMode, calculatorMode, isAuthenticated, hands, returnTarget, desv_cond, initial_bankroll, setInitBankroll, roundsPlayed, setRoundsPlayed, current_bankroll, setCurrentBankroll)
            }
              
          </div>

        ): option === 1 && countingMethod === 'hlc'?(  // Pestaña CALCULATOR: HiLo-COUNT 
          <div className="flex flex-col p-1 w-full gap-2 bg-white dark:bg-darkBlue">
            
            <div className="flex flex-row gap-2 border border-gray-600 p-1.5 rounded-md shadow-xl bg-white dark:bg-newBlue">
              <div className="flex flex-col w-1/3 gap-2 dark:bg-newBlue" id="settings">
                <div>
                  <span className="font-bold text-black dark:text-gray-400">Number of Rounds H:</span> <span className="font-bold text-red-500 dark:text-white">{hands}</span>
                </div>
                <div>
                  <span className="font-bold text-black dark:text-gray-400">Risk Profile R:</span> <span className="font-bold text-red-500 dark:text-white">{returnTarget}</span>
                </div>
                <div>
                  <span className="font-bold text-black dark:text-gray-400">Count Method:</span> <span className="font-bold text-red-500 dark:text-white">Hi-Lo True Count</span>
                </div>
                <div>
                  <span className="font-bold text-black dark:text-gray-400">Decision Method:</span> <span className="font-bold text-red-500 dark:text-white">{decisionMethod === 'bs'? 'Basic Strategy (BS)': 'Perfect Decision (PD)'}</span>
                </div>
                <div>
                  <span className="font-bold text-black dark:text-gray-400">Initial Bankroll:</span> <span className="font-bold text-red-500 dark:text-white">{'$' + initial_bankroll}</span>
                </div>
              </div>

              <div className="flex flex-col w-2/3 justify-evenly" id="mapSettings"> 
                <div className="flex flex-col w-full" id="toggles">

                  <div className="flex px-2 py-1 items-center justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Bet as Percentage: </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input type="checkbox" checked={betAsP} className="sr-only peer" id="betAsP" onChange={(c)=>setBetAsP(c.target.checked)}/>
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div>

                  <div className="flex px-2 py-1 items-center justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Heatmap: </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input type="checkbox" checked={heatMap} className="sr-only peer" id="heatMap" onChange={(c)=>setHeatMap(c.target.checked)}/>
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div>

                </div>

                <div className="flex flex-row">
                  <div className="flex flex-col w-full pr-4" id="SlideBar">
                    <div>
                      <span className="font-bold text-black dark:text-white">ROUNDS PLAYED</span> 
                    </div>


                    <div className="flex flex-col w-full" id="bar">
                      {/* <div className="flex w-full relative items-end">
                        <label className="absolute font-body text-menuText left-1/2" id="hands">N={slideBarHands}</label>
                      </div> */}
                      <input type="range" id="n_manos" min="0" max={hands} step="1" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-red-500" value={slideBarHands} onChange={(e)=>MoveSlideBarHands(e, "hands")} onMouseUp={hanldeSliderDrop}/>
                      <div className="flex w-full justify-between">
                        <label className="text-justify font-body text-menuText">0</label>
                        <label className="font-body text-red-500">H = {hands}</label>
                      </div>
                      <label className="font-body text-menuText text-center" id="hands">N={slideBarHands}</label>
                    </div>
                  </div>

                  {/* <div>
                    <button className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-2 rounded" onClick={handleMapUpdate}>
                      <img src={refreshIcon} alt="Refresh Icon" className="h-6 w-6" />
                    </button>
                  </div> */}

                </div>         
              </div>
            </div>

            <div className="flex items-center justify-center w-full">
              <HiloAntTable2 subscriptionPlan={subscriptionPlan} heatMap={heatMap} betAsP={betAsP} initial_bankroll={initial_bankroll} ror={returnTarget} droppedSlideBarHands={droppedSlideBarHands} hands={hHands} decisionMethod={decisionMethod} />
            </div>
          </div>
          
        ): option === 2 ?(  // Pestaña SETTINGS 
          <div className="flex flex-col gap-2 p-1 w-full h-[550px] border border-black bg-white dark:bg-darkBlue" id="settings">
              <div className="">
                <p className="font-bold text-black dark:text-gray-400"> Settings </p>
              </div>
              {/* <div className="flex w-full items-center justify-between border border-gray-600 p-1 rounded-lg dark:bg-darkBlue2">
                <label className="font-title text-black dark:text-gray-400"> Calculator MODE </label>
                <div id="tooltip_calc_mode" className="invisible z-10 flex flex-col bg-gray-200 border border-gray-800 w-[300px] rounded px-2 pb-1 absolute transform translate-x-[10%] translate-y-[40%]">
                  <IoClose onClick={()=>Tooltip("tooltip_calc_mode")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                  <p className='font-body font-semibold text-gray-800 text-xs text-justify'>"Count as Input" for Hi-Lo True Count method only</p>
                </div>
                <div onClick={()=>{if(countingMethod === "pc"){Tooltip("tooltip_calc_mode")}}}>
                  <select onChange={(c)=>setCalculatorMode(c.target.value)} value={calculatorMode} className={countingMethod === "hlc" ? "w-44 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center" :"w-44 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center opacity-50 pointer-events-none"}>
                    <option value="ci"> Count as input </option>
                    <option value="fcc"> Full Card Counting </option>
                  </select>   
                </div> 
              </div> */}
              <div className="flex ">
                {/* ------------------------------------------ Columna izquierda -----------------------------------------*/}
                <div id="col1" className="flex flex-col w-1/2">
                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Hide keyboard </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input type="checkbox" checked={hideKeyboard} className="sr-only peer" id="hideKeyboard" onChange={(c)=>setHideKeyboard(c.target.checked)}/>
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div> 

                  {/* <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Show percentage </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input type="checkbox" checked={showPercentaje} className="sr-only peer" id="showPercentaje" onChange={(c)=>setShowPercentaje(c.target.checked)}/>
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div>  */}

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Dark Mode </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input type="checkbox" checked={darkMode} className="sr-only peer" id="darkMode" onChange={(c)=>setDarkMode(c.target.checked)}/>
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div> 

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Soft 17 </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input type="checkbox" checked={s17} className="sr-only peer" id="s17" onChange={(c)=>setS17(c.target.checked)}/>
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div>

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> ENHC </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <input type="checkbox" checked={enhc} className="sr-only peer" id="enhc" onChange={(c)=>setEnhc(c.target.checked)}/>
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div>

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400">BJ pays</p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <select className="w-15 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setBJpays(c.target.value)}} value={BJpays}>
                        <option value="3to2">3 to 2</option>
                      </select>
                    </label>
                  </div>

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400">Surrender</p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <select className="w-30 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setSurrender(c.target.value)}} value={surrender}>
                        <option value="notAllowed">not allowed</option>
                      </select>
                    </label>
                  </div>

                </div>

                <div className="h-full border border-gray-700"></div>
                  
                 {/* ------------------------------------------ Columna derecha -----------------------------------------*/}
                <div id="col2" className="flex flex-col w-1/2">
                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Depth </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(c)=> {setDepth(c.target.value)}} value={depth}>
                        <option value={0.5}>1/2</option>
                        <option value={0.75}>3/4</option>
                      </select>
                    </label>
                  </div>

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Double </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(c)=> {setDouble(c.target.value)}} value={double}>
                        <option value="0">ALL</option>
                        <option value="1">"9,10,11"</option>
                      </select>
                    </label>
                  </div>

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Decks </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setDecks(c.target.value); calculateSabot(c.target.value)}} value={decks}>
                        <option value="6">6</option>
                        <option value="8">8</option>
                        <option value="10">10</option>
                        <option value="12">12</option>
                      </select>
                    </label>
                  </div> 

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400">D.A.S</p>
                    <label className="inline-flex relative items-center cursor-pointer opacity-50">
                      <input type="checkbox" checked={DAS} className="sr-only peer" id="das" onChange={(c)=>setDAS(c.target.checked)} disabled/> 
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    </label>
                  </div>

                  <div className=" flex px-2 py-1 justify-between">
                    <p className="font-body text-black dark:text-gray-400"> Split </p>
                    <label className="inline-flex relative items-center cursor-pointer">
                      <select className="w-30 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setSPlit(c.target.value)}} value={split}>
                        <option value="0">max 1 time</option>
                      </select>
                    </label>
                  </div> 

                </div>
              </div>

              <div className="flex flex-col dark:bg-darkBlue2 rounded-lg border border-gray-600 w-full px-1 py-1.5 gap-3">
                {/* <div className="flex w-full items-center justify-between">
                  <label className="font-title text-black dark:text-gray-400">Define the counting method:</label>
                  <select onChange={(e)=>{setCountingMethod(e.target.value);if(e.target.value==="pc"){setCalculatorMode("fcc")}; if(e.target.value==="hlc"){setCalculatorMode("ic")}}} value={countingMethod} className="w-[165px] rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center">
										<option value="pc">Perfect Count (PC)</option>
										<option value="hlc">Hi-Lo True Count (HLC)</option>
									</select>   
                </div> */}
                <div className="flex w-full items-center justify-between">
                  <label className="font-title text-black dark:text-gray-400">Define the counting method:</label>
                  <select
                    onChange={(e) => {
                      setCountingMethod(e.target.value);
                      if (e.target.value === "pc") {
                        setCalculatorMode("fcc");
                      }
                      if (e.target.value === "hlc") {
                        setCalculatorMode("ic");
                      }
                    }}
                    value={countingMethod}
                    className="w-[165px] rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center"
                  >
                    <option value="pc">
                      {subscriptionPlan==0||subscriptionPlan==2? (
                        "Perfect Count (PC)"
                      ) : (
                        <>
                          <span className="text-green-500">&#128274;</span>
                          <s>Perfect Count (PC)</s>
                        </>
                      )}
                    </option>
                    <option value="hlc">
                      {subscriptionPlan==1||subscriptionPlan==2? (
                        "Hi-Lo True Count (HLC)"
                      ) : (
                        <>
                          <span className="text-green-500">&#128274;</span>
                          <s>Hi-Lo True Count (HLC)</s>
                        </>
                      )}
                    </option>
                  </select>
                </div>

                <div className="flex w-full items-center justify-between">
                  <label className="font-title text-black dark:text-gray-400">Define the decision method:</label>
                  <select onChange={(e)=>{setDecisionMethod(e.target.value)}} value={decisionMethod} className="w-[165px] rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center">
										<option value="pd">Perfect Decision (PD)</option>
										<option value="bs">Basic Strategy (BS)</option>
									</select>   
                </div>
                <div className="flex w-full items-center justify-between">
                  <p className="font-title text-black dark:text-gray-400">Define your initial Bankroll (IB)</p>
                  <div className="flex items-center">
                      <span className="text-black dark:text-gray-400"> $ </span>
                      <input type="number" value={initial_bankroll} onChange={() => limitBankroll()} id="initial_bankroll" className="text-center pl-1 bg-gray-300 dark:bg-newBlue w-[165px] h-7 font-body text-black dark:text-gray-400 rounded-lg"/>
                  </div>
                </div>
              </div>

              <div className="flex flex-col dark:bg-darkBlue2 rounded-lg border border-gray-600 w-full px-2 py-1.5 gap-3">
                <div className="flex justify-between items-center w-full">
                  <div id='tooltip_helper' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[300px] rounded px-2 pb-1 absolute transform translate-x-[10%] -translate-y-[50%]">
                    <IoClose onClick={()=>Tooltip("tooltip_helper")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                    <p className='font-body font-semibold text-gray-800 text-xs text-justify'>The optimization of the betting policy is an ambiguous problem, as there is no single optimality
                                                                                              criterion. If the expected return of a betting session is maximized, undesirable strategies are
                                                                                              obtained, which, despite having a high expected return, have a probability close to 1 of going
                                                                                              bankrupt (search for “St. Petersburg Paradox” for more information).
                    </p>
                    <p className='font-body font-semibold text-gray-800 text-xs text-justify pt-3'>For this reason, the optimality criterion of a betting policy is defined through a valuation or
                                                                                                    preference function. This function models the satisfaction a player perceives based on the gains
                                                                                                    obtained and, in principle, varies for different players. The player's risk profile parameter "R" is
                                                                                                    associated with a specific valuation function, which was used for training the neural networks that
                                                                                                    model the betting policy (for more information, see the RESEARCH section).
                    </p>
                  </div>
                  <label onClick={()=>Tooltip("tooltip_helper")} className="font-title text-black dark:text-gray-400 underline hover:cursor-pointer">Help</label>
                  <h2 className="text-center text-xl font-title text-white">Strategy Generator</h2>
                  <button className="bg-red-500 rounded-lg p-1 font-title text-white " onClick={()=>ApplyStrategy(setKValue, setAlphaValue,setCValue)}>Apply Strategy</button>
                </div>
                
                <div className="flex w-full items-center justify-between">
                  <label className="font-title text-black dark:text-gray-400">Define the number of rounds "H":</label>
                  <select onChange={(c)=> {setHands(c.target.value)}}  value={hands} className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white">
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                    <option value={250}>250</option>
                  </select>    
                </div>
                <div className="flex w-full items-center justify-between">
                  <label className="font-title text-black dark:text-gray-400">Define the player's risk profile "R":</label>
                  <select onChange={(c)=> {setreturnTarget(c.target.value)}} value={returnTarget} className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white">
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                  </select>   
                </div>
                {/* <div className="flex w-full items-center justify-between">
                  <label className="font-title text-black dark:text-gray-400">Define the dispersion level "D":</label> 
                  <select onChange={(c)=> {setDesvCond(c.target.value)}} value={desv_cond} className="w-18 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white">
                    <option value={0}>Level 1</option>
                    <option value={1.25}>Level 2</option>
                  </select>  
                </div>  */}
              </div>
          </div>
        ) : option === 3 ? (   // Pestaña INFO 
          <div className="flex flex-col p-1 w-full border border-black bg-white dark:bg-darkBlue max-h-[95%] overflow-auto" id="info">
            <div className=" flex flex-col p-1 justify-between gap-0.5 ">
              <p className="font-title font-medium text-black dark:text-white text-md place-self-center">KEY INSTRUCTIVE</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">"Num_Key": </span>Add card to the general count.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white ">"Q + Num_Key": </span>Add card to the general count and to the player's hand.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">"E + Num_Key": </span>Add card to the general count and to the dealer's hand.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">"X": </span>Clears the player's and dealer's cards. It also calculates the best bet.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">"Delete_Key": </span> Undoes the last recorded card.</p>
            </div>
            {/* <div className=" flex flex-col p-1 justify-between gap-1">
              <p className="font-title font-medium text-black dark:text-white text-md place-self-center">Calculator MODE</p>
              <p className="font-body text-black dark:text-menuText text-sm text-justify">- If the 'Count as Input' option is enabled, the calculator will adopt a specific format. In this format, a value is entered for the count, which is associated with the selected counting method. Based on this value and the optimization criteria defined for the strategy, the best possible bet is calculated, i.e. the bet that is optimal based on the account information and the target strategy.</p>
              <p className="font-body text-black dark:text-menuText text-sm text-justify">- In contrast, if the 'Full Card Counting' option is enabled, the calculator format will be adapted for a live game of blackjack, which allows recording all cards dealt throughout a game. From this information, a specific count is calculated according to the selected method, and from this, the best possible bet is generated in real time. This bet is optimal according to the count obtained and the objectives previously established for the strategy.</p>
              <p className="font-body text-black dark:text-menuText text-sm text-justify">- The calculator mode can only be changed for counting methods different from "Perfect Count (PC).</p>
            </div> */}
            <div className=" flex flex-col p-1 justify-between gap-0.5">
              <p className="font-title font-medium text-black dark:text-white text-md place-self-center">RULES</p>
              <p className="font-body text-black dark:text-menuText text-sm">Adjust the rules to align them with the blackjack game in which you will participate:</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Soft 17: </span>If ON, Dealer stands on soft 17</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white ">ENHC: </span>If ON, Dealer does not peek for blackjack</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Double: </span>If “ALL”, player can double any two-card hand. If “9,10,11”, player can only double on hands with that score.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Decks: </span>Number of decks used</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Depth: </span>Penetration of the deck. If “1/2”, the game is restarted each time half of the full deck is
                                                                                                                                                          reached. If “3/4”, the game is restarted each time ¾ of the deck is reached.
                                                                                                                                                          </p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Show percentage: </span> If ON, the EV of the decisions and of the next round are displayed.</p>
            </div>
            <div className=" flex flex-col p-1 justify-between gap-1">
              <p className="font-title font-medium text-black dark:text-white text-md place-self-center">Count & Decision Method</p>
              <p className="font-body text-black dark:text-menuText text-sm text-justify">This section establishes the mechanisms of the strategy to be applied. Initially, the counting method is determined: "Perfect Count (PC)" (which implies considering the exact amount of each card in the remaining deck) or "HI-LO True Count (HLTC)" (associated to the HI-LO counting method). It is then specified whether the player's decisions during a round will be based on the "Perfect Decision (PD)", i.e., the one that offers the maximum expectation based on the exact composition of the deck, or on the "Basic Strategy (BS)", the well-known decision mechanism that depends solely on the hands of the player and the dealer. The "initial bankroll" is also defined here, which is the bankroll with which the player starts the betting session.</p>  
            </div>
            <div className=" flex flex-col p-1 justify-between gap-1">
              <p className="font-title font-medium text-black dark:text-white text-md place-self-center">STRATEGY GENERATOR</p>
              <p className="font-body text-black dark:text-menuText text-sm text-justify">Subsequently, the user must set two parameters: the number of rounds “H”, and the player's risk
                                                                                          profile “R”. These two parameters will be used to define the betting strategy that is optimized for a
                                                                                          total of H rounds, according to the risk profile R, the counting method, the decision method, and the
                                                                                          set of established rules</p>  
            </div>
            <div className="flex flex-col p-1 justify-between gap-1">
              <p className="font-title font-medium text-black dark:text-white text-md place-self-center">CALCULATOR INFORMATION</p>

              <h3 className="font-title font-medium text-black dark:text-white text-lg">Counting method is <span className="underline">Perfect Count (PC):</span></h3>
              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Deck composition: </span>
                Shows the number of cards remaining to be played.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Latest cards dealt: </span>
                Shows the last cards played that have been registered by the user.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">STD: </span>
                Shows the standard deviation of the returns for the player in the next round, given the decision method.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">EV: </span>
                Shows the expected value of the returns for the player in the next round, given the decision method.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Player's hand/ Dealer's hand: </span>
                Shows the composition of the player's and dealer's hand respectively.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Current Bankroll: </span>
                Shows the player's current bankroll. It can be updated manually or with "+" and "-", which will increase or decrease the bankroll by 5% of the initial bankroll.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Percentages in best decision: </span>
                Indicates the expected value associated with each decision.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Number of Rounds: </span>
                Indicates the number of effective rounds that have been played since the start, in which a bet has actually been placed. It can be updated with "+" and "-".
              </p>

              <h3 className="font-title font-medium text-black dark:text-white text-lg">Counting method is <span className="underline">Hi-Lo True Count (HLC):</span></h3>
              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Bet as Percentage: </span>
                If activated, the optimal bets are displayed as a percentage of the current bankroll. If not activated, the optimal bets are shown as the net amount of money to be wagered.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Vertical Direction: </span>
                Indicates the Hi-Lo True Count value.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Horizontal Direction: </span>
                Indicates the Current Bankroll.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Slider: </span>
                Indicates the number of effective rounds already played.
              </p>

              <p className="font-body text-black dark:text-menuText text-sm">
                <span className="font-title font-medium text-black dark:text-white">Table values: </span>
                Indicate the optimal bet, depending on the current bankroll, the number of rounds and the Hi-Lo True Count.
              </p>
            </div>

            <div className=" flex flex-col p-1 justify-between gap-1">
              <p className="font-title font-medium text-black dark:text-white text-md place-self-center">BUTTONS</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Reset Count: </span>Resets the deck composition to its full state.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white ">Best Bet: </span>Provides the optimal amount the player should bet, according to the strategy generated by the user.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Best Decision: </span>Shows the best possible decision associated with the player's hand and the dealer's card. It maximizes the player's expected returns. The best decision will be shown in green.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Clear Hands: </span> Resets the player's hand and the dealer's hand.</p>
              <p className="font-body text-black dark:text-menuText text-sm"> <span className="font-title font-medium text-black dark:text-white">Card Panel: </span>Allows to record the cards being played. The arrow at the bottom right undoes the last recorded card.</p>
            </div>
                
          </div>
        ): null}  
      </div>    
    </div>
    );
}
  
  export default Calculadora;