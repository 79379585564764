import getData from "./getData";
import changeDecision from "../helpers/changeDecision";
import { showScores } from "./showScores";

const getBestDecision = () => {
	const calculatorMode = document.getElementById('mode').innerHTML
	const datos = getData(calculatorMode);
	showScores()
	datos["comando"] = "decision"
	if(datos.jugador.length > 1 && datos.croupier.length > 0){
		fetch(`${process.env.REACT_APP_BACKEND_URL}/decision`,{
			method: "POST",
			headers: {
				"Content-Type": "application/json"
			},
			body: JSON.stringify(datos)
		}).then(res =>res.json()).then(res => changeDecision(res));
	}
}

export default getBestDecision;