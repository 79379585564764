const InitialText = () => {
    const step1 = 
    [
        "In this section, you will have access to the results of the simulations carried out within the framework of this research. Each simulation has been designed with a single player facing the dealer, using a total of 8 decks. In those simulations where the player does not bet on all hands, we have included an imaginary player who plays a hand every time our test player chooses not to bet. We have considered all possible combinations of 4 game rules: ENHC, Soft17, Double and Depth (The possibility of \"surrender\" to the player was not considered).",
    ]
    
    return (
        <div className="flex flex-col pt-5 w-full">
            <div className="flex justify-center">
                <p className="font-title font-medium text-white text-2xl md:text-4xl"> SIMULATIONS </p>
            </div>
        {step1.map((step, index) => (
            <div className="flex w-full" key={`${index}bo`}>
                <p className="text-justify font-body text-menuText md:text-xl mt-10" key={`${index}bo`}> 
                    {step}
                </p>
            </div>
        ))}
        </div>
    )
}

export default InitialText;