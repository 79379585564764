import { IoClose } from 'react-icons/io5';


export default function TableOptions({calculateSabot, decks, setDecks, surrender, setSurrender, BJpays, setBJpays, split210, setSplit210, DAS, setDAS, splitAces, setSplitAces, CountMethod, setCountMethod, decisionMethod, setDecisionMethod, enhc, setEnhc, s17, setS17,
    double, setDouble, depth, setDepth, CustomStrategy, setCustomStrategy, hands, setHands, BI, limitBankroll,
    returnTarget, setreturnTarget, percentile, setPercentile, setStrategyHands, strategyHands, desv_cond, setDesvCond}){

    function Tooltip(id){
      document.getElementById(id).classList.toggle("invisible");   
    }  

    return(
      <div className="flex bg-darkBlue2 flex-col gap-10 px-2 pb-2 h-full">

        <div className="flex justify-center rounded-full">
        <table className="w-[95%] rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-darkBlue2 border-2 border-gray-400">
              <th className="font-body text-gray-400 text-xl text-center border-b-2 border-gray-400 
              w-full py-2 px-4 w-[50%]"> COUNT METHOD  </th>
              <th className="font-body text-gray-400 text-xl text-center border-b-2 border-gray-400 
              w-full py-2 px-4 border-l-2 w-[50%]"> DECISION METHOD </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-darkBlue2 border-b-2 border-l-2 border-gray-400 py-2 px-4 border-gray-400">
              <td className="py-2 px-4">          
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> HI-LO Count(HLC) </p>
                <input checked ={CountMethod === "hlc"} onChange={(e) => setCountMethod(e.target.value)} 
                id="default-radio-1" 
                type="radio" value="hlc" className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 
                focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                 dark:border-gray-600 "/>
              </div>
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Perfect Count(PC) </p>
                <input checked ={CountMethod === "pc"} onChange={(e) => setCountMethod(e.target.value)} 
                id="default-radio-1" 
                type="radio" value="pc" name="default-radio" className="w-6 h-6 text-blue-600 bg-gray-100 
                border-gray-300 
                focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                 dark:border-gray-600"/>
              </div>
  
              </td>
              <td className="py-2 px-4 border-b-2 border-l-2 border-r-2 border-gray-400 py-2 px-4 border-gray-400">  
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Perfect Decision(PD) </p>
                <input checked ={decisionMethod === "pd"} onChange={(e) => setDecisionMethod(e.target.value)} 
                id="default-radio-1" 
                type="radio" value="pd" className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 
                focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                 dark:border-gray-600"/>
              </div>                
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Basic Strategy(BS) </p>
                <input checked ={decisionMethod === "bs"} onChange={(e) => setDecisionMethod(e.target.value)} 
                id="default-radio-1" 
                type="radio" value="bs" className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 
                focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                 dark:border-gray-600"/>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>

        <div className="flex justify-center">
          <div className="flex items-center justify-between border-2 border-gray-400 p-5 w-[95%] gap-2">
            <p className="font-body text-gray-400 text-xl text-justify"> Initial Bankroll (IB)</p>
            <label className="inline-flex relative items-center cursor-pointer">
              <input type="number" className="pl-1 bg-gray-300 dark:bg-darkBlue2 w-full h-full 
              font-body text-black dark:text-gray-400 rounded-lg" onChange={(e) => {limitBankroll(e)}} value={BI} />
            </label>
          </div>
        </div>

        <div className="flex justify-center">
          <div className="flex flex-col flex-justify-center border-2 border-gray-400 w-[95%] h-[40vh]">
							<div className="border-b-2 border-gray-400 p-2 h-[25%] flex items-center justify-center">
								<label className="text-justify font-body text-menuText text-lg">RULES</label>
							</div>
						
							<div className="flex flex-row w-full h-[75%]">
								{/* --------------------------------------- COLUMNA IZQUIERDA -------------------------------------------*/}
								<div className="flex flex-col justify-around items-start h-full p-2 w-[50%] space-y-2" id="col1">
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">ENHC</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<input type="checkbox" checked={enhc} className="sr-only peer" id="enhc" onChange={(c)=>setEnhc(c.target.checked)}/>
											<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
										</label>
									</div>
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">Soft17</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<input type="checkbox" checked={s17} className="sr-only peer" id="s17" onChange={(c)=>setS17(c.target.checked)}/>
											<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
										</label>
									</div>
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">Double</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(c)=> {setDouble(c.target.value)}} value={double}>
												<option value="0">ALL</option>
												<option value="1">"9,10,11"</option>
											</select>
										</label>

									</div>
									<div className="flex w-full items-center justify-between">
										<label className="text-justify font-body text-menuText">Depth</label>
										<label className="inline-flex relative items-center cursor-pointer">
											<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" onChange={(c)=> {setDepth(c.target.value)}} value={depth}>
												<option value={"0.5"}>1/2</option>
												<option value="0.75">3/4</option>
											</select>
										</label>
									</div>
									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText"> Decks </p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setDecks(c.target.value); calculateSabot(c.target.value)}} value={decks}>
											<option value="6">6</option>
											<option value="8">8</option>
											<option value="10">10</option>
											<option value="12">12</option>
										</select>
										</label>
									</div> 
								</div>
								{/* --------------------------------------- COLUMNA DERECHA -------------------------------------------*/}
								<div className="flex flex-col justify-around items-start h-full p-2 w-[50%] space-y-2" id="col2">
									<div className=" flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">BJ pays</p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setBJpays(c.target.value)}} value={BJpays}>
											<option value="3to2">3 to 2</option>
										</select>
										</label>
									</div>

									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">Surrender</p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setSurrender(c.target.value)}} value={surrender}>
											<option value="notAllowed">not allowed</option>
										</select>
										</label>
									</div>

									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">Split</p>
										<label className="inline-flex relative items-center cursor-pointer">
										<select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white opacity-50 pointer-events-none" onChange={(c)=> {setSplit210(c.target.value)}} value={split210}>
											<option value={1}>max 1 time</option>
										</select>
										</label>
									</div>

									<div className="flex w-full items-center justify-between">
										<p className="text-justify font-body text-menuText">D.A.S</p>
										<label className="inline-flex relative items-center cursor-pointer opacity-50">
										{/*No se permite: onChange={(c)=>setDAS(c.target.checked)} */}
										<input type="checkbox" checked={DAS} className="sr-only peer" id="das" /> 
										<div className="w-11 h-6 bg-gray-200 peer-focus:outline-none dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
										</label>
									</div>

									

								</div>
							</div>
						</div>
        </div>

      {/* <div className="flex justify-center">
        <table className="w-[95%] rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-darkBlue2 border-2 border-gray-400">
              <th className="font-body text-gray-400 text-xl text-center border-b-2 border-gray-400 
              w-full py-2 px-4 w-[50%]"> RULES  </th>
              <th className="font-body text-gray-400 text-xl text-center border-b-2 border-gray-400 
              w-full py-2 px-4 border-l-2 w-[50%]"> BET STRATEGY </th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-darkBlue2 border-b-2 border-l-2 border-gray-400 py-2 px-4 border-gray-400">
              <td className="py-2 px-4">          
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> ENHC </p>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" checked={enhc} 
                  onChange={() => setEnhc(!enhc)}/>
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 
                  peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 
                  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                  after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 
                  after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
                  peer-checked:bg-blue-600"></div>
                </label>
              </div>
              
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Soft17 </p>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" checked={s17} 
                  onChange={() => setS17(!s17)}/>
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 
                  peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 
                  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] 
                  after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border 
                  after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 
                  peer-checked:bg-blue-600"></div>
                </label>
              </div>              
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Double </p>
                <label className="inline-flex relative items-center cursor-pointer">
                      <select className="w-11 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white" 
                      onChange={(e) => setDouble(e.target.value)} value={double}>
                          <option value="0">ALL</option>
                          <option value="1">"9,10,11"</option>
                      </select>
                      </label>
              </div>
  
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Depth </p>
              <label className="inline-flex relative items-center cursor-pointer">
                                          <select className="w-12 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white"
                       onChange={(c)=> {setDepth(c.target.value)}} value={depth}>
                                              <option value="0.5">1/2</option>
                                              <option value="0.75">3/4</option>
                                          </select>
                    </label>
              </div>
  
              <div className="flex items-center justify-between w-full gap-2">
              <p className="font-body text-gray-400 text-xl text-justify"> Initial Bankroll (IB)</p>
                <label className="inline-flex relative items-center cursor-pointer">
                      <input type="number" className="pl-1 bg-gray-300 dark:bg-darkBlue2 w-full h-full 
                      font-body text-black dark:text-gray-400 rounded-lg" onChange={(e) => {limitBankroll(e)}} value={BI} />
                      </label>
              </div>
  
              </td>
              <td className="py-2 px-4 border-b-2 border-l-2 border-r-2 border-gray-400 py-2 px-4 border-gray-400"> 
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Flat Bet </p>
                <input checked ={CustomStrategy === "fb"} onChange={(e) => setCustomStrategy(e.target.value)} 
                id="default-radio-1" 
                type="radio" value="fb" className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 
                focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                 dark:border-gray-600" disabled/></div>                 
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Flat Percentage </p>
                <input checked ={CustomStrategy === "fpb"} onChange={(e) => setCustomStrategy(e.target.value)} 
                id="default-radio-1" 
                type="radio" value="fpb" className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 
                focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                 dark:border-gray-600" disabled/>    
              </div>
              <div className="flex items-center justify-between w-full">
              <p className="font-body text-gray-400 text-xl text-justify"> Custom Strategy </p>
                <input checked ={CustomStrategy === "custom"} onChange={(e) => setCustomStrategy(e.target.value)} 
                id="default-radio-1" 
                type="radio" value="custom" className="w-6 h-6 text-blue-600 bg-gray-100 border-gray-300 
                focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700
                 dark:border-gray-600"/>
              </div>          
              </td>
            </tr>
          </tbody>
        </table>
      </div> */}
  
  
        <div className="flex justify-center">
        <table className="w-[95%] rounded-lg overflow-hidden">
          <thead>
            <tr className="bg-darkBlue2 border-2 border-gray-400">
              <th className="font-body text-gray-400 text-xl text-center border-b-2 border-gray-400 
              w-full py-2 px-4 w-[50%]"> 							
              <div className="flex justify-around items-center border-b border-white p-1">
               <label onClick={()=>Tooltip("tooltip_helper")} className="font-body text-menuText text-sm underline cursor-pointer">HELP</label>
              <label className="font-body text-menuText text-lg">Strategy Generator</label>
              <div id='tooltip_helper' className="invisible flex flex-col bg-gray-200 border border-gray-800 w-[80%] rounded px-2 pb-1 absolute transform translate-y-[60%] z-20">
                <IoClose onClick={()=>Tooltip("tooltip_helper")} className='text-xl text-red-600 hover:cursor-pointer hover:text-red-800 place-self-end'/>
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>The optimization of the betting policy is an ambiguous problem, as there is no single optimality
                criterion. If the expected return of a betting session is maximized, undesirable strategies are
                obtained, which, despite having a high expected return, have a probability close to 1 of going
                bankrupt (search for “St. Petersburg Paradox” for more information).
                </p>
                <p className='font-body font-semibold text-gray-800 text-xs text-justify'>
                For this reason, the optimality criterion of a betting policy is defined through a valuation or
                preference function. This function models the satisfaction a player perceives based on the gains
                obtained and, in principle, varies for different players. The player's risk profile parameter "R" is
                associated with a specific valuation function, which was used for training the neural networks that
                model the betting policy (for more information, see the RESEARCH section).
                </p>
              </div>
             
            </div></th>
              {/*<th className="font-body text-gray-400 text-xl text-center border-b-2 border-gray-400 
              w-full py-2 px-4 border-l-2 w-[50%]"> CONSIDER </th>*/}
            </tr>
          </thead>
          <tbody>
            <tr className="bg-darkBlue2 border-b-2 border-gray-400">
              <td className="py-2 px-4 w-[50%] border-b-2 border-l-2 border-r-2 border-gray-400 py-2 px-4">   
              <div className="flex items-center justify-between w-full gap-2">
              <p className="font-body text-gray-400 text-xl text-justify"> Number of rounds "H": </p>
                <label className="inline-flex relative items-center cursor-pointer">
                                          <select id='1' className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center" onChange={(e)=>setStrategyHands(e.target.value)} value = {strategyHands}>
                                              <option value="50">  50  </option>
                                              <option value="100">  100  </option>
                                              <option value="250">  250  </option>
                                          </select>
                    </label>
              </div>       
              <div className="flex items-center justify-between w-full gap-2">
              <p className="font-body text-gray-400 text-xl text-justify">Player's risk profile "R": </p>
                <label className="inline-flex relative items-center cursor-pointer">
                                          <select  id='1'className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-centere" 
                      onChange={(c)=> {setreturnTarget(c.target.value)}} value={returnTarget}>
                                              <option value="1">1</option>
                                              <option value="2">2</option>
                                              <option value="3">3</option>
                                              <option value="4">4</option>
                                          </select>
                    </label>
              </div>
              {/* <div className="flex items-center justify-between w-full gap-2">
              <p className="font-body text-gray-400 text-xl text-justify"> Dispersion level "D": </p>
                <label className="inline-flex relative items-center cursor-pointer">
                                          <select id='1' className="w-14 rounded-md text-black bg-gray-300 dark:bg-gray-600 dark:text-white place-self-center" 
                      onChange={(c)=> {setDesvCond(c.target.value)}} value={desv_cond}>
                                              <option value="0">  Level 1  </option>
                                              <option value="1.25">  Level 2 </option>
                                          </select>
                    </label>
              </div> */}
  
              </td>
              {/*<td className="py-2 px-4 border-b-2 border-l-2 border-r-2 border-gray-400 py-2 px-4 border-gray-400 
              w-[50%] h-full">                  
                <p className="font-body text-gray-400 text-xl text-justify h-full w-full"> The strategy generated will be the one that maximizes the P percentile of the expected Bankroll
                  distribution for the player after N hands played, assuming a maximum Risk of Ruin of R, depending
                  on the counting and decision mechanism chosen.</p>
              </td>*/}
            </tr>
          </tbody>
        </table>
        </div>
  
      </div>
    )
}
