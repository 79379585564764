import { BsFacebook, BsInstagram } from 'react-icons/bs';
import { FaTiktok } from 'react-icons/fa';
import logo6 from '../content/logo-6.png';
import { Link } from "react-router-dom";

function Footer() {
	return (
		<footer className="flex flex-col md:flex-row w-full bg-footer pb-32 md:pb-12 justify-around gap-8 px-3 items-center ">
			<div className="flex flex-col items-center">
				<Link to='/' className="hidden md:block font-title text-gray-400 hover:text-red-500">HOME</Link> 
				<Link to='/research' className="hidden md:block font-title text-gray-400 hover:text-red-500">RESEARCH</Link> 
				<Link to='/blog' className="hidden md:block font-title text-gray-400 hover:text-red-500">BLOG & FAQ</Link> 
				<Link to='/simulations' className="hidden md:block font-title text-gray-400 hover:text-red-500">SIMULATIONS</Link> 
				<Link to='/subscriptions' className="hidden md:block font-title text-gray-400 hover:text-red-500">SUBSCRIPTIONS</Link> 
				<Link to='/aboutus' className="hidden md:block font-title text-gray-400 hover:text-red-500">ABOUT US</Link>  
			</div>
			<div className='flex flex-col items-center'>
				<Link to='/terms&conditions' className="font-title text-gray-400 hover:text-red-500"> TERMS & CONDITIONS</Link>
				<p className="font-title text-gray-400 hover:text-red-500"> PRIVACY POLICY</p>
			</div>
			<div className='flex flex-col items-center'>
				<img src={logo6} alt="logo_bj" className='md:h-[200px]'/>
				<p className="font-title text-gray-400">© 2024 Blackjack Theorem. All rights reserved.</p>
			</div>
			<div className="flex flex-col items-center md:items-start">
				<p className="font-title font-bold text-gray-400">MAIL US</p>
				<p className="font-title text-gray-400">contact@bjtheorem.com</p> 
			</div>
			<div className="flex flex-col items-center gap-3">
				<p className="font-title font-bold text-gray-400">FOLLOW US</p>
				<div className="flex md:flex-col gap-8 md:gap-3">
					<a href='https://instagram.com/bj.theorem?igshid=YmMyMTA2M2Y='><BsInstagram className="font-body text-gray-400 text-2xl hover:text-red-500"/></a>
					<a href='https://www.tiktok.com/@bjtheorem'><FaTiktok className="font-body text-gray-400 text-2xl hover:text-red-500"/></a>
					<a href='https://www.facebook.com/profile.php?id=100091901277064'><BsFacebook className="font-body text-gray-400 text-2xl hover:text-red-500"/></a>
				</div>
			</div>
		</footer>
	)
}

export default Footer;