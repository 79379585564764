import classNames from 'classnames';

export const cssButton = (StatePlayer, StateDealer) =>  classNames(
    'font-body text-black dark:text-white text-md border border-gray-600 p-1.5 text-base text-center',
    { 'bg-green-500': StatePlayer, 'bg-red-500': StateDealer, 'bg-white dark:bg-newBlue': !StatePlayer && !StateDealer}
);

export const cssLastCards = (elemento) => classNames(
    "font-body text-gray-200 border border-gray-600 px-0.5 rounded-sm",
    { 
      "bg-green-500": elemento === "Green",
      "bg-red-500": elemento === "Red",
      "bg-gray-800": elemento === "Gray"  
    }
);
  
export const cssCards = classNames(
    "border-l border-gray-600 text-center text-xs w-full font-body text-black dark:text-white text-md "
);

export const DecisionButons = (valor, BestDecision) => classNames(
    "flex justify-center gap-2 items-center rounded-full border border-gray-600 w-full",
    {
      'animate-wiggle bg-green-500': valor === BestDecision,
      "bg-gray-300 dark:bg-darkBlue2": valor != BestDecision
    }
  )

export const DecisionLabels = (valor, BestDecision) => classNames(
    "font-body text-sm",
    {
      "text-black": valor === BestDecision,
      "dark:text-gray-400": valor != BestDecision
    }
)