import getData from "./getData";

function ApplyStrategy( setKValue, setAlphaValue,setCValue ){
  const calculatorMode = document.getElementById('mode').innerHTML
  const datos = getData(calculatorMode);
  fetch(`${process.env.REACT_APP_BACKEND_URL}/apply_strategy`,{
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(datos)
  }).then(res => res.json()).then(res => {setAlphaValue(res.alfa); setKValue(res.k); setCValue(res.c)})
};

export default ApplyStrategy;