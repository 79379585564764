
function verifyCard(card){
    
    const valid_cards = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'Backspace', 'q', 'e', 'x', 'Q', 'E', 'X']
    if (valid_cards.includes(card)) {
        return true
    }
    else{
        return false
    }
}

export default verifyCard