
function calculateSabot(valor){
  sessionStorage.setItem('value_c_1', parseInt(valor)*4)
  sessionStorage.setItem('value_c_2', parseInt(valor)*4)
  sessionStorage.setItem('value_c_3', parseInt(valor)*4)
  sessionStorage.setItem('value_c_4', parseInt(valor)*4)
  sessionStorage.setItem('value_c_5', parseInt(valor)*4)
  sessionStorage.setItem('value_c_6', parseInt(valor)*4)
  sessionStorage.setItem('value_c_7', parseInt(valor)*4)
  sessionStorage.setItem('value_c_8', parseInt(valor)*4)
  sessionStorage.setItem('value_c_9', parseInt(valor)*4)
  sessionStorage.setItem('value_c_0', parseInt(valor)*16)
  sessionStorage.removeItem('cards') 
  sessionStorage.removeItem('dealerHand')
  sessionStorage.removeItem('playerHand')
  sessionStorage.removeItem('money')
  sessionStorage.removeItem('amount')
  sessionStorage.removeItem('inicial_money')
  sessionStorage.removeItem('a1')
  sessionStorage.removeItem('a2')
  sessionStorage.removeItem('a3')
  sessionStorage.removeItem('a4')
  try{
    sessionStorage.setItem('f_real')
  }catch{}
}

export default calculateSabot;