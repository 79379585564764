function calcularPuntaje(mano) {
    let puntaje = 0;
    let ases = 0;
  
    for(let i=0; i<mano.length; i++) {
      if(parseInt(mano[i]) === 1) {
          ases += 1;
          puntaje += 11;
      } else {
          puntaje += parseInt(mano[i]);
      }
    }
  
    // Si el puntaje es mayor que 21 y la mano tiene un As, se puede contar el As como 1 en lugar de 11.
    while(puntaje > 21 && ases > 0) {
      puntaje -= 10;
      ases -= 1;
    }
    if(mano.length === 1 && puntaje === 11){
      return 'As'
    }
    return puntaje;
  }

  export default calcularPuntaje