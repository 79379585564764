export const changeBackRoll = (operador, inputValue, setInputValue, setP0, setPa, initial_bankroll) => {
    if (operador === "+"){
      if (inputValue === ""){
        setInputValue("100");
        setP0("100");
        setPa("100");
      } else {
        setInputValue(`${parseInt(parseInt(inputValue) + initial_bankroll*0.05)}`);
      }
    } else {
      if (inputValue !== ""){
        if (parseInt(inputValue) - initial_bankroll*0.05 >= 0){
          setInputValue(`${parseInt(parseInt(inputValue) - initial_bankroll*0.05)}`);
        } else {
          setInputValue(0);
        }
      }
    }
  }

export const manejoFocus = (setP0, setPa, inputValue) => {
    setP0(inputValue)
    setPa(inputValue)
}


  
