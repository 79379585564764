import Header from "../Header";
import Footer from "../Footer";
import newWindow from "../../helpers/newWindow";
import CelMenu from "../CelMenu";
import { useEffect, useState } from "react";
import { RxChevronDown, RxChevronRight  } from 'react-icons/rx';
import  plot  from "./GraphtMobile";
import {SiConvertio} from "react-icons/si";
import GetSimulations from "../../helpers/Mobile/GetSimulations";

import InitialText from "./SimulationsParts/InitialText";
import Rules from "./SimulationsParts/Rules";
import TableOptions from "./SimulationsParts/TableOptions";
import FirstGraph from "./SimulationsParts/FirstGaphs";
import SecondGraph from "./SimulationsParts/SecondGaph";
import CalculateBet from "./SimulationsParts/CalculateBet";
import { BiDownArrowAlt } from 'react-icons/bi';

import Chart from 'chart.js/auto';
import annotationPlugin from'chartjs-plugin-annotation';

import calculateSabot from "../../helpers/calculateSabot";
Chart.register(annotationPlugin)

var chart;
var chart1;

function SimulationsMobile({ subscriptionPlan, expirationDate }){

  const [show, setShow] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [tipoGraficoDensidad, setTipoGraficoDensidad] = useState(false);
  const [numMano, setNumMano] = useState(100);
  const [showGraphs, setShowGraphs] = useState(true)

  const [CountMethod, setCountMethod] = useState("pc");
  const [decisionMethod, setDecisionMethod] = useState("pd");
  const [enhc, setEnhc] = useState(false);
  const [double, setDouble] = useState("0");
  const [s17, setS17] = useState(false);
  const [depth, setDepth] = useState("0.5");
  const [BI, setBI] = useState("100");
  const [CustomStrategy, setCustomStrategy] = useState("custom");
  const [hands, setHands] = useState("100");
  //const [ror, setRor] = useState("0.25");
  const [returnTarget, setreturnTarget] = useState(1);
  const [percentile, setPercentile] = useState("0.5");
  const [desv_cond, setDesvCond] = useState(0); 

  const [decks, setDecks] = useState(8);  
  const [surrender, setSurrender] = useState('notAllowed'); //options: notAllowed / any / anyExceptAce
  const [BJpays, setBJpays] = useState('3to2');  //options: 3to2 
  const [split210, setSplit210] = useState(1); 
  const [DAS, setDAS] = useState(false); 
  const [splitAces, setSplitAces] = useState(false);

  const [manos, setManos] = useState(100);
  const [histogramas, setHistogramas] = useState([]);
  const [f_percentiles, setFPercentiles] = useState([]);
  const [cargando, setCargando] = useState(true);

	const [strategyHands, setStrategyHands] = useState(100);

  //const [graph, setGraph] = useState("a")
  useEffect(() => {
	try{
		getGraficos("hist");
	} catch(e){
		console.log();
	}
    try {
		getGraficos("sim")
	} catch (e) {
		console.log();
	}
	setCargando(false);
  }, [cargando]);

  function showPost(postId) {
    if(show === postId){
      setShow(null)
    } else {
      setShow(postId)
    }
  }
  const changeInput = (e) => {
    setNumMano(parseInt(e.target.value));
  }



  const ChangeResource = () => {
    setShowGraphs(!showGraphs);
  }

  useEffect(() => {
    if (showGraphs & !cargando){
      //plot(numMano, tipoGraficoDensidad);
	  try{
		plotHistogramas(histogramas);
	  }
	  catch (e){

	  }
    }
  }, [showGraphs, manos]);

  const limitBankroll = (event) => {
    if (event.target.value !== ""){
        let numero2;
        if (event.target.value.length > 0 && event.target.value[0] === "0"){
            numero2 = event.target.value.slice(1, event.target.value.length)
        } else {
            numero2 = event.target.value
        }
        const numero = parseFloat(numero2)
        if (numero > 1000000) {
            setBI("1000000");
        } else if (numero < 0) {
            setBI("0");
        } else {
            setBI(numero2)
        }
    } else {
        setBI("0");
    }
  }

  /*const Simulate = async () => {
    try {
      let new_enhc;
      let new_s17;
      if (enhc) {
        new_enhc = "1"
      } else {
        new_enhc = "0"
      }
      if (s17)  {
        new_s17 = "1"
      } else {
        new_s17 = "0"
      }
      const json = await GetSimulations(new_enhc, new_s17, double, depth, BI, CountMethod, decisionMethod, 
        CustomStrategy, hands, ror, percentile);
      setHistogramas(json.histogramas);
      plotHistogramas();
    } catch (e) {
      console.error(e);
    }
  }*/

  const getGraficos = async (tipo) => {
	try {
		setHands(strategyHands);
		setManos(strategyHands)
		MoveHands(strategyHands, strategyHands);
    //console.log(enhc, s17, double, depth, init_bankroll, countingMethod, decisionMethod, bettingMethod, hands, ror, percentile)
		var new_enhc = 1
		var new_s17 = 1
		var new_BI = 0
		if(enhc){
			new_enhc = 0
		}
		if(s17){
			new_s17 = 0
		}
		if (BI == ""){
			new_BI = 0; 
		} else {
			new_BI = BI
		}

		//console.log(new_enhc, new_s17, double, depth, init_bankroll, countingMethod, decisionMethod, bettingMethod, hands, ror, percentile)
		if(tipo === "hist"){
			try {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/grafico`,{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					"enhc": new_enhc,
					"s17": new_s17,
					"double": double,
					"depth": depth,
					"init_bankroll": new_BI,
					"countingMethod": CountMethod,
					"decisionMethod": decisionMethod,
					"bettingMethod": CustomStrategy,
					"hands": strategyHands,
					"returnTarget": returnTarget,
					"percentile": percentile,
					"desvcond": desv_cond,
				})
			})	
		const json = await response.json();
		console.log(json);
		setHistogramas(json.histogramas);
		setFPercentiles(json.factores_percentiles);
		plotHistogramas(json.histogramas);
		} catch (e) {
			console.log(e);
		}
	}


		else if(tipo === "sim"){
			try {


			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/simulations`,{
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					"enhc": new_enhc,
					"s17": new_s17,
					"double": double,
					"depth": depth,
					"init_bankroll": new_BI,
					"countingMethod": CountMethod,
					"decisionMethod": decisionMethod,
					"bettingMethod": CustomStrategy,
					"hands": strategyHands,
					"returnTarget": returnTarget,
					"percentile": percentile,
					"desvcond": desv_cond,
					"n_manos_sim": manos,			
				})
			})
			const json = await response.json();
			console.log(json);
			plotSimulacion(json.simulacion);
		} catch (e) {
			console.log(e);
		}
		}
	} catch (e){
		console.log();
	}
  }

  useEffect(() => {
	setHands(numMano);
}, [numMano])

  //useEffect(() => {
//	setNumMano(hands);
  //}, [hands])

	function plotHistogramas(info){
		
		var y_label = !tipoGraficoDensidad ? "F(B)" : "f(B)"
		var y_data = !tipoGraficoDensidad ? info[manos/10]?.cumulative : info[manos/10]?.histogram
		// Verificar si y_data está vacío o no definido
		var isDataEmpty = !y_data || y_data.length === 0;

		// Si y_data está vacío o es el gráfico acumulativo, el máximo es 1.
		// De lo contrario, calculamos el máximo basado en y_data.
		var maxYValue = isDataEmpty || !tipoGraficoDensidad ? 1 : Math.max(...y_data) * 1.1;
		if(chart){chart.destroy();}
		chart = new Chart("histograms", {
			type: 'bar',
			data: {
				labels: info[manos/10]?.bin_edges.map(edge => edge.toFixed(2)), 
				datasets: [{
					data: y_data,
					backgroundColor: 'rgba(75, 192, 192, 0.6)', 
					borderColor: 'rgba(75, 192, 192, 1)',
					borderWidth: 1
				}]
			},
			options: {
				plugins: {
					legend: {
						display: false,
					 } 
				}, 
				scales: {
					x: {
						type: 'category', 
						title: {
							display: true,
							text: 'Bankroll "B"',
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						}
					},
					y: {
						max: maxYValue,
						title: {
							display: true,
							text: y_label,
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						}
					}
				}
			}
		});
	}
	function plotSimulacion(data){
		
		const maxValue = Math.max(...data);
		if(chart1){chart1.destroy();}
		chart1 = new Chart("simulacion", {
			type: 'line',
			data: {
				labels: data.map((_, index) => index), 
				datasets: [
				{
					data: data,
					backgroundColor: 'rgba(75, 192, 192, 0.6)', 
					borderColor: '#0000ff',
					borderWidth: 2,
					pointRadius: 0,
				},
				]
			},
			options: {
				
				plugins: {
					legend: {
						display: false,
					},
					annotation: {
						annotations: [
						{
							type: 'line',
							mode: 'horizontal',
							scaleID: 'y',
							value: BI,
							borderColor: 'green',
							borderWidth: 2,
							label: {
									content: 'Initial Bankroll',
									display: true,
									position: 'end',
									backgroundColor: 'rgba(0, 128, 0, 0)',
									color: 'green',
									yAdjust: -10
									
							}
					}]
				}	 	 	 
				}, 
				scales: {
					x: {
						type: 'linear', 
						max: Math.max(parseInt(manos), hands),
						title: {
							display: true,
							text: 'Number of hands',
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						},
						grid: {
							display: false, // Oculta las líneas verticales de la grilla
						},
					},
					y: {
						beginAtZero: true,
						title: {
							display: true,
							text: "Bankroll",
							font: {
								size: 16, // Tamaño de fuente deseado
								weight: 'bold' // Peso de la fuente (opcional)
							}
						}
					}
				}
			}
		});
	}

  

  function MoveHands(value, max_value){
		setManos(value)
		const label = document.getElementById("hands");
		var percent = 0
		console.log(value)
		if(value === "1000"){
			percent = ((value-22)/ max_value) * 100;
		} 
		else if(value === "100"){
			percent = ((value-6)/ max_value) * 100;
		} 
		else {
			percent = ((value-10)/ max_value) * 100;
		}
		label.style.left = `calc(${percent}%)`;
	}

  const applyParameters = () => {
	getGraficos("hist");
	getGraficos("sim");	
  }

  const RulesOut = Rules({showPost, show});
  const TableOptionsOut = TableOptions({calculateSabot, decks, setDecks, surrender, setSurrender, BJpays, setBJpays, split210, setSplit210, DAS, setDAS, splitAces, setSplitAces, CountMethod, setCountMethod, decisionMethod, setDecisionMethod, enhc, setEnhc, s17, setS17,
    double, setDouble, depth, setDepth, CustomStrategy, setCustomStrategy, hands, setHands, BI, limitBankroll,
    returnTarget, setreturnTarget, percentile, setPercentile, setStrategyHands, strategyHands, desv_cond, setDesvCond});
  const FirstGraphOut = FirstGraph({tipoGraficoDensidad, setTipoGraficoDensidad, ChangeResource, numMano, changeInput, showGraphs, BI, f_percentiles, manos });
  const SecondGraphOut = SecondGraph({getGraficos, manos, setManos});
  const CalculateBetOut = CalculateBet({});

  return (
    <div className="flex flex-col w-full">
      <Header newWindow={newWindow} setShowMenu={setShowMenu} showMenu={showMenu}/>
      <CelMenu showMenu={showMenu} subscriptionPlan={subscriptionPlan} expirationDate={expirationDate}/>
			<div className={showMenu ? "flex justify-center px-5 md:px-[20%] w-screen pt-72 md:pt-24 pb-10 bg-darkBlue" : 
      "flex justify-center px-5 md:px-[20%] w-screen pt-20 pb-10 md:pt-24 bg-darkBlue"}>
        <InitialText/>
			</div>
        {RulesOut}
        {TableOptionsOut}
        <div className="flex flex-col w-full p-2 mt-2">
        <button onClick={()=>(applyParameters())} className="bg-red-500 rounded-lg py-2 px-3 font-title text-white 
        font-medium"> APPLY PARAMETERS </button>
        <div className="flex flex-col w-full justify-center items-center gap-7">
			<label className="font-title font-medium text-menuText text-xl">Number of hands:</label>
				<div className="flex flex-col w-[70%] p-5">
					<div className="flex w-full relative items-end">
						<label className="absolute font-body text-menuText left-1/2" id="hands"> N={manos} </label>
					</div>
					<input type="range" id="n_manos" min="0" max={hands} step="10" className="w-full 
						h-2 bg-gray-200 
            			rounded-lg appearance-none cursor-pointer accent-red-500 w-full" value={manos} 
            			onChange={(e)=>MoveHands(e.target.value, hands)}/>
					<div className="flex w-full justify-between">
						<label className="text-justify font-body text-menuText"> 0 </label>
						<label className="font-body text-menuText"> {hands} </label>
					</div>
				</div>
			</div>
        </div>
        {FirstGraphOut}
        {SecondGraphOut}
		<Footer/>
    </div>
  )
}

export default SimulationsMobile;