import getProbability from "./getProbability"

function changeProb(prob) { 

  // Se guarda la nueva apuesta
  const apuesta = parseInt(prob.apuesta_con_riesgo)
  document.getElementById('amount').innerHTML = "$" + apuesta
  document.getElementById('amount').value = apuesta

  //Se guarda el estimated_value
  if(prob.esp){
    document.getElementById('estimated_v').innerHTML = "EV: " + (parseFloat(prob.esp*100)).toFixed(2) + "%"
    //(parseFloat(EV*100))

    //Se guarda la desviacion estandar 
    document.getElementById('std').innerHTML = "STD: " + (parseFloat(prob.std*100)).toFixed(2) + "%"
  } else {
    document.getElementById('std').innerHTML = ""
    document.getElementById('estimated_v').innerHTML = ""
  }

  // Se muestra la prob de la apuesta
  const pa = parseInt(document.getElementById('money').value.split('.').join(''))
  //document.getElementById('porcentaje').innerHTML = `Edge Percentil:  ${getProbability(parseFloat(prob[1]).toFixed(2))}%`

  
    
}

export default changeProb;